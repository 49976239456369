.foundational_content_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5vh;
}

.foundational_content_wrapper .foundational_subCategoru_container {
  display: flex;
  column-gap: 4%;
}

@media (max-width:576px) {
  .foundational_content_wrapper .foundational_subCategoru_container {
    display: flex;
    flex-direction: column;
    column-gap: 4%;
  }
}