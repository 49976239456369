.modal_QA_Content_item {
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal_QA_Content_item .modal_QA_Question {
  display: inline-block;
  font-family: HomeRegularFamily;
  font-size: var(--fs24);
  color: var(--Text_light_dark_to_white33);
}

.modal_QA_Content_item .modal_QA_Question.radio {
  width: 55%;
}

.modal_QA_Content_item .modal_QA_Question span:nth-of-type(2) {
  color: #ff0000;
}

.modal_QA_Content_item .company_Name {
  border: 0;
  border-bottom: 1px solid #707070;
  width: 38%;
  margin-left: var(--fs16);
  font-family: HomeRegularFamily;
  font-size: var(--fs24);
  /* color: #0496ff; */
  color: #707070;
  padding-inline: var(--fs16);
  background-color: transparent;
  color: var(--Text_light_dark_to_white66);
border-radius: 0;
}

.modal_QA_Content_item .company_Name.error {
  border-bottom: 1px solid red;
}

.modal_QA_Content_item .company_Name:focus {
  outline: none;
  /* border-bottom: 1px solid #0496ff; */
}

.modal_QA_Content_item .company_Name:active {
  outline: none;
  /* border-bottom: 1px solid #0496ff; */
}

.modal_QA_List_Options {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  column-gap: 2.5%;
  padding-top: 4vh;
}

.radio_optonList {
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 0;
  column-gap: var(--fs18);
}

@media (max-width: 1200px) {
  .modal_QA_Content_item .modal_QA_Question {
    font-size: var(--fs20);
  }

  .modal_QA_Content_item .modal_QA_Question.radio {
    width: 100%;
  }

  .modal_QA_Content_item .company_Name {
    border: 0;
    border-bottom: 1px solid #707070;
    width: 40%;
    font-size: var(--fs20);
  }

  .modal_QA_List_Options {
    padding-top: 3vh;
    flex-wrap: wrap;
  }

  .radio_optonList {
    column-gap: var(--fs18);
  }
}

@media (max-width: 576px) {
  .modal_QA_Content_item .company_Name {
    width: 70%;
    font-size: var(--fs20);
    margin: 0;
  }
}
