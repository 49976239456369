.subCategoryList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 20%;
}

.tabListRight {
  width: 75%;
}

/* .subCategoryList .subCategoryItem {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}

.subCategoryList .subCategoryItem button {
  border: none;
  width: 100%;
  padding-block: var(--fs28);
  padding-left: var(--fs28);
  color: #333333;
  display: flex;
  column-gap: var(--fs14);
  align-items: center;
  font-family: HomeRegularFamily;
  font-size: var(--fs20);
}

.subCategoryList .subCategoryItem button img {
  width: 8%;
}

.subCategoryList .subCategoryItem button.active {
  background-color: #0496ff;
  color: #fff;
  position: relative;
}

.subCategoryList .subCategoryItem button.active img {
  filter: invert(1);
}

.subCategoryList .subCategoryItem button.active::after {
  content: "";
  position: absolute;
  width: 7%;
  aspect-ratio: 1 / 1;
  background-color: #0496ff;
  transform: translate(50%, -50%) rotate(45deg);
  right: 0;
  top: 50%;
  border-top-right-radius: 0.2rem;
}

.subCategoryList .subCategoryItem:nth-of-type(1) button {
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
}

.subCategoryList .subCategoryItem:last-child button {
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.subCategoryList
  .subCategoryItem
  button:not(.subCategoryList .subCategoryItem:last-child button) {
  border: 1px solid rgba(0, 0, 0, 0.1);
} */
@media (max-width:1200px) {
  .subCategoryList {
    width: 30%;
  }

  .tabListRight {
    width: 65%;
  }
}

@media (max-width:576px) {
  .subCategoryList {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: var(--fs20);
  }
}