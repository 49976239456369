@font-face {
  font-family: HomeLightFamily;
  src: url(../../../assets/fonts/GTWalsheimPro-Light.ttf);
}

@font-face {
  font-family: HomeRegularFamily;
  src: url(../../../assets/fonts/GTWalsheimPro-Regular.ttf);
}

@font-face {
  font-family: HomeBoldFamily;
  src: url(../../../assets/fonts/GTWalsheimPro-Bold.ttf);
}

@font-face {
  font-family: HomeMediumFamily;
  src: url(../../../assets/fonts/GTWalsheimPro-Medium.ttf);
}

@font-face {
  font-family: alanta;
  src: url(../../../assets/fonts/Alata-Regular.ttf);
}

.header {
  display: flex;
  justify-content: space-between;
  padding-inline: var(--fs74);
  margin-top: 4vh;
  width: 100%;
}

.header h2 {
  font-family: HomeLightFamily;
  font-family: HomeRegularFamily;
  font-size: var(--fs36);
  color: var(--Text_light_dark_to_white33);
  letter-spacing: 2px;
}

.header .navigators {
  display: flex;
  column-gap: var(--fs12);
}

.header .navigators button {
  background-color: transparent;
  border: none;
}

.header .navigators button img {
  width: var(--fs45);
}

@media (max-width:1200px) {
  .header {
    padding-inline: var(--fs55);
    align-items: center;
  }

  .header h2 {
    font-size: var(--fs28);
    letter-spacing: 1.8px;
  }

  .header .navigators button img {
    width: var(--fs40);
  }
}

@media (max-width:768px) {
  .header {
    padding-inline: var(--fs10) 0;
    align-items: baseline;
  }

  .header h2 {
    font-size: var(--fs24);
  }

  .header .navigators button img {
    width: var(--fs35);
  }
}

@media (max-width:576px) {
  .header {
    padding-inline: 0;
  }

  .header h2 {
    font-size: var(--fs22);
  }

  .header .navigators {
    column-gap: 0;
  }
}


@media (max-width:375px) {
  .header .navigators button img {
    width: var(--fs30);
  }
}