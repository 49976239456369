.modalTextInput {
  width: 100%;
  padding: var(--fs10);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.4rem;
  font-family: HomeRegularFamily;
  font-size: var(--fs20);
  color:var(--Text_light_dark_to_white66);
  background-color: transparent;
}

.modalTextInput.error {
  border: var(--bs-border-width) solid red;
}

.modalTextInput:focus {
  outline: none;
}
