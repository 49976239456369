.data_SourceModal {
  width: 50%;
  background-color: var(--nav_bgdark_to_light);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 4vh var(--fs55);
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
}

form.dataSourceForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2vh;
}

form .tableWrapper {
  border-radius: 0.4rem;
  border: 1px solid #e7e7e7;
  width: 100%;
}

form .tableWrapper .dataSourceTable {
  width: 100%;
  border-collapse: collapse;
}

@media (max-width:1200px) {
  .data_SourceModal {
    width: 70%;
    padding: 4vh var(--fs50);
  }
}

@media (max-width:790px) {
  .data_SourceModal {
    width: 90%;
    padding: 4vh var(--fs30);
  }
}