.ownModalSubMitContainer {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-top: 2vh;
}

.ownModalSubMitContainer button:nth-of-type(1) {
  background-color: transparent;
  border: none;
  font-size: var(--fs23);
  color: #0496ff;
  display: flex;
  align-items: center;
}
.ownModalSubMitContainer button:nth-of-type(1) img {
  margin-right: var(--fs10);
  width: var(--fs32);
}

.ownModalSubMitContainer button:nth-of-type(2) {
  position: relative;
  border: none;
  background-color: transparent;
  transition: 0.3s ease;
  padding: 1vh 1.75%;
  font-size: var(--fs18);
  color: var(--Text_light_dark_to_white66);
}
.ownModalSubMitContainer button:nth-of-type(2) img {
  margin-right: var(--fs10);
  width: var(--fs16);
}
.ownModalSubMitContainer button:nth-of-type(2):hover img {
  filter: unset;
}
.ownModalSubMitContainer button:nth-of-type(2) img {
  font-size: var(--fs40);
  transition: 0.3s ease-in;
  transform: rotate(-33deg);
  filter: brightness(0) invert(0);
}
.ownModalSubMitContainer button:nth-of-type(2):before {
  position: absolute;
  content: "";
  width: 99.8%;
  height: 50%;
  left: 0;
  top: 0;
  border-left: 2px solid #414447;
  border-top: 2px solid #414447;
  border-top-left-radius: var(--fs10);
  border-top-right-radius: var(--fs10);
  transition: all 0.3s ease-in;
}
.ownModalSubMitContainer button:nth-of-type(2)::after {
  position: absolute;
  content: "";
  width: 50%;
  height: 99%;
  right: 0;
  bottom: 0;
  border-right: 2px solid #414447;
  border-bottom: 2px solid #414447;
  border-bottom-right-radius: var(--fs10);
  border-top-right-radius: var(--fs10);
  transition: all 0.3s ease-in;
}
.ownModalSubMitContainer button:nth-of-type(2):hover {
  background: -webkit-linear-gradient(
    77deg,
    rgba(17, 10, 255, 1) 0%,
    rgba(0, 144, 255, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  color: #3e3eed;
}
.ownModalSubMitContainer button:nth-of-type(2):hover img {
  transform: rotate(0deg);
}
.ownModalSubMitContainer button:nth-of-type(2):hover::before {
  height: 98%;
  border-left: 2px solid #3e3eed;
  border-top: 2px solid #3e3eed;
  border-bottom-left-radius: var(--fs10);
}
.ownModalSubMitContainer button:nth-of-type(2):hover::after {
  width: 99.8%;
  border-right: 2px solid #508efb;
  border-bottom: 2px solid #508efb;
  border-bottom-left-radius: var(--fs10);
}
