@media (max-width: 1200px) {
  :root {
    --fs10: 08.25px;
    --fs11: 08.875px;
    --fs12: 09.5px;
    --fs13: 010.125px;
    --fs14: 010.75px;
    --fs15: 012.375px;
    --fs16: 12px;
    --fs17: 12.625px;
    --fs18: 13.25px;
    --fs19: 13.875px;
    --fs20: 14.5px;
    --fs21: 15.125px;
    --fs22: 15.75px;
    --fs23: 16.375px;
    --fs24: 17px;
    --fs25: 17.625px;
    --fs26: 18.25px;
    --fs27: 18.875px;
    --fs28: 19.5px;
    --fs29: 20.125px;
    --fs30: 20.75px;
    --fs31: 21.75px;
    --fs32: 22px;
    --fs33: 22.625px;
    --fs34: 23.25px;
    --fs35: 23.875px;
    --fs36: 24.5px;
    --fs37: 25.125px;
    --fs38: 25.75px;
    --fs39: 26.375px;
    --fs40: 27px;
    --fs41: 27.625px;
    --fs42: 28.25px;
    --fs43: 28.875px;
    --fs44: 28.5px;
    --fs45: 30.125px;
    --fs46: 30.75px;
    --fs47: 31.375px;
    --fs48: 32px;
    --fs49: 32.625px;
    --fs50: 33.25px;
    --fs51: 33.875px;
    --fs52: 34.5px;
    --fs53: 35.125px;
    --fs54: 35.75px;
    --fs55: 36.375px;
    --fs56: 37px;
    --fs57: 37.625px;
    --fs58: 38.25px;
    --fs59: 38.875px;
    --fs60: 39.5px;
    --fs70: 39.5px;
    --fs80: 57px;
  }

  .billingArea {
    .subscription_tab_container {
      width: 100%;
      .tab_btn_container {
        width: 50%;

        // li {
        //   width: 50%;
        //   button {
        //     font-size: var(--fs24);
        //   }
        // }
      }
      .tab_content_container {
        .subscription_card_container {
          display: flex;
          flex-wrap: wrap;
          gap: 2em !important;
          height: 70vh;
          overflow-y: auto !important;
          .subscription_card {
            width: 32% !important;
            border-radius: var(--fs40);

            &:nth-child(1) {
              &:hover {
                .tag {
                  .tag_content {
                    &::before {
                      height: 100%;
                    }
                  }
                }
              }
            }
            &:nth-child(2) {
              &:hover {
                .tag {
                  .tag_content {
                    &::before {
                      height: 100%;
                    }
                  }
                }
              }
            }
            &:nth-child(3) {
              &:hover {
                .tag {
                  .tag_content {
                    &::before {
                      height: 100%;
                    }
                  }
                }
              }
            }

            // .card_content {
            //   h2 {
            //     font-size: var(--fs45);
            //     span {
            //       font-size: var(--fs30);
            //     }
            //   }
            //   h3 {
            //     font-size: var(--fs24);
            //   }
            // }
            // .tag {
            //   .tag_content {
            //     h5 {
            //       font-size: var(--fs28);
            //     }
            //   }
            // }
          }
        }
      }
    }
  }
  .home_container_header {
    h2 {
      font-size: var(--fs40);
    }
    p {
      font-size: var(--fs23);
      width: unset;
      line-height: var(--fs23);
    }
  }
  // -----------------------------------------home screen-------------------------------------------------------
  header.home {
    nav {
      .nav_bar {
        .nav_links {
          width: 75%;
        }
      }
    }
  }
  .landing_container {
    padding-top: 0;
    .carosel_inner {
      height: 100vh;
      display: flex;
      align-items: center;
    }
  }
  // home  slider
  .banner_container {
    width: 100%;
    margin-left: 0;
    .scroll_down {
      display: none;
      font-size: var(--fs20);
    }

    .banner_content {
      flex-direction: column;
      align-items: center;

      .banner_content_cotainer {
        order: 2;
        width: 100%;
        text-align: center;
        h2 {
          width: 80%;
          font-size: 32px;
          margin: auto;
        }
        .btn_container {
          // margin-left: 4%;
          justify-content: center;
          button {
            font-size: 18px;
            padding: 1.3vh 3%;
            img {
              width: 18px;
            }
          }
        }
      }
      .banner_img_container {
        order: 1;
      }
    }
  }

  // products use case section

  .product_container {
    .product_tab_container {
      flex-direction: column;
      .product_btn_container {
        width: 100%;
        .nav_tabs {
          width: 100%;
          display: flex;
          overflow-x: auto;
          overflow-y: hidden;
          gap: 1%;
          padding-bottom: 3vh;
        }
        button {
          // min-width: 15em !important;
          padding: 1vh var(--fs20);
          font-size: var(--fs21);
          gap: var(--fs13);
          border-radius: var(--fs12);
          img {
            width: var(--fs24);
          }
          // align-items: center;
          // &::before {
          //   top: 60%;
          //   left: 91%;
          //   transform: rotate(40deg);
          //   background-color: #ffffff;
          // }
          // &::after {
          //   bottom: 49%;
          //   left: 93%;
          //   transform: rotate(48deg);
          //   background-color: #fff;
          // }
          // &::after,
          // &::before {
          //   content: "";

          //   display: none;
          // }
        }
      }

      // .product_content_container {
      //   padding-top: 1em;
      //   width: 100%;
      //   .product_card_container {
      //     justify-content: center;
      //     gap: 5.5%;
      //     .product_card {
      //       width: 28%;
      //       padding: 5vh 3% 3vh;

      //       .img_holder {
      //         // width: 30%;
      //         // margin: auto;
      //         // padding-bottom: 3vh;
      //       }
      //       .product_card_body {
      //         h5 {
      //           font-size: var(--fs24);
      //         }
      //         p {
      //           font-size: var(--fs20);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  // Enterprise use case section

  .enterprises_usecase_Container {
    margin-bottom: 5vh;
    .enterprises_chip_container {
      ul {
        justify-content: center;
        li {
          padding: 1vh var(--fs12);
          font-size: var(--fs20);
          gap: var(--fs12);
          img {
            width: var(--fs20);
          }
          margin-bottom: 1.5vh;
          &::after {
            height: 70%;
            top: 15%;
            right: -8px;
          }
        }
      }
    }
    .enterprises_flip_card_container {
      .enterprises_flip_card {
        width: 30%;

        .card_front {
          h4 {
            font-size: var(--fs25);
          }
        }
        .img_holder {
          width: 30%;
        }

        .card_back {
          p {
            margin: 0;
            font-size: var(--fs15);
          }
          h4 {
            margin-bottom: 1vh;
          }
        }
      }
    }
  }

  .blog_Article_Container {
    .blog_card_container {
      .blog_card {
        .card_body {
          h6 {
            font-size: 12px;
          }
          h3 {
            font-size: var(--fs26);
          }
        }
      }
    }
  }
  // about us section

  .about_us_container {
    .home_container_header {
      margin-bottom: 6vh;
    }
    .about_us_content_holder {
      flex-direction: column;

      .about_us_left_content_container {
        order: 2;
        width: 100%;
        p {
          font-size: var(--fs27);
        }
      }
      .about_us_right_content_container {
        order: 1;
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  // footer

  .footer_container {
    // flex-direction: column;
    flex-wrap: wrap;
    ul {
      li {
        a {
          padding-bottom: 1vh;
        }
      }
    }
    .footer_content {
      &.social {
        width: 30%;
      }
      &:nth-child(1) {
        width: 100%;
      }
      width: 50%;

      h2 {
        font-size: var(--fs40);
        img {
          width: 20%;
        }
      }

      ul {
        li {
          a {
            padding-bottom: 1vh;
          }
        }
      }
    }
    .footer_Enterprise_content {
      width: 100% !important;
    }
  }

  // login page
  .login_container,
  .register_container {
    .login_content_container,
    .register_content_container {
      .login_header,
      .register_header {
        p {
          font-size: var(--fs22);
        }
      }
      .login_Form,
      .register_Form {
        .form_group {
          input {
            // font-size: var(--fs22);
          }
        }
        .keep_me_forget {
          a {
            font-size: var(--fs18);
          }
          .keep_login {
            label {
              font-size: var(--fs18);
            }
          }
        }
        .submit {
          button {
            font-size: var(--fs19);
          }
        }
        .login_with {
          p {
            font-size: var(--fs16);
          }
        }
        .sign_up {
          p {
            font-size: var(--fs16);
          }
        }
      }
    }
    .res_log_footer {
      p {
        font-size: var(--fs16);
      }
    }
  }

  // usecase page

  .sub_nav {
    .sub_nav_right_Content {
      width: max-content;
      .btn_container {
        gap: var(--fs25);
      }
    }
    .sub_nav_left_Content {
      p {
        font-size: var(--fs20);
      }
    }
  }

  .use_case_ex_container {
    .use_case_ex_header {
      h2 {
        font-size: var(--fs40);
      }
      p {
        font-size: var(--fs18);
      }
    }
    .use_case_ex_card_container {
      justify-content: center;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 60vh;
      &::-webkit-scrollbar {
        width: 3px !important;
        background: transparent !important;

      }

      &::-webkit-scrollbar-track {
        background: transparent !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #cccccc !important;
        border-radius: 11px !important;
        border: none  !important;
      }
      .use_case_ex_card {
        width: 24%;
        // height: max-content;
        .usecase_Card_holder {
          .use_case_card_body {
            h3 {
              font-size: var(--fs25);
            }
            p {
              font-size: var(--fs18);
            }
          }
        }
      }
    }
  }
  .enterprises_usecase_card_container {
    .enterprises_usecase_card {
      width: 18.3%;
      border: 1px solid #ccc;
      padding: 2.5vh 0.5%;
      border-radius: var(--fs12);

      h4 {
        font-size: var(--fs20);
      }
    }
  }

  // invite your  page
  .settingsContent {
    // margin: 0 7%;
    box-sizing: border-box;
    margin-top: var(--fs20);
    position: relative;

    p {
      margin: 0;
    }

    .invite_tableArea {
      width: 100%;
    }
  }

  .popupVerify {
    width: 40%;
  }

  // billing
  .settingsContent {
    .billingArea {
      .billingImg {
        width: 90%;
        object-fit: contain;
      }
    }
  }
  // ------------------------------------------qa contet------------------------
  .qa_docs_container {
    padding: 0 7%;

    .qa_docs_selectionArea {
      .input_container {
        .form_group {
          input {
            font-size: var(--fs18);
            padding: 1.75vh 2%;
          }
        }
      }
      .qa_docs_tabHeadArea {
        .qa_docs_tabHead {
          font-size: var(--fs20);

          input {
            width: var(--fs19);
            height: var(--fs19);
          }
        }
      }

      .qa_docs_tabbodyArea {
        .subHead {
          font-size: var(--fs25);
        }

        .qa_docs_tabbody {
          //  ---------------- cards area start-------------
          .qa_docs_cards {
            gap: 1.25%;

            .qa_docs_card {
              width: 32.5%;
              margin-bottom: var(--fs17);

              .cardImg {
                display: flex;
                width: 13%;
                align-items: center;
                aspect-ratio: 1/1;
                box-sizing: border-box;

                img {
                  width: 100%;
                }
              }
              .card_body {
                width: 80%;
              }

              .qa_docs_card_name {
                font-size: var(--fs22);
                margin: 0 0 var(--fs10);
              }

              .qa_docs_card_desc {
                font-size: var(--fs18);
              }
            }
          }

          .qa_docs_generate {
            display: flex;
            justify-content: end;

            button {
              background-color: transparent;
              padding: 1.2vh 3.5%;
              margin-bottom: 4vh;
              font-size: var(--fs20);
            }
          }

          //  ---------------- cards area end -------------
          .qa_fileDrop_area {
            .file-upload {
              text-align: center;
              cursor: pointer;
            }

            .file-uploadArea {
              display: flex;
              align-items: center;
              height: 39vh;
              gap: var(--fs20);
              box-sizing: border-box;

              .custom-label {
                border: 1px dashed #007df9;
                width: 30%;
                height: 100%;

                h4 {
                  margin: var(--fs13);
                }

                .uploadImg {
                  width: 23%;

                  img {
                    width: 100%;
                  }
                }
              }

              .file-list {
                height: 100%;
                width: 60%;

                .file-item {
                  .file-name {
                    h6 {
                      font-size: var(--fs18);
                    }

                    p {
                      font-size: var(--fs16);
                    }
                  }
                }
              }
            }
          }
        }

        .active {
          display: block;
        }
      }

      .urlHeading {
        font-size: var(--fs25);
      }
    }

    .qa_docs_chatArea {
      &.summarization {
        height: 66vh;
      }
      // height: 63vh;

      .topHeader {
        p {
          #headContent {
            font-size: var(--fs18);
          }
        }

        a {
          p {
            font-size: var(--fs17);
          }

          img {
            width: var(--fs23);
          }
        }
      }

      .summarizeSelectArea {
        h2 {
          font-size: var(--fs30);
        }

        .radioInputs {
          .summurizeRadioBtn {
            font-size: var(--fs17);

            input {
              width: var(--fs17);
              height: var(--fs17);
            }
          }
        }
      }

      .formatSelector {
        width: 40%;
        .formatLibraryArea {
          padding: 1vh 4%;

          p {
            font-size: var(--fs16);
          }

          ul {
            .bulletList {
              width: var(--fs25);

              &:hover {
                border-bottom: 1px solid #007bf4;
              }

              img {
                width: 100%;
              }
            }

            .active {
              border-bottom: 1px solid #007bf4;
            }
          }
        }
      }

      #chatScroll {
        width: 100%;
        &.QA {
          height: calc(
            100vh -
              (var(--fs74) + var(--fs20) + var(--fs250) + 3.5vh + var(--fs150) + 100px )
          );
        }
        &.Summarization {
          height: calc(
            100vh -
              (var(--fs74) + var(--fs20) + var(--fs150) + 3.5vh + var(--fs250) + 100px )
          );
        }
        .chatDialogBox {
          padding: var(--fs15) var(--fs20);

          .chatQuestion {
            p {
              margin-bottom: 10px;
            }
            a {
              font-size: var(--fs17);
            }

            .chatQuestion_btns {
              gap: var(--fs20);

              .chatQuestion_btn {
                width: var(--fs30);
              }
            }
          }

          .chatAnswer {
            padding: var(--fs10) 0 var(--fs30) 0;
            font-size: var(--fs16);

            h2 {
              font-size: var(--fs20);
            }

            ul {
              li {
                font-size: var(--fs14);

                .bullet {
                  width: var(--fs10);
                  width: var(--fs15);
                  height: var(--fs15);
                }

                .bulletText {
                  width: 90%;
                }
              }
            }
          }

          .chatReaction_container {
            width: 200px;
            top: 83%;

            .emoji-container {
              right: 100%;
              transform: translate(155%);
              padding: 0 var(--fs30);

              .emoji {
                width: var(--fs46);
              }
            }
            .emoji {
              width: var(--fs50);
              padding: var(--fs10);
            }
          }
        }
      }

      .chatFooter {
        position: relative;
        bottom: 0%;

        .footerChips {
          li {
            font-size: var(--fs13);
            gap: var(--fs12);
          }
        }
      }
      .typingArea {
        .inputBox {
          display: flex;
          width: 100%;

          input {
            font-size: var(--fs18);
            border-radius: var(--fs45);
          }
        }

        .sendBtn {
          width: var(--fs50);
        }
      }
    }
  }
  .slideOver {
    .sliderContent {
      .slideClose {
        top: 4vh;
        right: 3%;
      }

      .qa_fileDrop_area {
        .file-uploadArea {
          display: flex;
          align-items: center;
          height: 80vh;
          flex-direction: column;
          gap: var(--fs20);
          box-sizing: border-box;

          .subBtn {
            p {
              font-size: var(--fs20);
            }
          }

          .custom-label {
            border: 1px dashed #007df9;
            width: 90%;
            height: 40%;
          }
        }
      }
    }
  }
  // use_case_ex_container
  // -------------------------------------text to image /pdf container-----------------------------------
  .text_to_image_container {
    .input_container {
      .form_group {
        margin: 3vh 0;
        input {
          font-size: var(--fs20);
          padding: 1.25vh 2%;
        }
      }
    }
    .text_to_image_selectionArea {
      .text_to_image_tabHeadArea {
        .text_to_image_tabHead {
          font-size: var(--fs20);
        }
      }
    }
    .generate_btn_container {
      button {
        font-size: var(--fs20);
      }
    }
    .image_preview_container {
      .image_preview_header {
        h3 {
          font-size: var(--fs23);
        }
      }
      .image_preview_body {
        h5 {
          font-size: var(--fs18);
        }
        h2 {
          font-size: var(--fs30);
        }
        .choose_font_color_picker {
          h4 {
            font-size: var(--fs17);
          }
          .choose_font_Container {
            padding: 0.75vh 1%;

            select {
              font-size: var(--fs15);
            }
          }
          .color_picker_container {
            border-radius: var(--fs10);
            padding: 0.75vh 1%;

            img {
              width: var(--fs28);
              margin-right: var(--fs10);
            }
          }
        }
        .preview__pdf_holder {
          .img_holder {
            width: 11.35%;

            button.download {
              width: var(--fs18);
              height: var(--fs18);
              bottom: 5%;
              right: 5%;
              img {
                width: var(--fs10);
              }
            }
            button.play {
              top: 43%;
              left: 40%;
              background-color: transparent;
              img {
                width: var(--fs25);
              }
            }
          }
        }
        .preview_images_holder {
          .img_holder {
            width: 11.35%;

            button.download {
              width: var(--fs21);
              height: var(--fs21);
              bottom: 5%;
              right: 5%;
            }
            button.play {
              top: 43%;
              left: 40%;
            }
          }
        }
      }
    }
  }

  header.home_res_log,
  header.usecase {
    nav {
      .nav_list {
        column-gap: 0;
        width: 56%;
        justify-content: space-between;
      }
      .nav_other_options {
        display: flex;
        margin: 0;
        padding: 0;
        column-gap: var(--fs17);

        li {
          list-style: none;
          &:nth-of-type(1) {
            button {
              img {
                width: 62%;
              }
            }
          }
          &:nth-of-type(2) {
            button {
              img {
                width: 70%;
              }
            }
          }
          &:nth-of-type(3) {
            button {
              border-left: 1px solid #e2e2e2;
              border-right: 1px solid #e2e2e2;
              padding-inline: var(--fs16);
              span {
                font-size: var(--fs16);
              }
              img {
                width: 22%;
              }
            }
          }
          &:nth-of-type(4) {
            button {
              img {
                width: 70%;
              }
            }
          }
          button {
            border: none;
            background-color: transparent;
            padding: 0;
            cursor: pointer;
          }
        }
      }
    }
  }

  #exampleModal {
    .enterprises_usecase_modal {
      max-width: 63%;

      .enterprises_usecase_modal_content {
        padding: 3vh 7%;
        .enterprises_usecase_modal_header {
          margin-bottom: var(--fs15);

          h2 {
            font-size: var(--fs26);
            span {
              font-size: var(--fs18);
            }
          }
        }
        .enterprises_usecase_modal_body {
          .table_hoder {
            .table {
              tbody {
                tr {
                  td:nth-of-type(2) {
                    .website_form_group {
                      input {
                        padding-right: 20%;
                      }
                      .add_url_links {
                        font-size: var(--fs12);
                      }
                    }
                    .modal_chip_container {
                      row-gap: var(--fs13);
                      .chip {
                        border: 1px solid #ccc;
                        border-radius: var(--fs20);
                        display: flex;
                        align-items: center;
                        padding: 0.1% 2%;
                        column-gap: var(--fs10);
                        p {
                          margin: 0;
                          font-size: var(--fs15);
                          color: #b6b6b6;
                        }
                        button {
                          border: none;
                          background-color: transparent;
                          width: var(--fs12);
                          padding: 0;
                          margin-left: var(--fs3);
                          img {
                            width: 100%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  //
}
@media (max-width: 992px) {
  .how_we_enabling_Container {
    .how_we_enabling_img_holder {
      padding-top: 3vh;
    }
  }
  .billingArea {
    .subscription_tab_container {
      .tab_content_container {
        .subscription_card_container {
          .subscription_card {
            width: 35% !important;
          }
        }
      }
    }
  }
  .home_container_header {
    margin-bottom: 1.5vh;
    h2 {
      font-size: var(--fs30);
    }
    p {
      font-size: var(--fs18);
      width: unset;
      line-height: var(--fs18);
    }
  }
  // ------------------------home-----------------------------
  .register_container .register_content_container .register_header h3 {
    font-size: var(--fs39);
  }
  .login_container .login_content_container .login_header h3 {
    font-size: var(--fs32);
  }

  // header
  header.home_res_log,
  header.usecase {
    nav {
      padding-left: var(--fs30);
      .ham-icon {
        display: block;
        position: absolute;
        right: 2%;
      }
      // .ham-checkbox:checked ~ .nav_list {
      //   // transform: translateY(-200%);
      //   height: 0px;
      //   overflow: hidden;
      // }

      .nav_list {
        position: absolute;
        flex-direction: column;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0px;
        overflow: hidden;
        padding-left: 5%;
        background-color: rgba(252, 252, 252, 0.2);
        // background-color: #fff;
        transition: height 0.3s ease-in-out;
        transition-delay: 0.1s;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: var(--nav_bgdark_to_light);
        li {
          margin: 0.5em;
          &.hider {
            display: block;
          }
          a {
            font-size: var(--fs20);
          }
        }
      }

      .nav_other_options {
        // display: none;
        margin-right: 2%;
        li {
        display: none;
        &:nth-of-type(2) {
          display: block;
          margin-right: var(--fs15);
        }
          &:nth-of-type(3) {
            button {
              span {
                display: none;
              }
              img {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
  header.home {
    nav {
      .nav_bar {
        padding: 2vh 5.6% 2vh 5.6%;
        background-color: #fff;
        &.fixTop {
          background-color: #fff;
        }
        .logo_ {
          min-width: 8em;
        }

        .ham-icon {
          display: block;
        }

        // .ham-checkbox:checked ~ .nav_links {
        //   // transform: translateY(-200%);
        //   height: 0px;
        //   overflow: hidden;
        // }

        .nav_links {
          position: absolute;
          flex-direction: column;
          height: 0;
          justify-content: unset;
          overflow: hidden;
          top: 100%;
          left: 0;
          width: 100%;
          padding-left: 5%;
          background-color: rgba(252, 252, 252, 0.2);
          background-color: #fff;

          -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(20px);
          box-shadow: 0px 5px 13px 0px rgba(227, 227, 227, 0.48);
          transition: height 0.3s ease-in-out;
          transition-delay: 0.1s;
          z-index: -1;
          li {
            margin: 0.5em;
            a {
              font-size: var(--fs25);
            }
          }
        }
      }
    }
  }

  // home  slider
  .banner_container {
    width: 100%;
    margin-left: 0;
    .banner_content {
      flex-direction: column;
      align-items: center;

      .banner_content_cotainer {
        order: 2;
        h2 {
          font-size: 30px;
          text-align: center;
        }
      }
      .banner_img_container {
        order: 1;
      }
    }
  }

  // products use case section

  .product_container {
    .product_tab_container {
      width: 95%;
      .product_btn_container {
        width: 100%;
        button {
          padding: 1vh var(--fs16);
          font-size: var(--fs18);
          gap: var(--fs11);
          border-radius: var(--fs10);
          img {
            width: var(--fs20);
          }
        }
      }

      // .product_content_container {
      //   padding-top: 1em;
      //   width: 100%;
      //   .product_card_container {
      //     justify-content: center;
      //     gap: 4%;
      //     .product_card {

      //       .img_holder {
      //         width: 55%;
      //         margin: auto;
      //         // padding-bottom: 3vh;
      //       }
      //       .product_card_body {
      //         h5 {
      //           font-size: var(--fs26);
      //         }
      //         p {
      //           font-size: var(--fs19);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  // Enterprise use case section

  .enterprises_usecase_Container {
    margin-bottom: 0vh;
    .enterprises_chip_container {
      ul {
        li {
          font-size: var(--fs16);
          img {
            width: var(--fs16);
          }
          &::after {
            height: 65%;
            top: 17.5%;
            right: -6px;
          }
        }
      }
    }
    .enterprises_flip_card_container {
      .enterprises_flip_card {
        width: 30%;

        .card_front {
          h4 {
            font-size: var(--fs25);
          }
        }
        .img_holder {
          width: 30%;
        }

        .card_back {
          p {
            margin: 0;
            // font-size: var(--fs13);
          }
          h4 {
            margin-bottom: 1vh;
          }
        }
      }
    }
  }

  // blog article

  .blog_Article_Container {
    .blog_card_container {
      gap: 5.25%;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2em;
      .blog_card {
        width: 40%;
        margin: 0 auto;
        .card_body {
          h3 {
            font-size: var(--fs23);
          }
        }
      }
    }
  }

  // about us section

  .about_us_container {
    .home_container_header {
      margin-bottom: 6vh;
    }
    .about_us_content_holder {
      flex-direction: column;

      .about_us_left_content_container {
        order: 2;
        width: 100%;
        p {
          font-size: var(--fs24);
        }
      }
      .about_us_right_content_container {
        order: 1;
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  // usecase page

  .sub_nav {
    width: 85.5%;
    margin: auto;
    border-bottom: 1px solid #b9b9b9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: HomeRegularFamily;
    &.ad_content {
      padding-bottom: 0vh;
      padding-top: 0vh;
    }
    .sub_nav_left_Content {
      h2.jhon {
        font-size: var(--fs44);
        margin: 0;
        padding-bottom: 0.5vh;
        color: var(--text-dark_light);
        img {
          width: var(--fs43);
          margin-left: var(--fs10);
        }
      }
      h2.Own_case {
        color: var(--text-dark_light);

        font-size: var(--fs36);
      }
      .breadcrums {
        color: #037ef9;
        padding-bottom: 0.5vh;
        a {
          font-size: var(--fs14);
          text-decoration: none;
          color: #037ef9;
        }
      }
      h3.use_case_detailer {
        font-size: var(--fs37);
        margin: 0;
        img {
          width: var(--fs38);
          margin-right: var(--fs10);
        }
      }
      p {
        font-size: var(--fs17);
        margin: 0;
        color: var(--text-dark_grey);
        padding-bottom: 1vh;
      }
    }
    .sub_nav_right_Content {
      button {
        img {
          width: var(--fs12);
          transform: unset;
        }
        background-color: transparent;
        padding: 1.2vh var(--fs15);
        margin-bottom: 0vh;
        font-size: var(--fs20);
      }
      button.Own_case {
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(10, 131, 250, 1) 0%,
          rgba(124, 190, 254, 1) 100%
        );
        color: #ffffff;
        padding: 0.5vh;
        border-radius: var(--fs25);
        border-color: #037ef9;
        font-size: var(--fs18);
        border-width: 3px;
        border-bottom-color: #037ef9;
        border-right-color: #037ef9;
      }
      button.closed {
        background-color: transparent;
        border: none;
        img {
          width: var(--fs27);
        }
      }
    }
  }

  .use_case_ex_container {
    .use_case_ex_header {
      h2 {
        font-size: var(--fs40);
      }
      p {
        font-size: var(--fs18);
      }
    }
    .use_case_ex_card_container {
      // height: 60vh;
      .use_case_ex_card {
        width: 30%;   
        .usecase_Card_holder {
          .use_case_card_body {
            h3 {
              font-size: var(--fs25);
            }
            p {
              font-size: var(--fs18);
            }
          }
        }
      }
    }
  }
  .enterprises_usecase_card_container {
    align-items: stretch;
    // height: 60vh;
    overflow-y: scroll;
    .enterprises_usecase_card {
      width: 23.5%;
      border-radius: var(--fs10);

      h4 {
        font-size: var(--fs16);
      }
    }
  }
  // invite your  page
  .settingsContent {
    // margin: 0 7%;
    box-sizing: border-box;
    margin-top: var(--fs20);
    position: relative;

    p {
      margin: 0;
    }

    .invite_tableArea {
      width: 100%;
    }
  }

  .popupVerify {
    width: 50%;
  }
  // -------------------------------------text to image /pdf container-----------------------------------
  .text_to_image_container {
    .input_container {
      .form_group {
        margin: 3vh 0;
        input {
          font-size: var(--fs20);
        }
      }
    }
    .text_to_image_selectionArea {
      .text_to_image_tabHeadArea {
        .text_to_image_tabHead {
          font-size: var(--fs20);
        }
      }
    }
    .generate_btn_container {
      button {
        font-size: var(--fs20);
      }
    }
    .image_preview_container {
      .image_preview_header {
        h3 {
          font-size: var(--fs23);
        }
      }
      .image_preview_body {
        h5 {
          font-size: var(--fs18);
        }
        h2 {
          font-size: var(--fs30);
        }
        .choose_font_color_picker {
          h4 {
            font-size: var(--fs17);
          }
          .choose_font_Container {
            padding: 0.75vh 1%;

            select {
              font-size: var(--fs15);
            }
          }
          .color_picker_container {
            border-radius: var(--fs10);
            padding: 0.75vh 1%;

            img {
              width: var(--fs28);
            }
          }
        }
        .preview__pdf_holder {
          .img_holder {
            width: 14.35%;

            button.download {
              width: var(--fs18);
              height: var(--fs18);
              bottom: 5%;
              right: 5%;
              img {
                width: var(--fs10);
              }
            }
            button.play {
              top: 43%;
              left: 40%;
              img {
                width: var(--fs25);
              }
            }
          }
        }
        .preview_images_holder {
          .img_holder {
            width: 14.35%;

            button.download {
              width: var(--fs21);
              height: var(--fs21);
              bottom: 5%;
              right: 5%;
            }
            button.play {
              top: 43%;
              left: 40%;
            }
          }
        }
      }
    }
  }
  //----------------------thankyou_container------------
  .thankyou_container {
    .thankyou_illustration_holder {
      width: 35%;
    }
    .thankyou_text_Content {
      text-align: center;
      h3 {
        margin-bottom: 2vh;
        font-size: var(--fs40);
      }
      h4 {
        margin-bottom: 6vh;
        font-size: var(--fs28);
      }
      p {
        margin-bottom: 4vh;
        font-size: var(--fs26);
      }
      .btn_holder {
        button {
          padding: 1.2vh 2.5%;
          font-size: var(--fs25);
        }
      }
    }
  }

  // ---------------qa
  // ------------------------------------------qa contet------------------------
  .qa_docs_container {
    padding: 0 7%;

    .qa_docs_selectionArea {
      .input_container {
        .form_group {
          input {
            font-size: var(--fs18);
            padding: 1.75vh 2%;
          }
        }
      }
      .qa_docs_tabHeadArea {
        .qa_docs_tabHead {
          font-size: var(--fs20);

          input {
            width: var(--fs19);
            height: var(--fs19);
          }
        }
      }

      .qa_docs_tabbodyArea {
        padding-top: 2vh;
        padding-bottom: 4vh;
        .subHead {
          font-size: var(--fs25);
        }

        .qa_docs_tabbody {
          //  ---------------- cards area start-------------
          .qa_docs_cards {
            gap: 1.25%;
            justify-content: space-between;
            .qa_docs_card {
              width: 45%;
              margin-bottom: var(--fs17);
              flex-direction: column;
              justify-content: flex-start;
              .cardImg {
                display: flex;
                width: 20%;
                align-items: center;
                aspect-ratio: 1/1;
                box-sizing: border-box;

                img {
                  width: 100%;
                }
              }
              .card_body {
                width: 80%;
              }

              .qa_docs_card_name {
                font-size: var(--fs22);
                margin: 0 0 var(--fs10);
              }

              .qa_docs_card_desc {
                font-size: var(--fs18);
              }
            }
          }

          .qa_docs_generate {
            display: flex;
            justify-content: end;
            margin-top: 4vh;
            button {
              background-color: transparent;
              padding: 1.2vh 3.5%;
              margin-bottom: 0;
              font-size: var(--fs20);
            }
          }

          //  ---------------- cards area end -------------
          .qa_fileDrop_area {
            .file-upload {
              text-align: center;
              cursor: pointer;
            }

            .file-uploadArea {
              display: flex;
              align-items: center;
              height: 36vh;
              gap: var(--fs20);
              box-sizing: border-box;

              .custom-label {
                border: 1px dashed #007df9;
                width: 35%;
                height: 100%;

                h4 {
                  margin: var(--fs13);
                }

                .uploadImg {
                  width: 23%;

                  img {
                    width: 100%;
                  }
                }
              }

              .file-list {
                height: 100%;
                width: 60%;
                justify-content: unset;
                .file-item {
                  .file-name {
                    h6 {
                      font-size: var(--fs18);
                    }

                    p {
                      font-size: var(--fs16);
                    }
                  }
                }
              }
            }
          }
        }

        .active {
          display: block;
        }
      }

      .urlHeading {
        font-size: var(--fs25);
      }
    }

    .qa_docs_chatArea {
      // &.summarization {
      //   height: 66vh;
      // }
      // height: 63vh;
      height: calc(100vh - (var(--fs74) + var(--fs20) + var(--fs150) + 10.5vh));
      .formater_contaner {
        height: 56px;
      }
      .topHeader {
        p {
          #headContent {
            font-size: var(--fs18);
          }
        }

        a {
          p {
            font-size: var(--fs17);
          }

          img {
            width: var(--fs23);
          }
        }
      }

      .summarizeSelectArea {
        h2 {
          font-size: var(--fs30);
        }

        .radioInputs {
          .summurizeRadioBtn {
            font-size: var(--fs17);

            input {
              width: var(--fs17);
              height: var(--fs17);
            }
          }
        }
      }

      .formatSelector {
        width: 40%;
        .formatLibraryArea {
          padding: 1vh 4%;

          p {
            font-size: var(--fs16);
          }

          ul {
            .bulletList {
              width: var(--fs25);

              &:hover {
                border-bottom: 1px solid #007bf4;
              }

              img {
                width: 100%;
              }
            }

            .active {
              border-bottom: 1px solid #007bf4;
            }
          }
        }
      }

      #chatScroll {
        width: 100%;
        &.QA {
          height: calc(
            100vh -
              (var(--fs74) + var(--fs20) + var(--fs250) + 10.5vh + var(--fs150) + 10vh)
          );
        }
        &.Summarization {
          height: calc(
            100vh -
              (
                var(--fs74) + var(--fs20) + var(--fs250) + 10.5vh + 0vh +
                  var(--fs250) + 10vh
              )
          );
        }
        // height: calc(
        //   100vh - (var(--fs74) + var(--fs20) + var(--fs150) + 3.5vh + 250px)
        // );
        .chatDialogBox {
          padding: var(--fs15) var(--fs20);

          .chatQuestion {
            p {
              margin-bottom: 10px;
            }
            a {
              font-size: var(--fs17);
            }

            .chatQuestion_btns {
              gap: var(--fs20);

              .chatQuestion_btn {
                width: var(--fs30);
              }
            }
          }

          .chatAnswer {
            padding: var(--fs10) 0 var(--fs30) 0;
            font-size: var(--fs16);

            h2 {
              font-size: var(--fs20);
            }

            ul {
              li {
                font-size: var(--fs14);

                .bullet {
                  width: var(--fs10);
                  width: var(--fs15);
                  height: var(--fs15);
                }

                .bulletText {
                  width: 90%;
                }
              }
            }
          }

          .chatReaction_container {
            width: 200px;
            top: 83%;

            .emoji-container {
              right: 100%;
              transform: translate(155%);
              padding: 0 var(--fs30);

              .emoji {
                width: var(--fs46);
              }
            }
            .emoji {
              width: var(--fs50);
              padding: var(--fs10);
            }
          }
        }
      }

      .chatFooter {
        position: relative;
        bottom: 0%;
        margin-top: var(--fs18);
        .footerChips {
          margin-right: 0;
          li {
            font-size: var(--fs11);
            gap: var(--fs11);
          }
        }
      }
      .typingArea {
        .inputBox {
          display: flex;
          width: 100%;

          input {
            font-size: var(--fs16);
            border-radius: var(--fs45);
          }
        }

        .sendBtn {
          width: var(--fs50);
        }
      }
    }
  }
  .slideOver {
    display: none;
    .sliderContent {
      .slideClose {
        top: 4vh;
        right: 3%;
      }

      .qa_fileDrop_area {
        .file-uploadArea {
          display: flex;
          align-items: center;
          height: 80vh;
          flex-direction: column;
          gap: var(--fs20);
          box-sizing: border-box;

          .subBtn {
            p {
              font-size: var(--fs20);
            }
          }

          .custom-label {
            border: 1px dashed #007df9;
            width: 90%;
            height: 40%;
          }
        }
      }
    }
  }

  .enterpises_container {
    width: 100%;
    .enterpises_card_container {
      justify-content: center;
      .enterpises_card {
        width: 35%;
      }
    }
  }

  //-------------------------------- enterprises_usecase_modal------------------------------
  .enterprises_usecase_modal {
    max-width: 63%;
    .enterprises_usecase_modal_content {
      .enterprises_usecase_modal_header {
        button {
          width: 2.5%;
        }
        h2 {
          font-size: var(--fs24);
          span {
            font-size: var(--fs16);
          }
        }
      }
      .enterprises_usecase_modal_body {
        .table_hoder {
          max-height: 60vh;

          .table {
            thead {
              tr {
                th {
                  padding: var(--fs10) var(--fs14);
                  font-size: var(--fs19);
                }
              }
            }
            tbody {
              tr {
                td {
                  &.data_name {
                    font-size: var(--fs20);
                    padding-left: 6.5%;
                  }
                  padding: var(--fs10) var(--fs16);
                }
                td:nth-of-type(1) {
                  .check_holder {
                    .custom_holder {
                      column-gap: var(--fs15);

                      .checkmark {
                        border-radius: var(--fs7);
                        width: var(--fs28);
                      }
                      .labelTxt {
                        font-size: var(--fs15);
                      }
                    }
                  }
                }
                td:nth-of-type(2) {
                  .website_form_group {
                    input {
                      padding: 0.5vh 2%;
                      font-size: var(--fs16);
                      border-radius: 6px;
                      padding: 6px;
                      &::placeholder {
                        font-size: var(--fs16);
                      }
                    }
                    .add_url_links {
                      font-size: var(--fs12);
                    }
                  }
                  .modal_chip_container {
                    gap: 4%;
                    row-gap: var(--fs14);
                    .chip {
                      border-radius: var(--fs15);
                      padding: 0.1% 2%;
                      column-gap: var(--fs10);
                      p {
                        font-size: var(--fs12);
                      }
                      button {
                        width: var(--fs10);
                        margin-left: var(--fs3);
                      }
                    }
                  }
                }
              }
            }
          }

          .submit_container {
            padding-bottom: 1vh;
            button {
              padding: 1.3vh 7.5%;
              font-size: var(--fs14);
              img {
                width: var(--fs20);
              }
            }
          }
        }
      }
      .submit_container {
        padding-bottom: 1vh;
        button {
          &::after {
            border-width: 1.5px;
          }
          &::before {
            border-width: 1.5px;
          }
          padding: 0.8vh var(--fs25);
          font-size: var(--fs20);
          img {
            width: var(--fs25);
          }
        }
      }
    }
  }
  #exampleModal {
    .enterprises_usecase_modal {
      max-width: 75%;
    }
  }
}

@media (max-width: 768px) {
  //  half border button styles start
  .half_border_button {
    &::before {
      border-left: 1px solid #414447;
      border-top: 1px solid #414447;
    }
    &::after {
      border-right: 1px solid #414447;
      border-bottom: 1px solid #414447;
    }

    &:hover {
      &::before {
        border-left: 1px solid #3e3eed;
        border-top: 1px solid #3e3eed;
      }
      &::after {
        width: 99.8%;
        border-right: 1px solid #508efb;
        border-bottom: 1px solid #508efb;
      }
    }
  }
  .footer_container {
    .footer_content {
      &.social {
        padding-bottom: 2vh;
        ul {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .billingArea {
    .subscription_tab_container {
      .tab_btn_container {
        width: 60% !important;
      }
      .tab_content_container {
        .subscription_card_container {
          .subscription_card {
            width: 48% !important;
          }
        }
      }
    }
  }
  .slideOver {
    width: 70%;
    .slideTrigger {
      right: 90%;
    }
  }
  // home------------------

  header.home {
    nav {
      .nav_bar {
        .logo_ {
          min-width: 8em;
        }
        .nav_links {
          li {
            a {
              font-size: var(--fs21);
            }
          }
        }
      }
    }
  }

  .banner_container {
    .banner_content {
      .banner_content_cotainer {
        order: 2;
        h2 {
          font-size: 25px;
          text-align: center;
        }
        .btn_container {
          button {
            font-size: 18px;
            img {
              width: 22px;
            }
          }
        }
      }
      .banner_img_container {
        order: 1;
        width: 60%;
      }
    }
  }
  .footer_container {
    margin-top: 2vh;
    margin-bottom: 3vh;
    .footer_content {
      padding-bottom: 0vh;
      h2 {
        img {
          width: 25%;
        }
        padding-bottom: 3vh;
      }
    }
    .footer_Enterprise_content {
      display: none;
    }
  }

  header.home_res_log,
  header.usecase {
    nav {
      .nav_list {
        li {
          a {
            font-size: var(--fs20);
          }
        }
      }
    }
  }
  // products use case section

  .product_container {
    .product_tab_container {
      .product_btn_container {
        button {
          padding: 1vh var(--fs12);
          font-size: var(--fs16);
          gap: var(--fs10);
          border-radius: var(--fs10);
          margin-bottom: 2vh;
          img {
            width: var(--fs18);
          }
        }
      }
      .product_content_container {
        .product_card_container {
          gap: 4%;
          .product_card {
            width: 48%;
            .product_card_body {
              h5 {
                font-size: var(--fs21);
              }
              p {
                font-size: var(--fs16);
              }
            }
          }
        }
      }
    }
  }

  // Enterprise use case section

  .enterprises_usecase_Container {
    .enterprises_chip_container {
      ul {
        gap: 6px;
        li {
          gap: 3px;
          padding: 1vh 8px;
          border-radius: 5px;
          // font-size: var(--fs11);
          margin-bottom: 0;
          img {
            // width: var(--fs11);
          }
        }
      }
    }
    .enterprises_flip_card_container {
      padding-top: 3vh;
      .enterprises_flip_card {
        width: 40%;
        margin: 1em auto;

        .card_front {
          h4 {
            font-size: var(--fs20);
          }
        }
      }
    }
  }

  // blog article

  .blog_Article_Container {
    .blog_card_container {
      .blog_card {
        width: 50%;
        margin: 0 auto;
      }
    }
  }

  .about_us_container {
    .about_us_content_holder {
      .about_us_left_content_container {
        p {
          // font-size: var(--fs21);
        }
      }
    }
  }

  // usecase page

  .sub_nav {
    width: 85.5%;
    margin: auto;
    border-bottom: 1px solid #b9b9b9;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: HomeRegularFamily;
    padding-top: 1vh;
    flex-direction: column;

    .sub_nav_left_Content {
      width: 100%;
      h2.jhon {
        img {
          width: var(--fs43);
          margin-left: var(--fs10);
        }
      }
      h2.Own_case {
        color: var(--text-dark_light);

        font-size: var(--fs36);
      }
      .breadcrums {
        color: #037ef9;
        padding-block: 0.5vh;
        display: flex;
        align-items: center;
        gap: 4px;
        a {
          font-size: var(--fs14);
          text-decoration: none;
          color: #037ef9;
        }
      }
      h3.use_case_detailer {
        font-size: var(--fs37);
        img {
          width: var(--fs38);
          margin-right: var(--fs10);
        }
      }
      p {
        padding-bottom: 1vh;
      }
    }
    .sub_nav_right_Content {
      // width: 100%;
      display: flex;
      // flex-direction: column;
      justify-content: flex-end;
      align-self: flex-end;
      align-items: center;
      gap: 10px;
      p {
        margin: 0;
      }

      button {
        img {
          width: var(--fs12);
          transform: unset;
        }
        background-color: transparent;
        padding: 1.2vh var(--fs13);
        margin-bottom: 0vh;
        font-size: var(--fs20);
      }
      button.Own_case {
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(10, 131, 250, 1) 0%,
          rgba(124, 190, 254, 1) 100%
        );
        color: #ffffff;
        padding: 0.5vh;
        border-radius: var(--fs25);
        border-color: #037ef9;
        font-size: var(--fs18);
        border-width: 3px;
        border-bottom-color: #037ef9;
        border-right-color: #037ef9;
      }
      button.closed {
        background-color: transparent;
        border: none;
        img {
          width: var(--fs27);
        }
      }
    }
  }

  .use_case_ex_container {
    .use_case_ex_header {
      h2 {
        font-size: var(--fs40);
      }
      p {
        font-size: var(--fs18);
      }
    }
    .use_case_ex_card_container {
      width: 100%;
      .use_case_ex_card {
        width: 40%;

        .usecase_Card_holder {
          .use_case_card_body {
            h3 {
              font-size: var(--fs25);
            }
            p {
              font-size: var(--fs18);
            }
          }
        }
      }
    }
  }
  .enterprises_usecase_card_container {
    align-items: stretch;
    // height: 55vh;
    .enterprises_usecase_card {
      border-radius: var(--fs10);

      h4 {
        font-size: var(--fs16);
      }
    }
  }
  // footer
  footer.usecase {
    flex-direction: column;
  }

  // invite your  page
  .settingsContent {
    // margin: 0 7%;
    box-sizing: border-box;
    margin-top: var(--fs20);
    position: relative;

    p {
      margin: 0;
    }

    .invite_tableArea {
      width: 100%;
    }
  }

  .popupVerify {
    width: 70%;
  }
  // -------------------------------------text to image /pdf container-----------------------------------
  .text_to_image_container {
    .input_container {
      .form_group {
        margin: 3vh 0;
        input {
          font-size: var(--fs20);
        }
      }
    }
    .text_to_image_selectionArea {
      .text_to_image_tabHeadArea {
        .text_to_image_tabHead {
          font-size: var(--fs20);
        }
      }
    }
    .generate_btn_container {
      button {
        font-size: var(--fs20);
      }
    }
    .image_preview_container {
      .image_preview_header {
        h3 {
          font-size: var(--fs23);
        }
      }
      .image_preview_body {
        h5 {
          font-size: var(--fs18);
        }
        h2 {
          font-size: var(--fs30);
        }
        .choose_font_color_picker {
          h4 {
            font-size: var(--fs17);
          }
          .choose_font_Container {
            padding: 0.75vh 1%;

            select {
              font-size: var(--fs15);
            }
          }
          .color_picker_container {
            border-radius: var(--fs10);
            padding: 0.75vh 1%;

            img {
              width: var(--fs28);
            }
          }
        }
        .preview__pdf_holder {
          .img_holder {
            width: 18.35%;

            button.download {
              width: var(--fs22);
              height: var(--fs22);
              bottom: 5%;
              right: 5%;
              img {
                width: var(--fs18);
              }
            }
            button.play {
              top: 43%;
              left: 40%;
              img {
                width: var(--fs25);
              }
            }
          }
        }
        .preview_images_holder {
          .img_holder {
            width: 18.35%;

            button.download {
              width: var(--fs21);
              height: var(--fs21);
              bottom: 5%;
              right: 5%;
            }
            button.play {
              top: 43%;
              left: 40%;
            }
          }
        }
      }
    }
  }
  //----------------------thankyou_container------------
  .thankyou_container {
    .thankyou_illustration_holder {
      width: 45%;
    }
    .thankyou_text_Content {
      text-align: center;
      h3 {
        margin-bottom: 2vh;
        font-size: var(--fs40);
      }
      h4 {
        margin-bottom: 6vh;
        font-size: var(--fs28);
      }
      p {
        margin-bottom: 4vh;
        font-size: var(--fs26);
      }
      .btn_holder {
        button {
          padding: 1.2vh 2.5%;
          font-size: var(--fs25);
        }
      }
    }
  }

  // ------------------------------------------qa contet------------------------

  .sub_nav {
    .sub_nav_right_Content {
      // width: 34%;
      button {
        gap: 4px;
        font-size: 12px;
        padding: 1vh 8px;
      }
    }
  }
  .qa_docs_container {
    padding: 0 7%;

    .qa_docs_selectionArea {
      .input_container {
        .form_group {
          input {
            font-size: var(--fs18);
            padding: 1.75vh 2%;
          }
        }
      }
      .qa_docs_tabHeadArea {
        .qa_docs_tabHead {
          font-size: var(--fs20);

          input {
            width: var(--fs19);
            height: var(--fs19);
          }
        }
      }

      .qa_docs_tabbodyArea {
        padding-top: 2vh;
        padding-bottom: 4vh;
        .subHead {
          font-size: var(--fs25);
        }

        .qa_docs_tabbody {
          //  ---------------- cards area start-------------
          .qa_docs_cards {
            gap: 1.25%;
            justify-content: space-between;
            .qa_docs_card {
              width: 48%;
              margin-bottom: var(--fs17);

              .cardImg {
                display: flex;
                width: 20%;
                align-items: center;
                aspect-ratio: 1/1;
                box-sizing: border-box;

                img {
                  width: 100%;
                }
              }
              .card_body {
                width: 80%;
              }

              .qa_docs_card_name {
                font-size: var(--fs22);
                margin: 0 0 var(--fs10);
              }

              .qa_docs_card_desc {
                font-size: var(--fs18);
              }
            }
          }

          .qa_docs_generate {
            display: flex;
            justify-content: end;
            margin-top: 4vh;
            button {
              background-color: transparent;
              padding: 1.2vh 3.5%;
              margin-bottom: 0;
              font-size: var(--fs20);
            }
          }

          //  ---------------- cards area end -------------
          .qa_fileDrop_area {
            .file-upload {
              text-align: center;
              cursor: pointer;
            }

            .file-uploadArea {
              display: flex;
              align-items: center;
              height: 30vh;
              gap: var(--fs20);
              box-sizing: border-box;

              .custom-label {
                border: 1px dashed #007df9;
                width: 70%;
                height: 100%;

                h4 {
                  margin: var(--fs13);
                }

                .uploadImg {
                  width: 23%;

                  img {
                    width: 100%;
                  }
                }
              }

              .file-list {
                height: 100%;
                width: 60%;
                justify-content: unset;
                .file-item {
                  .file-name {
                    h6 {
                      font-size: var(--fs18);
                    }

                    p {
                      font-size: var(--fs16);
                    }
                  }
                }
              }
            }
          }
        }

        .active {
          display: block;
        }
      }

      .urlHeading {
        font-size: var(--fs25);
      }
    }

    .qa_docs_chatArea {
      .formater_contaner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex-direction: column;
        // height: 70px;
        margin-top: 1em;
        .formatSelector {
          align-self: flex-end;
          width: 100%;
        }
      }
      .radioInputs {
        flex-wrap: wrap;
      }
      // &.summarization {
      //   height: 66vh;
      // }
      // height: 63vh;

      .topHeader {
        p {
          #headContent {
            font-size: var(--fs18);
          }
        }

        a {
          p {
            font-size: var(--fs17);
          }

          img {
            width: var(--fs23);
          }
        }
      }

      .summarizeSelectArea {
        h2 {
          font-size: var(--fs30);
        }

        .radioInputs {
          .summurizeRadioBtn {
            font-size: var(--fs17);

            input {
              width: var(--fs17);
              height: var(--fs17);
            }
          }
        }
      }

      .formatSelector {
        width: 50%;
        .formatLibraryArea {
          padding: 1vh 4%;

          p {
            font-size: var(--fs16);
          }

          ul {
            .bulletList {
              width: var(--fs25);

              &:hover {
                border-bottom: 1px solid #007bf4;
              }

              img {
                width: 100%;
              }
            }

            .active {
              border-bottom: 1px solid #007bf4;
            }
          }
        }
      }

      #chatScroll {
        &.QA {
          height: calc(
            100vh -
              calc(var(--fs74) + var(--fs20) + var(--fs250) + 3.5vh + var(--fs150) + 100px)
          );
          // height: calc(100vh - calc(calc(var(--fs74) + var(--fs150)) + 13.5vh));
        }
        &.Summarization {
          height: calc(
            100vh -
              (
                var(--fs74) + var(--fs20) + var(--fs250) + 3.5vh + 0vh +
                  var(--fs250) + 100px
              )
          );
        }
        width: 100%;
        // height: 30vh;
        // height: calc(
        //   100vh - (var(--fs74) + var(--fs20) + var(--fs150) + 3.5vh + 250px)
        // );
        height: calc(
          100vh -
            (var(--fs74) + var(--fs20) + var(--fs150) + 3.5vh + 222px + 9vh)
        );

        // aspect-ratio: 1/0.25;

        .chatDialogBox {
          padding: var(--fs15) var(--fs20);

          .chatQuestion {
            p {
              margin-bottom: 10px;
            }
            a {
              font-size: var(--fs17);
            }

            .chatQuestion_btns {
              gap: var(--fs20);

              .chatQuestion_btn {
                width: var(--fs30);
              }
            }
          }

          .chatAnswer {
            padding: var(--fs10) 0 var(--fs30) 0;
            font-size: var(--fs16);

            h2 {
              font-size: var(--fs20);
            }

            ul {
              li {
                font-size: var(--fs14);

                .bullet {
                  width: var(--fs10);
                  width: var(--fs15);
                  height: var(--fs15);
                }

                .bulletText {
                  width: 90%;
                }
              }
            }
          }

          .chatReaction_container {
            width: 200px;
            top: 83%;

            .emoji-container {
              right: 100%;
              transform: translate(155%);
              padding: 0 var(--fs30);

              .emoji {
                width: var(--fs46);
              }
            }
            .emoji {
              width: var(--fs50);
              padding: var(--fs10);
            }
          }
        }
      }

      .chatFooter {
        position: relative;
        bottom: 0%;
        height: 10vh;

        margin-top: var(--fs20);
        .footerChips {
          margin-right: 0;
        }
      }
      .typingArea {
        .inputBox {
          display: flex;
          width: 100%;

          input {
            font-size: var(--fs20);
            border-radius: var(--fs45);
          }
        }

        .sendBtn {
          width: var(--fs50);
        }
      }
    }
  }
  .slideOver {
    display: none;

    .sliderContent {
      .slideClose {
        top: 4vh;
        right: 3%;
      }

      .qa_fileDrop_area {
        .file-uploadArea {
          display: flex;
          align-items: center;
          height: 80vh;
          flex-direction: column;
          gap: var(--fs20);
          box-sizing: border-box;

          .subBtn {
            p {
              font-size: var(--fs20);
            }
          }

          .custom-label {
            border: 1px dashed #007df9;
            width: 90%;
            height: 40%;
          }
        }
      }
    }
  }

  footer {
    .footer {
      flex-direction: column;
      .footer_right_content {
        width: 50%;
      }
    }
  }

  //-------------------------------- enterprises_usecase_modal------------------------------
  .enterprises_usecase_modal {
    max-width: 75%;
    .enterprises_usecase_modal_content {
      .enterprises_usecase_modal_header {
        button {
          width: 2.5%;
        }
        h2 {
          font-size: var(--fs22);
          span {
            font-size: var(--fs14);
          }
        }
      }
      .enterprises_usecase_modal_body {
        .table_hoder {
          max-height: 55vh;

          .submit_container {
            padding-bottom: 1vh;
            button {
              padding: 1vh 4.5%;
              font-size: var(--fs14);
              img {
                width: var(--fs20);
              }
            }
          }
        }
      }
      .submit_container {
        button {
          &::after {
            border-width: 1px;
          }
          &::before {
            border-width: 1px;
          }
          padding: 0.8vh var(--fs25);
          font-size: var(--fs20);
          border-radius: 5px;
          img {
            width: var(--fs22);
          }
        }
      }
    }
  }
  #exampleModal {
    .enterprises_usecase_modal {
      max-width: 85%;
    }
  }
}

@media (max-width: 576px) {
  footer .footer .footer_right_content a {
    font-size: 9px;
  }
  .footer_container {
    .footer_content {
      h2 {
        display: none;
      }
    }
    .footer_Enterprise_content {
      display: none;
    }
  }
  .footer_container .footer_content.social {
    padding-bottom: 0vh;
  }
  .enterprises_usecase_Container {
    .enterprises_chip_container {
      ul {
        li {
          // font-size: var(--fs11);
          img {
            width: var(--fs11);
          }
          &::after {
            height: 55%;
            right: -4px;
            top: 22.5%;
          }
        }
      }
    }
    .enterprises_flip_card_container {
      padding-top: 0vh;
      .enterprises_flip_card {
        width: 40%;
        margin: 1em auto;

        .card_front {
          h4 {
            font-size: var(--fs20);
          }
        }
      }
    }
  }
  .usecase_tab_Container {
    .usecase_tab_btn_container {
      width: 85.5%;
      padding-bottom: 4vh;

      .nav_tabs {
        border-radius: 6px;
        gap: 10px;
        button {
          padding: 0.6vh 10px;
          font-size: 14px;
          border-radius: 6px;
        }
        padding: 1vh 2%;
      }
    }
  }
  // billing
  .about_us_container {
    padding-bottom: 0;
  }
  .footer_container {
    margin-top: 1vh;
    margin-bottom: 2vh;
    .footer_content {
      padding-bottom: 1vh;
      ul {
        li {
          a {
            padding-bottom: 0.75vh;
            font-size: 13px;
          }
        }
      }
      h2 {
        margin-bottom: 0;
        padding: 0;
      }
    }
  }
  .landing_container {
    width: 90%;
    margin: 10vh auto 5vh auto;
    padding-top: 0;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    .carosel_inner {
      height: 87vh;
      display: flex;
      align-items: center;
    }
    .banner_container .banner_content .banner_img_container {
      width: 80%;
    }
  }
  .billingArea {
    .subscription_tab_container {
      .tab_content_container {
        .subscription_card_container {
          height: auto;
          .subscription_card {
            width: 75% !important;
            .tag {
              left: -12% !important;
            }
          }
        }
      }
    }
  }
  // enterprices content
  // enterprises content
  .enterpises_container {
    width: 100%;
    .enterpises_card_container {
      justify-content: center;
      .enterpises_card {
        width: 70%;
      }
    }
  }
  // summarization
  .qa_docs_container {
    .qa_docs_chatArea {
      .formater_contaner {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // flex-direction: column;
        // margin-top: 1em;
      }
      .radioInputs {
        flex-wrap: wrap;
      }
      #chatScroll {
        &.QA {
          height: calc(
            100vh -
              (var(--fs74) + var(--fs20) + var(--fs250) + 10.5vh + var(--fs150))
          );
        }
        &.Summarization {
          height: calc(
            100vh -
              (
                var(--fs74) + var(--fs20) + var(--fs250) + 10.5vh + 0vh +
                  var(--fs250)
              )
          );
        }
        .chatQuestion {
          flex-direction: column;
          .chatQuestion_btns {
            margin-top: 1em;
          }
        }
      }
    }
  }

  .slideOver {
    width: 80%;
  }
  // ---------------------------------------home----------------------------

  .banner_container {
    .banner_content {
      .banner_content_cotainer {
        width: 100% !important;
        h2 {
          font-size: 22px;
          text-align: center;
        }
        .btn_container {
          button {
            font-size: 14px;
            img {
              width: 18px;
            }
          }
        }
      }
    }
    .scroll_down {
      bottom: 10vh;
    }
  }

  // products use case section

  .product_container {
    h2.product {
      display: none;
    }
    .product_tab_container {
      display: none;
      .product_content_container {
        .product_card_container {
          gap: 0%;
          .product_card {
            width: 100%;
            margin-bottom: 3vh;
            // .img_holder {
            //   width: 50%;
            //   padding-bottom: 2.5vh;
            // }
          }
        }
      }
    }
    .product_accordian_tab_container {
      display: block;
    }
  }

  // Enterprise use case section

  .enterprises_usecase_Container {
    .enterprises_flip_card_container {
      .enterprises_flip_card {
        width: 48%;
        .card_back {
          padding: 5% 4%;
        }
        .card_front,
        .card_back {
          h4 {
            font-size: 15px;
          }
        }
      }
    }
  }

  // blog article

  .blog_Article_Container {
    width: 90%;
    margin: auto;
    border-radius: var(--fs20);
    background-image: url(../src/assets/images/usecase_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 5vh;
    padding-top: 10vh;
    margin-top: -10vh;

    .blog_card_container {
      width: 95%;
      height: 80vh;
      overflow-y: scroll;
      .card_img_holder {
        position: relative;
        transition: 0.3s ease-in;
        &:after {
          content: "";
          left: 0;
          position: absolute;
          height: var(--fs10);
          width: 100%;
          background-color: #fd2e3d;
        }
      }
      .blog_card {
        width: 90%;
      }
    }
  }

  // footer

  .footer_container {
    h3 {
      font-size: 18px;
    }
    .footer_Enterprise_content {
      width: 100% !important;
      ul {
        li {
          width: 100%;
          a {
            font-size: 12px;
          }
        }
      }
    }
  }

  footer {
    .footer {
      flex-direction: column;
    }
  }

  // login page
  .res_log_footer {
    flex-direction: column;
    .footer_right_content{
      min-width: 50% !important;
    }
  }

  // usecase page

  // .sub_nav {
  //   align-items: start;

  //   flex-direction: column;

  .use_case_ex_container {
    max-height: calc(100vh - (var(--fs60) + var(--fs150)));
    .use_case_ex_header {
      h2 {
        font-size: var(--fs40);
      }
      p {
        font-size: var(--fs18);
      }
    }
    .use_case_ex_card_container {
      justify-content: center;
      overflow-y: auto;
      overflow-x: hidden;
      height: unset;
      max-height: 60vh;
      width: 100%;
      .use_case_ex_card {
        width: 80%;

        .usecase_Card_holder {
          .img_holder {
            width: 15% !important;
          }
          .use_case_card_body {
            h3 {
              font-size: var(--fs25);
            }
            p {
              font-size: var(--fs18);
            }
          }
        }
      }
    }
  }
  .enterprises_usecase_card_container {
    width: 90%;
    .enterprises_usecase_card {
      width: 32%;
      border-radius: var(--fs10);

      h4 {
        font-size: var(--fs16);
      }
    }
  }
  footer.usecase {
    text-align: center;
    flex-direction: column;
    .footer_right_content {
      width: 70%;
    }
  }

  // invite your  page
  .settingsContent {
    box-sizing: border-box;
    margin-top: var(--fs20);
    position: relative;

    p {
      margin: 0;
    }
    .invite_table {
      overflow-x: auto;
      table {
        width: 200% !important;
      }
    }

    .invite_tableArea {
      width: 100%;
    }
  }

  .popupVerify {
    width: 90%;
  }

  // setings page
  .settingsContent {
    height: 65vh !important;
    overflow-y: auto !important;
  }
  .inputContainers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    .inputBox {
      width: 100% !important;
      margin: 1em;

      input {
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #a8a7a7;
        padding: var(--fs10) 0;
        font-size: var(--fs18);
        outline: none;
      }
    }

    button {
      font-family: HomeRegularFamily;
      background-color: transparent;
      padding: 1vh 6.5%;
      margin-bottom: 4vh;
      font-size: var(--fs20);
    }
  }
  // -------------------------------------text to image /pdf container-----------------------------------
  .text_to_image_container {
    .input_container {
      .form_group {
        margin: 3vh 0;
        input {
          font-size: var(--fs20);
        }
      }
    }
    .text_to_image_selectionArea {
      .text_to_image_tabHeadArea {
        .text_to_image_tabHead {
          font-size: var(--fs20);
        }
      }
    }
    .generate_btn_container {
      button {
        font-size: var(--fs20);
      }
    }
    .image_preview_container {
      .image_preview_header {
        h3 {
          font-size: var(--fs23);
        }
      }
      .image_preview_body {
        h5 {
          font-size: var(--fs18);
        }
        h2 {
          font-size: var(--fs30);
        }
        .choose_font_color_picker {
          h4 {
            font-size: var(--fs17);
          }
          .choose_font_Container {
            padding: 0.75vh 1%;

            select {
              font-size: var(--fs15);
            }
          }
          .color_picker_container {
            border-radius: var(--fs10);
            padding: 0.75vh 1%;

            img {
              width: var(--fs28);
            }
          }
        }
        .preview__pdf_holder {
          .img_holder {
            width: 20.35%;

            button.download {
              width: var(--fs22);
              height: var(--fs22);
              bottom: 5%;
              right: 5%;
              img {
                width: var(--fs18);
              }
            }
            button.play {
              top: 43%;
              left: 40%;
              img {
                width: var(--fs25);
              }
            }
          }
        }
        .preview_images_holder {
          .img_holder {
            width: 20.35%;

            button.download {
              width: var(--fs21);
              height: var(--fs21);
              bottom: 5%;
              right: 5%;
            }
            button.play {
              top: 43%;
              left: 40%;
            }
          }
        }
      }
    }
  }
  //----------------------thankyou_container------------
  .thankyou_container {
    .thankyou_illustration_holder {
      width: 50%;
    }
    .thankyou_text_Content {
      text-align: center;
      h3 {
        margin-bottom: 2vh;
        font-size: var(--fs40);
      }
      h4 {
        margin-bottom: 6vh;
        font-size: var(--fs28);
      }
      p {
        margin-bottom: 4vh;
        font-size: var(--fs26);
      }
      .btn_holder {
        button {
          padding: 1.2vh 2.5%;
          font-size: var(--fs25);
        }
      }
    }
  }

  .qa_docs_container {
    padding: 0 7%;
    // max-height: 60vh;
    // overflow-y: auto;
    .qa_docs_selectionArea {
      .input_container {
        .form_group {
          input {
            font-size: var(--fs18);
            padding: 1.75vh 2%;
          }
        }
      }
      .qa_docs_tabHeadArea {
        .qa_docs_tabHead {
          font-size: var(--fs20);

          input {
            width: var(--fs19);
            height: var(--fs19);
          }
        }
      }

      .qa_docs_tabbodyArea {
        padding-top: 2vh;
        padding-bottom: 4vh;
        .subHead {
          font-size: var(--fs25);
        }

        .qa_docs_tabbody {
          //  ---------------- cards area start-------------
          .qa_docs_cards {
            gap: 1.25%;
            justify-content: center;
            max-height: 54vh;
            overflow: auto;
            .qa_docs_card {
              width: 70%;
              margin-bottom: var(--fs17);
              align-items: start;
              .cardImg {
                display: flex;
                // width: 13%;
                align-items: center;
                aspect-ratio: 1/1;
                box-sizing: border-box;

                img {
                  width: 100%;
                }
              }
              .card_body {
                width: 80%;
              }

              .qa_docs_card_name {
                font-size: var(--fs22);
                margin: 0 0 var(--fs10);
              }

              .qa_docs_card_desc {
                font-size: var(--fs18);
              }
            }
          }

          .qa_docs_generate {
            display: flex;
            justify-content: center;
            margin-top: 4vh;
            button {
              background-color: transparent;
              padding: 1.2vh 3.5%;
              margin-bottom: 0;
              font-size: var(--fs20);
            }
          }

          //  ---------------- cards area end -------------
          .qa_fileDrop_area {
            .file-upload {
              text-align: center;
              cursor: pointer;
            }

            .file-uploadArea {
              height: unset;
              display: flex;
              align-items: center;
              flex-direction: column;
              row-gap: 5vh;
              // height: 30vh;
              gap: var(--fs20);
              box-sizing: border-box;

              .custom-label {
                border: 1px dashed #007df9;
                width: 70%;
                height: 100%;

                h4 {
                  margin: var(--fs13);
                }

                .uploadImg {
                  width: 23%;

                  img {
                    width: 100%;
                  }
                }
              }

              .file-list {
                max-height: 200px;
                width: 60%;
                justify-content: unset;
                .file-item {
                  .file-name {
                    h6 {
                      font-size: var(--fs18);
                      margin: 0;
                    }

                    p {
                      font-size: var(--fs16);
                      margin: 0;
                    }
                  }
                }
              }
            }
          }
        }

        .active {
          display: block;
        }
      }

      .urlHeading {
        font-size: var(--fs25);
      }
    }

    .qa_docs_chatArea {
      height: calc(100vh - (var(--fs74) + var(--fs20) + var(--fs150) + 3.5vh));
      // &.summarization {
      //   height: 66vh;
      // }
      // height: 63vh;
      .formater_contaner {
        height: 62px;
      }
      .topHeader {
        p {
          #headContent {
            font-size: var(--fs18);
          }
        }

        a {
          p {
            font-size: var(--fs17);
          }

          img {
            width: var(--fs23);
          }
        }
      }

      .summarizeSelectArea {
        h2 {
          font-size: var(--fs30);
        }

        .radioInputs {
          .summurizeRadioBtn {
            font-size: var(--fs17);

            input {
              width: var(--fs17);
              height: var(--fs17);
            }
          }
        }
      }

      .formatSelector {
        width: 100% !important;
        .formatLibraryArea {
          padding: 1vh 4%;

          p {
            font-size: var(--fs16);
          }

          ul {
            .bulletList {
              width: var(--fs25);

              &:hover {
                border-bottom: 1px solid #007bf4;
              }

              img {
                width: 100%;
              }
            }

            .active {
              border-bottom: 1px solid #007bf4;
            }
          }
        }
      }

      #chatScroll {
        width: 100%;
        // height: 30vh;
        // height: calc(
        //   100vh - (var(--fs74) + var(--fs20) + var(--fs150) + 3.5vh + 200px)
        // );
        // aspect-ratio: 1/0.25;

        .chatDialogBox {
          padding: var(--fs15) var(--fs20);

          .chatQuestion {
            p {
              margin-bottom: 10px;
            }
            a {
              font-size: var(--fs17);
              align-self: flex-start;
            }

            .chatQuestion_btns {
              gap: var(--fs20);

              .chatQuestion_btn {
                width: var(--fs30);
              }
            }
          }

          .chatAnswer {
            padding: var(--fs10) 0 var(--fs30) 0;
            font-size: var(--fs16);

            h2 {
              font-size: var(--fs20);
            }

            ul {
              li {
                font-size: var(--fs14);

                .bullet {
                  width: var(--fs10);
                  width: var(--fs15);
                  height: var(--fs15);
                }

                .bulletText {
                  width: 90%;
                }
              }
            }
          }

          .chatReaction_container {
            width: 200px;
            top: 83%;

            .emoji-container {
              right: 100%;
              transform: translate(155%);
              padding: 0 var(--fs30);

              .emoji {
                width: var(--fs46);
              }
            }
            .emoji {
              width: var(--fs50);
              padding: var(--fs10);
            }
          }
        }
      }

      .chatFooter {
        position: relative;
        bottom: 0%;
        margin-top: var(--fs20);
        .footerChips {
          margin-right: 0;
          display: none;
        }
      }
      .typingArea {
        .inputBox {
          display: flex;
          width: 100%;

          input {
            font-size: var(--fs20);
            border-radius: var(--fs45);
            padding-block: 1vh;
            padding-inline: 3%;
          }
        }

        .sendBtn {
          width: var(--fs50);
        }
      }
    }
  }
  .slideOver {
    display: none;
    .slideTrigger {
      right: 62%;
    }
    .sliderContent {
      .slideClose {
        top: 4vh;
        right: 3%;
      }

      .qa_fileDrop_area {
        .file-uploadArea {
          display: flex;
          align-items: center;
          height: 80vh;
          flex-direction: column;
          gap: var(--fs20);
          box-sizing: border-box;

          .subBtn {
            p {
              font-size: var(--fs20);
            }
          }

          .custom-label {
            border: 1px dashed #007df9;
            width: 90%;
            height: 40%;
          }
        }
      }
    }
  }
  .how_we_enabling_Container {
    .how_we_enabling_img_holder {
      img,
      svg {
        width: 100%;
        // &:nth-child(1) {
        //   display: none;
        // }
        // &:nth-child(2) {
        //   display: block;
        // }
      }
    }
  }

  //-------------------------------- enterprises_usecase_modal------------------------------
  .enterprises_usecase_modal {
    max-width: 100%;
    .enterprises_usecase_modal_content {
      .enterprises_usecase_modal_header {
        button {
          width: 3.5%;
        }
      }
      .enterprises_usecase_modal_body {
        .table_hoder {
          max-height: 55vh;
        }
      }
    }
  }
  #exampleModal {
    .enterprises_usecase_modal {
      max-width: 100%;
    }
  }
}
@media (max-width: 476px) {
  :root {
    --fs10: 06.25px;
    --fs11: 06.875px;
    --fs12: 07.5px;
    --fs13: 08.125px;
    --fs14: 08.75px;
    --fs15: 09.375px;
    --fs16: 10px;
    --fs17: 10.625px;
    --fs18: 11.25px;
    --fs19: 11.875px;
    --fs20: 12.5px;
    --fs21: 13.125px;
    --fs22: 13.75px;
    --fs23: 14.375px;
    --fs24: 15px;
    --fs25: 15.625px;
    --fs26: 16.25px;
    --fs27: 16.875px;
    --fs28: 17.5px;
    --fs29: 18.125px;
    --fs30: 18.75px;
    --fs31: 19.75px;
    --fs32: 20px;
    --fs33: 20.625px;
    --fs34: 21.25px;
    --fs35: 21.875px;
    --fs36: 22.5px;
    --fs37: 23.125px;
    --fs38: 23.75px;
    --fs39: 24.375px;
    --fs40: 25px;
    --fs41: 25.625px;
    --fs42: 26.25px;
    --fs43: 26.875px;
    --fs44: 27.5px;
    --fs45: 28.125px;
    --fs46: 28.75px;
    --fs47: 29.375px;
    --fs48: 30px;
    --fs49: 30.625px;
    --fs50: 31.25px;
    --fs51: 31.875px;
    --fs52: 32.5px;
    --fs53: 33.125px;
    --fs54: 33.75px;
    --fs55: 34.375px;
    --fs56: 35px;
    --fs57: 35.625px;
    --fs58: 36.25px;
    --fs59: 36.875px;
    --fs60: 37.5px;
    --fs70: 37.5px;
    --fs80: 55px;
  }
  // ------------------------------------------qa contet------------------------
  .sub_nav {
    .sub_nav_right_Content {
      align-self: flex-start;
      gap: 6px;
      p {
        width: 30%;
        font-size: 11px;
        margin-left: -30px;
      }
      .btn_container {
        gap: 6px;
        button {
          img {
            &.arrow {
              width: 8px;
            }
            &.icon {
              width: 14px;
            }

            font-size: 11px;
          }
        }
      }
    }
  }
  .qa_docs_container {
    padding: 0 7%;

    .qa_docs_selectionArea {
      .input_container {
        .form_group {
          input {
            font-size: var(--fs18);
            padding: 1.75vh 2%;
          }
        }
      }
      .qa_docs_tabHeadArea {
        .qa_docs_tabHead {
          font-size: var(--fs20);

          input {
            width: var(--fs19);
            height: var(--fs19);
          }
        }
      }

      .qa_docs_tabbodyArea {
        padding-top: 2vh;
        padding-bottom: 4vh;
        .subHead {
          font-size: var(--fs25);
        }

        .qa_docs_tabbody {
          //  ---------------- cards area start-------------
          .qa_docs_cards {
            gap: 1.25%;
            justify-content: center;
            .qa_docs_card {
              width: 85%;
              margin-bottom: var(--fs17);

              .cardImg {
                display: flex;
                width: 13%;
                align-items: center;
                aspect-ratio: 1/1;
                box-sizing: border-box;

                img {
                  width: 100%;
                }
              }
              .card_body {
                width: 80%;
              }

              .qa_docs_card_name {
                font-size: var(--fs22);
                margin: 0 0 var(--fs10);
              }

              .qa_docs_card_desc {
                font-size: var(--fs18);
              }
            }
          }

          //  ---------------- cards area end -------------
          .qa_fileDrop_area {
            .file-upload {
              text-align: center;
              cursor: pointer;
            }

            .file-uploadArea {
              height: unset;
              display: flex;
              align-items: center;
              flex-direction: column;
              row-gap: 5vh;
              // height: 30vh;
              gap: var(--fs20);
              box-sizing: border-box;

              .custom-label {
                border: 1px dashed #007df9;
                width: 80%;
                height: 100%;
                height: 150px;

                h4 {
                  margin: var(--fs13);
                }

                .uploadImg {
                  width: 23%;

                  img {
                    width: 100%;
                  }
                }
              }

              .file-list {
                max-height: 200px;
                width: 60%;
                justify-content: unset;
                .file-item {
                  .file-name {
                    h6 {
                      font-size: var(--fs18);
                      margin: 0;
                    }

                    p {
                      font-size: var(--fs16);
                      margin: 0;
                    }
                  }
                }
              }
            }
          }
        }

        .active {
          display: block;
        }
      }

      .urlHeading {
        font-size: var(--fs25);
      }
    }

    .qa_docs_chatArea {
      &.summarization {
        height: 66vh;
      }
      height: 63vh;

      .topHeader {
        p {
          #headContent {
            font-size: var(--fs18);
          }
        }

        a {
          p {
            font-size: var(--fs17);
          }

          img {
            width: var(--fs23);
          }
        }
      }

      .summarizeSelectArea {
        h2 {
          font-size: var(--fs30);
        }

        .radioInputs {
          .summurizeRadioBtn {
            font-size: var(--fs17);

            input {
              width: var(--fs17);
              height: var(--fs17);
            }
          }
        }
      }

      .formatSelector {
        width: 40%;
        .formatLibraryArea {
          padding: 1vh 4%;

          p {
            font-size: var(--fs16);
          }

          ul {
            .bulletList {
              width: var(--fs25);

              &:hover {
                border-bottom: 1px solid #007bf4;
              }

              img {
                width: 100%;
              }
            }

            .active {
              border-bottom: 1px solid #007bf4;
            }
          }
        }
      }

      #chatScroll {
        width: 100%;
        // height: 30vh;
        // aspect-ratio: 1/0.25;

        .chatDialogBox {
          padding: var(--fs15) var(--fs20);

          .chatQuestion {
            p {
              margin-bottom: 10px;
            }
            a {
              font-size: var(--fs17);
            }

            .chatQuestion_btns {
              gap: var(--fs20);

              .chatQuestion_btn {
                width: var(--fs30);
              }
            }
          }

          .chatAnswer {
            padding: var(--fs10) 0 var(--fs30) 0;
            font-size: var(--fs16);

            h2 {
              font-size: var(--fs20);
            }

            ul {
              li {
                font-size: var(--fs14);

                .bullet {
                  width: var(--fs10);
                  width: var(--fs15);
                  height: var(--fs15);
                }

                .bulletText {
                  width: 90%;
                }
              }
            }
          }

          .chatReaction_container {
            width: 200px;
            top: 83%;

            .emoji-container {
              right: 100%;
              transform: translate(155%);
              padding: 0 var(--fs30);

              .emoji {
                width: var(--fs46);
              }
            }
            .emoji {
              width: var(--fs50);
              padding: var(--fs10);
            }
          }
        }
      }

      .chatFooter {
        position: relative;
        bottom: 0%;
        margin-top: var(--fs20);
        padding-bottom: 2rem;
        .footerChips {
          margin-right: 0;
          li {
            font-size: var(--fs15);
          }
        }
      }
      .typingArea {
        .inputBox {
          display: flex;
          width: 100%;

          input {
            font-size: var(--fs20);
            border-radius: var(--fs45);
          }
        }

        .sendBtn {
          width: var(--fs50);
        }
      }
    }
  }
  .slideOver {
    display: none;
    .slideTrigger {
      // right: 42%;
    }
    .sliderContent {
      .slideClose {
        top: 4vh;
        right: 3%;
      }

      .qa_fileDrop_area {
        .file-uploadArea {
          display: flex;
          align-items: center;
          height: 80vh;
          flex-direction: column;
          gap: var(--fs20);
          box-sizing: border-box;

          .subBtn {
            p {
              font-size: var(--fs20);
            }
          }

          .custom-label {
            border: 1px dashed #007df9;
            width: 90%;
            height: 40%;
          }
        }
      }
    }
  }

  footer {
    .footer {
      .footer_right_content {
        width: 70%;
        a {
          font-size: 9px;
        }
      }
    }
  }

  .product_container {
    .product_tab_container {
      .product_content_container {
        .product_card_container {
          gap: 2%;
          .product_card {
            width: 49%;
            margin-bottom: 1.5vh;
            .product_card_body {
              h5 {
                font-size: var(--fs19);
              }
              p {
                font-size: var(--fs14);
              }
            }
            .img_holder {
              width: 45%;
            }
          }
        }
      }
    }
  }
}

//   @import "./ownModalQuery";
