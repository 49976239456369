.chipList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 2vh;
}

.chipList .chips {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.chipList .chips input {
  display: none;
}

.chipList .chips input:checked+.customLabel {
  border: 1px solid #0496ff;
  color: #0496ff;
}

.chipList .chips .customLabel {
  display: inline-block;
  background-color: transparent;
  padding: var(--fs7) var(--fs14);
  border: 1px solid var(--Text_light_dark_to_white66);
  color: var(--Text_light_dark_to_white66);
  border-radius: 100px;
  font-family: HomeRegularFamily;
  font-size: var(--fs18);
  cursor: pointer;
}

@media (max-width:1200px) {
  .chipList .chips .customLabel {
    padding: var(--fs5) var(--fs12);
    font-size: var(--fs16);
  }
}

@media (max-width:790px) {
  .chipList .chips .customLabel {
    padding: 3px var(--fs10);
    font-size: var(--fs13);
  }
}