.modal_QA_content_container {
  flex-grow: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}

.modal_QA_content_container::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.modal_QA_content_container::-webkit-scrollbar-track {
  background: transparent;
  width: 3px;
}

/* Handle */
.modal_QA_content_container::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 8px;
}

/* Handle on hover */
/* .modal_QA_content_container::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.modal_QA_content_container .modal_QA_Content_list {
  display: flex;
  flex-direction: column;
  row-gap: 6vh;
  width: 100%;
  padding: 0;
  margin: 0;
}

@media (max-width: 576px) {
  .modal_QA_content_container::-webkit-scrollbar {
    width: 0px !important;
    height: 12px;
    background-color: #ffffff;
  }
}
