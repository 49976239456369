.categoryList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.categoryList .categoryItem {
  padding: 0;
  margin: 0;
  list-style: none;
}

.categoryList .categoryItem .forOThers {
  display: flex;
  column-gap: var(--fs14);
}

.categoryList .categoryItem p {
  font-family: HomeMediumFamily;
  padding: 0;
  margin: 0;
  color: var(--Text_light_dark_to_white66);

  font-size: var(--fs20);
}

.categoryList .categoryItem .dataBaseList .dataBaseListItem {
  list-style: none;
}

.categoryList .categoryItem .dataBaseList .dataBaseListItem {
  padding: 0;
  margin: 0;
  list-style: none;
  color: var(--Text_light_dark_to_white66);
  font-size: var(--fs18);
  display: flex;
  column-gap: var(--fs14);
}

.categoryList .categoryItem .directSubCategory {
  list-style: none;
}

.categoryList .categoryItem .directSubCategory .directSubCategoryItem {
  padding: 0;
  margin: 0;
  list-style: none;
  color: var(--Text_light_dark_to_white66);
  font-size: var(--fs18);
}

.categoryList .categoryItem .directCategory {
  list-style: none;
}

.categoryList .categoryItem .directSubCategory .directCategoryItem {
  padding: 0;
  margin: 0;
  list-style: none;
 color: var(--Text_light_dark_to_white66);
  font-size: var(--fs20);
}

.categoryList .categoryItem .directCategory .directCategoryItem .subCategory {
  list-style: none;
}

.categoryList .categoryItem .directCategory .directCategoryItem .subCategory .subCategoryItem {
  padding: 0;
  margin: 0;
  list-style: none;
 color: var(--Text_light_dark_to_white66);
  font-size: var(--fs18);
}

.categoryList .categoryItem .directCategory .directCategoryItem .subCategory .subCategoryItem .subList {
  list-style: none;
}

.categoryList .categoryItem .directCategory .directCategoryItem .subCategory .subCategoryItem .subList .subListItem {
  padding: 0;
  margin: 0;
  list-style: none;
 color: var(--Text_light_dark_to_white66);
  font-size: var(--fs18);
}

@media (max-width:1200px) {
  .categoryList .categoryItem p {
    font-size: var(--fs19);
  }
}

@media (max-width:768px) {
  .categoryList .categoryItem p {
    font-size: var(--fs17);
  }
}