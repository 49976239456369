.radio_optionItem {
  list-style: none;
  padding: 0;
  margin: 0;
}

.radio_optionItem input {
  display: none;
}

/* .radio_optionItem input:checked+label span::before {
  background-image: url(../../../../../../assets/images/ownmodal/Radio/active.png);
} */

.radio_optionItem label {
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: var(--fs15);

}

.radio_optionItem label img{
 width: var(--fs30);

}

.radio_optionItem label span {
  display: inline-flex;
  font-family: HomeRegularFamily;
  align-items: center;
  font-size: var(--fs24);
  color: var(--Text_light_dark_to_white66);
  margin-right: var(--fs18);
  padding: 1px;
}

/* .radio_optionItem label span::before {
  content: "";
  display: inline-block;
  width: var(--fs30);
  height: var(--fs30);
  background-image: url(../../../../../../assets/images/ownmodal/Radio/inactive.png);
  background-size: cover;
  vertical-align: middle;
  background-repeat: no-repeat;
  margin-right: var(--fs15);
} */

@media (max-width:1200px) {


  .radio_optionItem label span {
    font-size: var(--fs20);
  }

  .radio_optionItem label img{
    width: var(--fs25);
   
   }


}

@media (max-width:475px) {
  .radio_optionItem label span {
    font-size: var(--fs18);
  }
}