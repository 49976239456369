.ownModalSubCategory_DropDown {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: -7%;
  transform: translateX(100%);
  width: 80%;
}

.ownModalSubCategory_DropDown .ownModalSubCategory_DropDown_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ownModalSubCategory_DropDown .ownModalSubCategory_DropDown_list:not(.ownModalSubCategory_DropDown .ownModalSubCategory_DropDown_list:last-child) button {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ownModalSubCategory_DropDown .ownModalSubCategory_DropDown_list:first-child button {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.ownModalSubCategory_DropDown .ownModalSubCategory_DropDown_list:last-child button {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.ownModalSubCategory_DropDown .ownModalSubCategory_DropDown_list button {
  border: none;
  width: 100%;
  background-color: var(--tab_btn_to_dark);
  color: var(--Text_light_dark_to_white66);
  font-family: HomeRegularFamily;
  text-align: start;
  font-size: var(--fs18);
  padding-block: var(--fs16);
  border: none;
}

@media (max-width:976px) {
  .ownModalSubCategory_DropDown {
    top: auto;
    bottom: 0;
    width: 100%;
  }
}

@media (max-width:576px) {
  .ownModalSubCategory_DropDown {
    top: 100%;
    right: auto;
    left: 70%;
    transform: translateX(-100%);
    width: 50vw;
    margin-top: var(--fs20);
    padding: 1px;

  }

  .ownModalSubCategory_DropDown .ownModalSubCategory_DropDown_list button {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}