
.OwnModalPreviewModal {
  width: 38%;
  /* background: var(--nav_bgdark_to_light); */
  
  height: 80%;
  position: absolute;
  left: 50%;
  background-color: #fff;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 4vh var(--fs55);
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 2vh;
  
}
.dark {
  background-color: #181e29 !important;
}
.OwnModalPreviewModal .previewListWrapper {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.OwnModalPreviewModal .previewListWrapper::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.OwnModalPreviewModal .previewListWrapper::-webkit-scrollbar-track {
  /* background: #fff; */
  background-color: var(--bg_body);
  width: 3px;
}

/* Handle */
.OwnModalPreviewModal .previewListWrapper::-webkit-scrollbar-thumb {
  background: #cccccc;

  border-radius: 8px;
}

/* Handle on hover */
/* .OwnModalPreviewModal .previewListWrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
.OwnModalPreviewModal .OwnModalList {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
}

@media (max-width: 1200px) {
  .OwnModalPreviewModal {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .OwnModalPreviewModal {
    width: 80%;
    padding: 3vh var(--fs25);
  }
}
