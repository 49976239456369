:root {
  --fs3: clamp(1.875px, 3vw * 100 / 1920, 3px);
  --fs5: clamp(3.125px, 5vw * 100 / 1920, 5px);
  --fs7: clamp(4.375px, 7vw * 100 / 1920, 7px);
  --fs10: clamp(06.25px, 10vw * 100 / 1920, 10px);
  --fs11: clamp(06.875px, 11vw * 100 / 1920, 11px);
  --fs12: clamp(07.5px, 12vw * 100 / 1920, 12px);
  --fs13: clamp(08.125px, 13vw * 100 / 1920, 13px);
  --fs14: clamp(08.75px, 14vw * 100 / 1920, 14px);
  --fs15: clamp(09.375px, 15vw * 100 / 1920, 15px);
  --fs16: clamp(10px, 16vw * 100 / 1920, 16px);
  --fs17: clamp(10.625px, 17vw * 100 / 1920, 17px);
  --fs18: clamp(11.25px, 18vw * 100 / 1920, 18px);
  --fs19: clamp(11.875px, 19vw * 100 / 1920, 19px);
  --fs20: clamp(12.5px, 20vw * 100 / 1920, 20px);
  --fs21: clamp(13.125px, 21vw * 100 / 1920, 21px);
  --fs22: clamp(13.75px, 22vw * 100 / 1920, 22px);
  --fs23: clamp(14.375px, 23vw * 100 / 1920, 23px);
  --fs24: clamp(15px, 24vw * 100 / 1920, 24px);
  --fs25: clamp(15.625px, 25vw * 100 / 1920, 25px);
  --fs26: clamp(16.25px, 26vw * 100 / 1920, 26px);
  --fs27: clamp(16.875px, 27vw * 100 / 1920, 27px);
  --fs28: clamp(17.5px, 28vw * 100 / 1920, 28px);
  --fs29: clamp(18.125px, 29vw * 100 / 1920, 29px);
  --fs30: clamp(18.75px, 30vw * 100 / 1920, 30px);
  --fs31: clamp(19.75px, 31vw * 100 / 1920, 31px);
  --fs32: clamp(20px, 32vw * 100 / 1920, 32px);
  --fs33: clamp(20.625px, 33vw * 100 / 1920, 33px);
  --fs34: clamp(21.25px, 34vw * 100 / 1920, 34px);
  --fs35: clamp(21.875px, 35vw * 100 / 1920, 35px);
  --fs36: clamp(22.5px, 36vw * 100 / 1920, 36px);
  --fs37: clamp(23.125px, 37vw * 100 / 1920, 37px);
  --fs38: clamp(23.75px, 38vw * 100 / 1920, 38px);
  --fs39: clamp(24.375px, 39vw * 100 / 1920, 39px);
  --fs40: clamp(25px, 40vw * 100 / 1920, 40px);
  --fs41: clamp(25.625px, 41vw * 100 / 1920, 41px);
  --fs42: clamp(26.25px, 42vw * 100 / 1920, 42px);
  --fs43: clamp(26.875px, 43vw * 100 / 1920, 43px);
  --fs44: clamp(27.5px, 44vw * 100 / 1920, 44px);
  --fs45: clamp(28.125px, 45vw * 100 / 1920, 45px);
  --fs46: clamp(28.75px, 46vw * 100 / 1920, 46px);
  --fs47: clamp(29.375px, 47vw * 100 / 1920, 47px);
  --fs48: clamp(30px, 48vw * 100 / 1920, 48px);
  --fs49: clamp(30.625px, 49vw * 100 / 1920, 49px);
  --fs50: clamp(31.25px, 50vw * 100 / 1920, 50px);
  --fs51: clamp(31.875px, 51vw * 100 / 1920, 51px);
  --fs52: clamp(32.5px, 52vw * 100 / 1920, 52px);
  --fs53: clamp(33.125px, 53vw * 100 / 1920, 53px);
  --fs54: clamp(33.75px, 54vw * 100 / 1920, 54px);
  --fs55: clamp(34.375px, 55vw * 100 / 1920, 55px);
  --fs56: clamp(35px, 56vw * 100 / 1920, 56px);
  --fs57: clamp(35.625px, 57vw * 100 / 1920, 57px);
  --fs58: clamp(36.25px, 58vw * 100 / 1920, 58px);
  --fs59: clamp(36.875px, 59vw * 100 / 1920, 59px);
  --fs60: clamp(37.5px, 60vw * 100 / 1920, 60px);
  --fs74: clamp(46.25px, 74vw * 100 / 1920, 74px);
  --fs100: clamp(62.5px, 100vw * 100 / 1920, 100px);
  --fs118: clamp(73.75px, 118vw * 100 / 1920, 118px);
  --fs150: clamp(93.75px, 150vw * 100 / 1920, 150px);
  --fs240: clamp(150px, 240vw * 100 / 1920, 240px);
  --fs250: clamp(155px, 250vw * 100 / 1920, 250px);
  --fs350: clamp(195px, 450vw * 100 / 1920, 450px);
  --bg_body: #fff;
  // background-color: var(--bg_body);
  --text-dark_light: #212529;
  --text-dark_grey: #212529;
  --card_light_dark: #f8f8f8;
  --Text_light_dark_to_white33: #333333;
  --Text_light_dark_to_white66: #666666;
  --card_bd_light_blue_to_dark: #eef6ff;
  --footer_bg_light_dark: #fff;
  --Text_dark_to_light: #000;
  --nav_bgdark_to_light: #fff;
  ---active_blue_dark: #3551f2;
  --text_area_to_dark: #e6e2e2;
  --text_answer_color: #eff6ff;
  --modal_card_todark: #f3f3f3;
  --tab_btn_to_dark: #f8f8f8;
  --preview_to_dark: #f4f9ff;
}
[data-theme="dark"] {
  --bg_body: #0b101b;
  background-color: var(--bg_body);
  --text-dark_light: #ffffff;
  --text-dark_grey: #a5a5a5;
  --card_light_dark: #181e29;
  --Text_light_dark_to_white33: #fff;
  --Text_light_dark_to_white66: #a5a5a5;
  --card_bd_light_blue_to_dark: #01162d;
  --footer_bg_light_dark: #181e29;
  --Text_dark_to_light: #fff;
  --nav_bgdark_to_light: #181e29;
  ---active_blue_dark: #0496ff;
  --text_area_to_dark: #181e29;
  --text_answer_color: #181e29;
  --modal_card_todark: #252d3d;
  --tab_btn_to_dark: #181e29;
  --preview_to_dark: #181e29;

  .filterDark {
    filter: brightness(0) invert(1) !important;
    transition: all 0.3s ease;
  }
}
.app_container {
  min-height: 100vh;
  transition: all 0.3s ease;
  background-color: var(--bg_body);
  // --bg_body: #fff;
  // background-color: var(--bg_body);
  // --text-dark_light: #212529;
  // --text-dark_grey: #212529;
  // --card_light_dark: #f8f8f8;
  // --Text_light_dark_to_white33: #333333;
  // --Text_light_dark_to_white66: #666666;
  // --card_bd_light_blue_to_dark: #eef6ff;
  // --footer_bg_light_dark: #fff;
  // --Text_dark_to_light: #000;
  // --nav_bgdark_to_light: #fff;
  // ---active_blue_dark: #3551f2;
  // --text_area_to_dark: #e6e2e2;
  // --text_answer_color: #eff6ff;
}
.app_container.dark {
  // --bg_body: #0b101b;
  // background-color: var(--bg_body);
  // --text-dark_light: #ffffff;
  // --text-dark_grey: #a5a5a5;
  // --card_light_dark: #181e29;
  // --Text_light_dark_to_white33: #fff;
  // --Text_light_dark_to_white66: #a5a5a5;
  // --card_bd_light_blue_to_dark: #01162d;
  // --footer_bg_light_dark: #181e29;
  // --Text_dark_to_light: #fff;
  // --nav_bgdark_to_light: #181e29;
  // ---active_blue_dark: #0496ff;
  // --text_area_to_dark: #181e29;
  // --text_answer_color: #181e29;

  // .filterDark {
  //   filter: brightness(0) invert(1);
  //   transition: all 0.3s ease;
  // }
}
body {
  // --bg_body: #fff;
  // background-color: var(--bg_body);
  // --text-dark_light: #212529;
  // --text-dark_grey: #212529;
  // --card_light_dark: #f8f8f8;
  // --Text_light_dark_to_white33: #333333;
  // --Text_light_dark_to_white66: #666666;
  // --card_bd_light_blue_to_dark: #eef6ff;
  // --footer_bg_light_dark: #fff;
  line-height: unset !important;
}
// body.dark {
//   --bg_body: #0b101b;
//   background-color: var(--bg_body);
//   --text-dark_light: #ffffff;
//   --text-dark_grey: #a5a5a5;
//   --card_light_dark: #181e29;
//   --Text_light_dark_to_white33: #fff;
//   --Text_light_dark_to_white66: #a5a5a5;
//   --card_bd_light_blue_to_dark: #01162d;
//   --footer_bg_light_dark: #181e29;

// }

@function fs($fs) {
  $percentageCalc: calc(((1920 - 1200) / 1920) * 100);
  $remainingPercent: calc(100 - $percentageCalc);
  $fontPercent: calc(($remainingPercent / 100) * $fs);
  $min-font-size: calc($fontPercent) + px;
  $font_vw: ($fs + vw);
  $font_mx: (calc($fs) + px);

  @return clamp(($min-font-size), calc(($font_vw) * 100 / 1920), $font_mx);
}
// font family
@font-face {
  font-family: HomeRegularFamily;
  src: url(../src/assets/fonts/GTWalsheimPro-Regular.ttf);
}
@font-face {
  font-family: HomeBoldFamily;
  src: url(../src/assets/fonts/GTWalsheimPro-Bold.ttf);
}
@font-face {
  font-family: HomeMediumFamily;
  src: url(../src/assets/fonts/GTWalsheimPro-Medium.ttf);
}
@font-face {
  font-family: alanta;
  src: url(../src/assets/fonts/Alata-Regular.ttf);
}

header.home_res_log,
header.usecase {
  font-family: HomeRegularFamily;
  position: sticky;
  z-index: 100;
  top: 0;
  nav {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // z-index: 1000;
    // top: 0;
    padding-inline: var(--fs74);
    // position: sticky;
    height: var(--fs74);
    transition: all 0.3s ease;

    background-color: var(--nav_bgdark_to_light);
    .logo_container {
      height: 50%;
      a {
        img {
          height: 100%;
        }
      }
    }
    #ham {
      display: none;
    }
    .ham-icon {
      display: none;
    }
    button.ham-icon {
      border: none;
      background-color: transparent;
    }
    .ham-checkbox {
      display: none;
    }
    .nav_list {
      display: flex;
      margin: 0;
      padding: 0;
      column-gap: var(--fs74);
      li {
        list-style: none;
        &.hider {
          display: none;
        }
        a {
          text-decoration: none;
          font-size: var(--fs17);
          color: var(--Text_dark_to_light);
          font-family: HomeRegularFamily;
          &.active,
          &:hover {
            color: var(---active_blue_dark);
            position: relative;
            &:after {
              content: "";
              position: absolute;
              width: 50%;
              left: 25%;
              height: var(--fs5);
              bottom: -55%;
              border-radius: var(--fs10);
              background-color: var(---active_blue_dark);
            }
          }
        }
      }
    }
    .nav_other_options {
      display: flex;
      margin: 0;
      padding: 0;
      column-gap: var(--fs25);

      li {
        list-style: none;
        &:nth-of-type(1) {
          button {
            img {
              // width: 62%;
              width: var(--fs22);
            }
          }
        }
        &:nth-of-type(2) {
          button {
            img {
              width: var(--fs25);
              // width: 70%;
            }
          }
        }
        &:nth-of-type(3) {
          button {
            border-left: 1px solid #e2e2e2;
            border-right: 1px solid #e2e2e2;
            padding-inline: var(--fs16);
            span {
              font-size: var(--fs16);
              color: var(--Text_dark_to_light);
              font-family: HomeRegularFamily;
              margin-right: var(--fs12);
            }
            img {
              width: 22%;
            }
          }
        }
        &:nth-of-type(4) {
          button {
            img {
              width: var(--fs23);

              // width: 70%;
            }
          }
        }
        button {
          border: none;
          background-color: transparent;
          padding: 0;
          cursor: pointer;
        }
      }
    }
  }
}
.home_img_container {
  width: 100%;
  img {
    width: 100%;
  }
}

// -----------------------------login screen styles--------------------------
.login_container,
.register_container {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: HomeRegularFamily;
  position: relative;
  .login_content_container,
  .register_content_container {
    padding: 4.5vh 4%;
    width: 31%;
    min-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #fff;
    border-radius: var(--fs20);
    .login_header,
    .register_header {
      text-align: center;
      h3 {
        margin-bottom: 0.4vh;
        font-family: HomeRegularFamily;
      }
      p {
        font-size: var(--fs16);
        margin-bottom: 3vh;
        color: #666666;
      }
    }
    .login_Form,
    .register_Form {
      .form_group {
        margin-bottom: 1vh;
        position: relative;
        button.password_type {
          position: absolute;
          right: 0;
          top: 25%;
          background-color: transparent;
          border: none;
          img {
            width: var(--fs18);
          }
        }
        small {
          color: tomato;
          position: absolute;
        }
        input {
          padding: 0.75vh 0%;
          border: none;
          box-shadow: none;
          outline: none;
          border-radius: 0px;
          border-bottom: 1px solid #c7c7c7;
          font-size: var(--fs18);
          &::placeholder {
            color: #b9b9b9;
          }
          &.error {
            border-color: tomato;
          }
        }
      }
      .keep_me_forget {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4.5vh;
        a {
          text-decoration: none;
          font-size: var(--fs13);
          color: #0496ff;
        }
        .keep_login {
          display: flex;
          align-items: center;

          #Keeplogin {
            width: var(--fs17);
            height: var(--fs17);
            border-color: #333333;
          }
          label {
            font-size: var(--fs12);
            margin: 0;
            margin-left: 4px;
            color: #666666;
          }
        }
      }
      .submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4vh;
        button {
          // width: 48%;
          background-color: transparent;
          padding: 0.8vh 10.5%;
          margin-bottom: 0;
          font-size: var(--fs20);
        }
      }
      .login_with {
        display: flex;
        text-align: center;
        justify-content: center;
        position: relative;
        margin-bottom: 2.8vh;
        p {
          background-color: #fff;
          margin: 0;
          z-index: 10;
          color: #333333;
          padding: 0 2%;
        }
        &::before {
          width: 100%;
          position: absolute;
          content: "";
          top: 48%;
          height: 1px;
          background-color: #e8e8e8;
        }
      }
      .oAuth_container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.2vh;
        gap: 4%;
        img {
          width: var(--fs43);
          cursor: pointer;
        }
      }
      .sign_up {
        text-align: center;
        p {
          margin: 0;
          font-size: var(--fs18);
          color: #666666;
        }
        a {
          color: #0496ff;
          font-family: HomeRegularFamily;
        }
      }
    }
  }
  .res_log_footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 1.75vh 7.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    p {
      margin: 0;
      font-size: var(--fs18);
      color: #666666;
    }

    .footer_right_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 23%;
    }
  }
}

.register_container {
  .register_content_container {
    padding: 4vh 2%;
    .register_header {
      h3 {
        font-size: var(--fs49);
      }
    }
    .register_Form {
      width: 84%;
      margin: auto;
      .form_group {
        margin-bottom: 2.5vh;
        &:nth-child(5) {
          margin-bottom: 3.5vh;
        }
      }
    }
  }
}
.login_container {
  .login_content_container {
    padding: 4.5vh 4%;
    .login_header {
      h3 {
        font-size: var(--fs37);
        span {
          font-family: HomeBoldFamily;
          .pi {
            color: #007bf4;
          }
        }
      }
    }
    .login_Form {
      .form_group {
        // &:nth-child(1) {
        margin-bottom: 3vh;
        // }
      }
    }
  }
}

// ---------------------------------sub nav----------------------
.sub_nav {
  width: 85.5%;
  margin: auto;
  // margin-top: var(--fs74);
  border-bottom: 1px solid #414447;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: HomeRegularFamily;
  // padding-top: 1vh;
  height: var(--fs150);
  // height: 6vh;
  &.ad_content {
    padding-bottom: 1vh;
    padding-top: 3vh;
  }
  .sub_nav_left_Content {
    h2.jhon {
      font-size: var(--fs44);
      margin: 0;
      padding-bottom: 0.5vh;
      color: var(--text-dark_light);
      // font-family: HomeBoldFamily;
      img {
        width: var(--fs43);
        margin-left: var(--fs10);
      }
    }
    h2.Own_case {
      color: var(--text-dark_light);

      font-size: var(--fs36);
    }
    .breadcrums {
      color: #0496ff;
      padding-bottom: 0.5vh;
      display: flex;
      align-items: center;
      gap: var(--fs7);
      font-size: var(--fs17);
      a {
        font-size: var(--fs14);
        text-decoration: none;
        color: #0496ff;
      }
    }
    .local_storage_details {
      display: flex;
      align-items: center;
      gap: var(--fs20);
      .checkbox_val {
        font-size: var(--fs17);
        padding: 0;
      }
      .urlLinks {
        position: relative;
        span {
          color: #333333;
          display: inline-block;
          margin-left: var(--fs10);
        }
        &::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 100%;
          background-color: #ccc;
          left: -3%;
        }
      }
      button.info {
        background-color: transparent;
        border: none;
        padding: 0;
        img {
          width: var(--fs23);
        }
      }
    }
    h3.use_case_detailer {
      font-size: var(--fs37);
      color: var(--Text_dark_to_light);
      img {
        width: var(--fs38);
        margin-right: var(--fs10);
      }
    }
    p {
      font-size: var(--fs15);
      margin: 0;
      color: var(--text-dark_grey);
      padding-bottom: 1vh;
    }
  }
  .sub_nav_right_Content {
    width: 22%;
    p {
      text-align: center;
      margin-bottom: 1vh;
      font-size: var(--fs16);
      color: var(--Text_light_dark_to_white66);
    }
    .btn_container {
      display: flex;
      justify-content: space-between;
      button:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      button {
        color: var(--Text_light_dark_to_white33);
      }
    }
    .dropdown {
      ul {
        width: 100%;
        margin-top: 5% !important;
        &::before {
          content: "";
          width: 10%;
          aspect-ratio: 1/1;
          position: absolute;
          left: 60%;
          top: -5%;
          // border: 1px solid rgba(0, 0, 0, 0.175);
          z-index: -1;
        }
        li {
          padding: 0 5%;
          a {
            &:hover {
              color: #3e3eed;
              background-color: unset;
              img {
                filter: unset;
                &.arrow {
                  transform: rotate(0deg);
                }
              }
            }
            display: flex;
            align-items: center;
            gap: 10%;
            font-size: var(--fs20);
            padding: 1vh 10%;
            img {
              filter: brightness(0) invert(0);
              transition: 0.3s ease-in;
            }
            img.arrow {
              width: var(--fs14);
              margin-left: auto;

              transform: rotate(-35deg);
            }
            img.icon {
              width: var(--fs27);
            }
          }
        }
      }
    }
    button {
      &.show {
        color: #3e3eed;
        img {
          filter: unset;
        }
        &:after {
          width: 99.8%;
          border-right: 2px solid #508efb;
          border-bottom: 2px solid #508efb;
          border-bottom-left-radius: var(--fs10);
        }
        &::before {
          height: 98%;
          border-left: 2px solid #3e3eed;
          border-top: 2px solid #3e3eed;
          border-bottom-left-radius: var(--fs10);
        }
      }
      img {
        width: var(--fs11);
        transform: unset;
      }
      background-color: transparent;
      padding: 1.2vh var(--fs15);
      margin-bottom: 0vh;
      font-size: var(--fs18);
    }
    button.Own_case {
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(10, 131, 250, 1) 0%,
        rgba(124, 190, 254, 1) 100%
      );
      color: #ffffff;
      padding: 0.5vh;
      border-radius: var(--fs25);
      border-color: #037ef9;
      font-size: var(--fs18);
      border-width: 2px;
      border-bottom-color: #037ef9;
      border-right-color: #037ef9;
    }
    button.closed {
      background-color: transparent;
      border: none;
      img {
        width: var(--fs27);
      }
    }
    button {
      &:hover {
        color: #3e3eed;
        background-color: unset;
        img {
          filter: unset;
          &.arrow {
            transform: rotate(0deg);
          }
        }
        &:after {
          width: 99.8%;
          border-right: 2px solid #508efb;
          border-bottom: 2px solid #508efb;
          border-bottom-left-radius: var(--fs10);
        }
        &::before {
          height: 98%;
          border-left: 2px solid #3e3eed;
          border-top: 2px solid #3e3eed;
          border-bottom-left-radius: var(--fs10);
        }
      }

      display: flex;
      align-items: center;
      gap: var(--fs10);
      font-size: var(--fs17);
      padding: 1vh var(--fs15);
      img {
        filter: brightness(0) invert(0);
        transition: 0.3s ease-in;
      }
      img.arrow {
        width: var(--fs12);
        margin-left: auto;

        transform: rotate(-35deg);
      }
      img.icon {
        width: var(--fs24);
      }
    }
  }
}
// -------------------------------------use_case_ex_container-----------------------

.use_case_ex_container {
  font-family: HomeRegularFamily;
  padding-top: 3.5vh;
  // max-height: calc(100vh - calc(var(--fs74) + var(--fs20) + var(--fs150)));
  // overflow-y: auto;
  .use_case_ex_header {
    text-align: center;
    width: 85.5%;
    margin: auto;
    margin-bottom: 2vh;
    h2 {
      font-size: var(--fs37);
      margin: 0;
      font-family: HomeRegularFamily;
      color: var(--text-dark_light);
    }
    p {
      margin: 0;
      font-size: var(--fs14);
      color: var(--text-dark_grey);
    }
  }
  .use_case_ex_card_container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 1%;
    width: 85.5%;
    margin: auto;
    .use_case_ex_card {
      width: 19.2%;
      border-radius: var(--fs25);
      // background-color: var(--card_light_dark);
      margin-bottom: 1.5vh;
      transition: all 0.2s ease-in-out;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;
      background-color: var(--nav_bgdark_to_light);
      transition: all 0.3s ease;

      // &:nth-child(1) {
      //   background-color: #f8f2fe;
      //   &:hover {
      //     background: rgb(151, 0, 255);
      //     background: linear-gradient(
      //       180deg,
      //       rgb(151, 0, 255) 0%,
      //       rgb(97, 0, 241) 100%
      //     );
      //   }
      // }
      // &:nth-child(2) {
      //   &:hover {
      //     background: rgb(54, 224, 203);
      //     background: linear-gradient(
      //       180deg,
      //       rgba(54, 224, 203, 1) 0%,
      //       rgba(27, 200, 214, 1) 100%
      //     );
      //   }

      //   background-color: #e9fafa;
      // }
      // &:nth-child(3) {
      //   background-color: #f2f3ff;
      // }
      &:hover {
        // background: rgb(18, 7, 255);
        // background: linear-gradient(
        //   180deg,
        //   rgba(18, 7, 255, 1) 0%,
        //   rgba(0, 147, 255, 1) 100%
        // );
        box-shadow: rgb(4, 150, 255) 0px 1px 3px 0px,
          rgb(4, 150, 255) 0px 0px 0px 1px;
      }
      // &:nth-child(4) {
      //   background-color: #fff2f7;

      //   &:hover {
      //     background: #f8006d;
      //   }
      // }
      // &:nth-child(5) {
      //   &:hover {
      //     background: rgb(254, 178, 0);
      //     background: linear-gradient(
      //       180deg,
      //       rgba(254, 178, 0, 1) 0%,
      //       rgba(253, 128, 1, 1) 100%
      //     );
      //   }

      //   background-color: #fffaf2;
      // }
      // &:nth-child(6) {
      //   &:hover {
      //     background: rgb(112, 5, 247);
      //     background: linear-gradient(
      //       180deg,
      //       rgba(112, 5, 247, 1) 0%,
      //       rgba(196, 26, 213, 1) 100%
      //     );
      //   }

      //   background-color: #f8f2fe;
      // }

      // &:nth-child(7) {
      //   &:hover {
      //     background: rgb(0, 193, 255);
      //     background: linear-gradient(
      //       180deg,
      //       rgba(0, 193, 255, 1) 0%,
      //       rgba(18, 8, 255, 1) 100%
      //     );
      //   }

      //   background-color: #f2f9ff;
      // }
      // &:nth-child(8) {
      //   &:hover {
      //     background: rgb(94, 89, 113);
      //     background: linear-gradient(
      //       180deg,
      //       rgba(94, 89, 113, 1) 0%,
      //       rgba(86, 105, 143, 1) 100%
      //     );
      //   }

      //   background-color: #f6f6f7;
      // }
      // &:nth-child(9) {
      //   &:hover {
      //     background: rgb(148, 212, 80);
      //     background: linear-gradient(
      //       180deg,
      //       rgba(148, 212, 80, 1) 0%,
      //       rgba(111, 216, 0, 1) 100%
      //     );
      //   }

      //   background-color: #f2faea;
      // }
      // &:nth-child(10) {
      //   &:hover {
      //     background: rgb(255, 239, 0);
      //     background: linear-gradient(
      //       180deg,
      //       rgb(255, 239, 0) 0%,
      //       rgb(255, 190, 0) 100%
      //     );
      //   }

      //   background-color: #fffde5;
      // }
      &:hover {
        // background-color: var(--card_bd_light_blue_to_dark);
        .usecase_Card_holder {
          .use_case_card_body {
            h3,
            p {
              color: #0496ff;
            }
          }
          .use_case_card_header {
            .img_holder {
              img {
                &:nth-child(1) {
                  display: none;
                }
                &:nth-child(2) {
                  display: block;
                }
              }
            }
          }
        }
      }
      .usecase_Card_holder {
        padding: 2vh 7%;
        text-decoration: none;
        display: block;

        .use_case_card_header {
          margin-bottom: 1.6vh;
          .img_holder {
            width: 18%;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: #eaeaea;
            border-radius: 50%;
            img {
              width: 100%;
              &:nth-child(1) {
                display: block;
              }
              &:nth-child(2) {
                display: none;
              }
            }
          }
        }
        .use_case_card_body {
          h3 {
            font-size: var(--fs21);
            color: var(--Text_light_dark_to_white33);
            font-family: HomeRegularFamily;
            margin: 0;
            padding-bottom: 0.5vh;
          }
          p {
            font-size: var(--fs14);
            color: var(--Text_light_dark_to_white66);
            margin: 0;
          }
        }
      }
    }
  }
}
.usecase_tab_Container {
  .usecase_tab_btn_container {
    width: 85.5%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4vh;

    .nav_tabs {
      display: flex;
      border: none;
      border-radius: var(--fs13);
      background-color: var(--nav_bgdark_to_light);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      gap: var(--fs30);
      button {
        border: none;
        padding: 0.6vh var(--fs20);
        font-size: var(--fs22);
        border-radius: var(--fs12);
        &.active {
          background-color: #007bf4;
          color: #fff;
        }
      }
      padding: 1vh 2%;
    }
  }
}
.enterprises_usecase_card_container {
  width: 85.5%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 2%;
  max-height: calc(100vh - calc(var(--fs74) + var(--fs20) + var(--fs150)));
  // row-gap: 2%;

  .enterprises_usecase_card {
    width: 15%;
    // border: 2px solid #ccc;
    padding: 2.5vh 0.5%;
    border-radius: var(--fs12);
    cursor: pointer;
    margin-bottom: 3vh;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
    background-color: var(--nav_bgdark_to_light);

    &:hover,
    &.active {
      box-shadow: rgb(4, 150, 255) 0px 1px 3px 0px,
        rgb(4, 150, 255) 0px 0px 0px 1px;
      h4 {
        color: #0496ff;
      }
      // border-color: #007cff;
      .img_holder {
        :nth-child(2) {
          display: none;
        }
        :nth-child(1) {
          display: block;
        }
      }
    }
    .img_holder {
      width: 40%;
      margin: auto;
      :nth-child(1) {
        display: none;
      }
      :nth-child(2) {
        display: block;
      }

      img {
        width: 100%;
      }
      svg {
        width: 100%;
        fill: #ccc;
        .color_control {
          fill: #ccc;
        }
        .w_fill {
          width: 100%;
        }
      }
    }
    h4 {
      text-align: center;
      font-size: var(--fs20);
      margin: 0;
      padding: 2vh 0;
    }
  }
}
// -------------------------------------text to image /pdf container-----------------------------------
.text_to_image_container {
  width: 85.5%;
  margin: auto;
  .input_container {
    // border-bottom: 1px solid #b9b9b9;
    .form_group {
      margin: 3vh 0;
      input {
        // border: none;
        border-color: #b9b9b9;
        font-size: var(--fs18);
        box-shadow: none;
        outline: none;
        font-family: HomeRegularFamily;
        padding: 1.75vh 2%;
        border-radius: var(--fs15);
        transition: all 0.3s ease;

        // background-color: var(--text_area_to_dark);
        background-color: var(--bg_body);
        color: var(--Text_dark_to_light);
        // &::placeholder{
        //   color: #616161;
        // }
        &::placeholder {
          color: #797979;
        }
      }
    }
  }
  .text_to_image_selectionArea {
    margin-top: 5vh;
    .subBtn {
      text-align: center;

      p {
        background-color: #0496ff;
        display: inline-block;
        font-size: var(--fs25);
        font-family: HomeRegularFamily;
        color: white;
        // width: 40%;
        padding: 1.15vh 6%;
        border-radius: var(--fs10);
        margin-bottom: 1.4vh;
        background: linear-gradient(
          77deg,
          rgb(17, 10, 255) 0%,
          rgb(0, 144, 255) 100%
        );
        color: #fff;
      }
    }

    .text_to_image_tabHeadArea {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3%;

      .text_to_image_tabHead {
        font-size: var(--fs20);
        font-family: HomeRegularFamily;
        color: var(--Text_dark_to_light);

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4%;

        input {
          width: var(--fs27);
          height: var(--fs27);
          margin-right: var(--fs12);
        }
      }

      .active {
        color: black;
        color: var(--Text_dark_to_light);
      }
    }
  }
  .generate_btn_container {
    display: flex;
    justify-content: end;
    button {
      background-color: transparent;
      padding: 1.2vh 3.5%;
      margin-bottom: 0vh;
      font-size: var(--fs20);
      color: var(--Text_dark_to_light);
    }
  }
  .image_preview_container {
    background-color: var(--preview_to_dark);
    margin-top: 2.5vh;
    padding: 2.5vh 3%;
    border-radius: var(--fs20);
    .image_preview_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3vh;
      h3 {
        text-transform: uppercase;
        font-size: var(--fs23);
        margin: 0;
        color: var(--Text_light_dark_to_white66);

        font-family: HomeBoldFamily;
      }
      button {
        background-color: transparent;
        border: none;
        img {
          width: var(--fs27);
        }
      }
    }
    .image_preview_body {
      h5 {
        margin: 0;
        font-size: var(--fs18);
        color: var(--Text_light_dark_to_white66);
      }
      h2 {
        font-size: var(--fs30);
        font-family: HomeBoldFamily;
        margin-bottom: 2vh;
        color: var(--Text_light_dark_to_white66);

      }
      .choose_font_color_picker {
        display: flex;
        gap: 1%;
        h4 {
          font-size: var(--fs17);
          margin: 0;
          padding-right: var(--fs12);
        color: var(--Text_light_dark_to_white66);

        }
        .choose_font_Container {
          display: flex;
          align-items: center;
          background-color: #ffff;
          border: 1px solid #ededed;
          border-radius: var(--fs10);
          padding: 0.75vh 1%;

          select {
            border: none;
            font-size: var(--fs15);
            color: #037ef9;
            outline: none;
          }
        }
        .color_picker_container {
          display: flex;
          align-items: center;
          background-color: #ffff;
          border: 1px solid #ededed;
          border-radius: var(--fs10);
          padding: 0.75vh 1%;
          position: relative;
          input {
            width: 0;
            visibility: hidden;
            position: absolute;
            height: 0;
          }
          img {
            width: var(--fs28);
            margin-right: var(--fs10);
          }
        }
      }
      .preview__pdf_holder {
        display: flex;
        align-items: center;
        margin-top: 3vh;
        gap: 1.2%;
        .img_holder {
          width: 11.35%;
          position: relative;
          img {
            width: 100%;
          }
          button {
            padding: 0;
            position: absolute;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          button.download {
            background-color: #fff;
            border-radius: 50%;
            width: var(--fs18);
            height: var(--fs18);
            bottom: 5%;
            right: 5%;
            img {
              width: var(--fs10);
            }
          }
          button.play {
            top: 43%;
            left: 40%;
            background-color: transparent;
            img {
              width: var(--fs25);
            }
          }
          h3 {
            position: absolute;
            top: 40%;
            margin: 0;
            width: 100%;
            font-size: var(--fs14);
            color: #fff;
            text-align: center;
            font-style: italic;
          }
        }
      }
      .preview_images_holder {
        display: flex;
        align-items: center;
        margin-top: 3vh;
        gap: 1.2%;
        .img_holder {
          width: 11.35%;
          position: relative;
          img {
            width: 100%;
          }
          button {
            padding: 0;
            position: absolute;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          button.download {
            background-color: #fff;
            border-radius: 50%;
            width: var(--fs21);
            height: var(--fs21);
            bottom: 5%;
            right: 5%;
            img {
              width: var(--fs15);
            }
          }
          button.play {
            top: 43%;
            left: 40%;
            background-color: transparent;
            img {
              width: var(--fs25);
            }
          }
          h3 {
            position: absolute;
            top: 40%;
            margin: 0;
            width: 100%;
            font-size: var(--fs14);
            color: #fff;
            text-align: center;
            font-style: italic;
          }
        }
      }
    }
  }
}

// ------------------------------------------qa contet------------------------
.qa_docs_container {
  padding: 0 7%;

  .qa_docs_selectionArea {
    .subBtn {
      text-align: center;

      p {
        background-color: #0496ff;
        display: inline-block;
        font-size: var(--fs20);
        font-family: HomeRegularFamily;
        color: white;
        padding: var(--fs10) var(--fs45);
        border-radius: 8px;
        margin-bottom: var(--fs15);

        color: #fff;
      }
    }
    .input_container {
      .form_group {
        input {
          border-color: #b9b9b9;
          font-size: var(--fs18);
          box-shadow: none;
          outline: none;
          font-family: HomeRegularFamily;
          padding: 1.75vh 2%;
          border-radius: var(--fs15);
          margin-bottom: 5vh;
          transition: all 0.3s ease;
          background-color: var(--bg_body);
          color: var(--Text_dark_to_light);

          &::placeholder {
            color: #797979;
          }
        }
      }
    }
    .qa_docs_tabHeadArea {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--fs35);

      .qa_docs_tabHead {
        font-size: var(--fs17);
        font-family: HomeRegularFamily;
        // color: #6d6b6b;
        color: var(--Text_dark_to_light);

        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--fs10);
        cursor: pointer;

        input {
          width: var(--fs17);
          height: var(--fs17);
        }
      }

      .active {
        color: var(--Text_dark_to_light);
      }
    }

    .qa_docs_tabbodyArea {
      box-sizing: border-box;
      padding-top: 5vh;
      .subHead {
        font-size: var(--fs25);
        font-family: HomeRegularFamily;
        font-style: italic;
        color: #6d6b6b;
      }

      .qa_docs_tabbody {
        // display: none;

        //  ---------------- cards area start-------------
        .qa_docs_cards {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          gap: 1.25%;

          .qa_docs_card {
            width: 32.5%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: var(--fs15);
            background-color: var(--nav_bgdark_to_light);
            // border: 2px solid #bebdbd;
            padding: var(--fs20);
            border-radius: 8px;
            box-sizing: border-box;
            box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
              rgba(17, 17, 26, 0.1) 0px 0px 8px;
            color: var(--Text_light_dark_to_white66);
            // &:hover {
            //   background-color: #eff6ff;
            //   border: 2px solid #007cff;

            //   .cardImg {
            //     background-color: #0496ff;
            //   }
            // }
            &:hover {
              box-shadow: rgb(4, 150, 255) 0px 1px 3px 0px,
                rgb(4, 150, 255) 0px 0px 0px 1px;

              .cardImg {
                img {
                  &:nth-child(1) {
                    display: none;
                  }
                  &:nth-child(2) {
                    display: block;
                  }
                }
              }
            }
            &:hover,
            &.active {
              color: #0496ff;
              box-shadow: rgb(4, 150, 255) 0px 1px 3px 0px,
                rgb(4, 150, 255) 0px 0px 0px 1px;
            }
            // &:nth-child(1) {
            //   background-color: #f8f2fe;
            //   &:hover,
            //   &.active {
            //     background: linear-gradient(
            //       180deg,
            //       rgba(151, 0, 255, 1) 0%,
            //       rgba(97, 0, 241, 1) 100%
            //     );
            //   }
            // }
            // &:nth-child(2) {
            //   background: #e9fbfa;
            //   &:hover,
            //   &.active {
            //     background: linear-gradient(
            //       180deg,
            //       rgba(54, 224, 203, 1) 0%,
            //       rgba(27, 200, 214, 1) 100%
            //     );
            //   }
            // }
            // &:nth-child(3) {
            //   background-color: #fff2f7;

            //   &:hover,
            //   &.active {
            //     background-color: #f8006d;
            //   }
            // }
            // &:nth-child(4) {
            //   background-color: #f2faea;
            //   &:hover,
            //   &.active {
            //     background: rgb(148, 212, 80);
            //     background: linear-gradient(
            //       180deg,
            //       rgb(148, 212, 80) 0%,
            //       rgb(111, 216, 0) 100%
            //     );
            //   }
            // }
            // &:nth-child(5) {
            //   background-color: #fffaf2;
            //   &:hover,
            //   &.active {
            //     background: rgb(254, 178, 0);
            //     background: linear-gradient(
            //       180deg,
            //       rgb(254, 178, 0) 0%,
            //       rgb(253, 128, 1) 100%
            //     );
            //   }
            // }
            .cardImg {
              display: flex;
              width: 10%;
              align-items: center;
              aspect-ratio: 1/1;
              box-sizing: border-box;

              img {
                width: 100%;
              }
            }
            .card_body {
              width: 83%;
            }

            .qa_docs_card_name {
              font-size: var(--fs18);
              margin: 0 0 var(--fs10);
              font-family: HomeBoldFamily;
            }

            .qa_docs_card_desc {
              font-family: HomeRegularFamily;
              font-size: var(--fs15);
              margin: 0;
            }
          }

          // .active {
          //   background-color: #eff6ff;
          //   border: 2px solid #007cff;

          //   .cardImg {
          //     background-color: #0496ff;
          //   }
          // }
        }

        .qa_docs_generate {
          display: flex;
          justify-content: end;

          button {
            font-family: HomeRegularFamily;
            background-color: transparent;
            padding: 1.2vh 3.5%;
            margin-bottom: 4vh;
            font-size: var(--fs20);
            color: var(--Text_dark_to_light);
          }
        }

        //  ---------------- cards area end -------------
        .qa_fileDrop_area {
          .file-upload {
            text-align: center;
            cursor: pointer;
          }

          .file-uploadArea {
            display: flex;
            align-items: center;
            height: 39vh;
            gap: var(--fs20);
            box-sizing: border-box;

            .custom-label {
              border-radius: 10px;
              border: 2px dashed #0496ff;
              width: 30%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              h4 {
                margin: var(--fs10);
                color: #0496ff;
                font-size: var(--fs25);
                font-family: HomeBoldFamily;
              }

              .uploadImg {
                width: 25%;
                display: flex;

                img {
                  width: 100%;
                }
              }
            }

            .file-list {
              list-style: none;
              height: 100%;
              width: 60%;
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              box-sizing: border-box;
              margin: 0;
              overflow-y: scroll;
              scrollbar-width: auto;
              scrollbar-color: #b3b2b3 #ffffff;
              transition: all 0.8s ease-in-out;

              &::-webkit-scrollbar {
                width: 3px !important;
              }

              &::-webkit-scrollbar-track {
                background: transparent !important;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #cccccc !important;
                border-radius: 11px !important;
                border: none !important;
              }

              .file-item {
                padding: 5px;
                display: flex;
                align-items: flex-start;
                gap: var(--fs20);
                transition: all 0.8s ease-in-out;

                .file-itemimg {
                  width: var(--fs40);
                  display: flex;

                  img {
                    width: 100%;
                  }
                }

                .file-name {
                  h6 {
                    font-size: var(--fs18);
                    font-family: HomeBoldFamily;
                  }

                  p {
                    font-size: var(--fs16);
                    color: #7c7b7b;
                  }
                }

                .file-delete {
                  width: var(--fs16);
                  cursor: pointer;

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }

          .highlight {
            border-color: #000;
            background-color: rgba(0, 0, 0, 0.1);
          }

          .file-item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
          }

          .file-name {
            flex-grow: 1;
            margin-right: 10px;
          }

          .file-delete {
            color: red;
            cursor: pointer;
          }
        }
      }

      .active {
        display: block;
      }
    }

    #pasteArea {
      border: 2px dashed #007bf4;
      border-radius: var(--fs10);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--fs30);
      width: 100%;
      gap: var(--fs20);

      .pasteAreaImg {
        width: 5%;

        img {
          width: 100%;
        }
      }

      .pasteAreaText {
        h5 {
          color: #007bf4;
          font-size: var(--fs20);
          font-family: HomeBoldFamily;
          text-decoration: underline;
        }

        p {
          color: #7c7b7b;
          font-size: var(--fs16);
        }
      }
    }

    .urlHeading {
      font-size: var(--fs25);
      font-family: HomeBoldFamily;
      margin: var(--fs10) 0;
    }

    #linkList {
      margin: 0;
      height: 25vh;
      overflow-y: scroll;

      li {
        list-style: none !important;
        display: flex;
        align-items: center;
        gap: var(--fs20);
        margin-bottom: var(--fs10);

        .ulrImg {
          width: var(--fs30);

          img {
            width: 100%;
          }
        }

        .urlText {
          a {
            color: black;
            font-size: var(--fs15);
            font-family: HomeBoldFamily;
          }
        }

        .urlDelete {
          width: var(--fs10);
          cursor: pointer;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .qa_docs_chatArea {
    &.summarization {
      height: 66vh;
    }
    height: calc(
      100vh - calc(var(--fs74) + var(--fs20) + var(--fs150) + 3.5vh)
    );
    position: relative;

    .topHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--fs10);

      p {
        font-size: var(--fs16);
        color: #7c7b7b;
        margin: 0;

        #headContent {
          font-size: var(--fs18);
          font-family: HomeBoldFamily;
          margin: 0 5px;
          color: var(--Text_dark_to_light);
        }
      }

      a {
        text-decoration: none;
        color: #0496ff;
        display: flex;
        align-items: center;

        p {
          font-size: var(--fs17);
          font-family: HomeBoldFamily;
          margin: 0 5px;
          color: #0496ff;
        }

        img {
          width: var(--fs23);
          display: flex;
        }
      }
    }
    .formater_contaner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      height: var(--fs58);
    }
    .summarizeSelectArea {
      h2 {
        font-size: var(--fs30);
        font-family: HomeBoldFamily;
        margin-bottom: var(--fs15);
      }

      .radioInputs {
        display: flex;
        gap: var(--fs30);

        .summurizeRadioBtn {
          font-size: var(--fs17);
          font-family: HomeRegularFamily;
          color: #6d6b6b;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: var(--fs10);

          input {
            width: var(--fs17);
            height: var(--fs17);
          }
        }
      }
    }

    .formatSelector {
      display: flex;
      margin-top: var(--fs10);
      align-self: flex-end;
      width: 40%;
      justify-content: end;
      .formatLibraryArea {
        display: flex;
        border: 1px solid #9b9a9a;
        align-items: center;
        padding: 0 var(--fs10);
        padding: 1vh 4%;
        border-radius: var(--fs12);

        p {
          font-size: var(--fs16);
          white-space: nowrap;
          // font-family: HomeBoldFamily;
          margin-right: var(--fs15);
          margin-bottom: 0;
        }

        ul {
          margin: 0;
          white-space: nowrap;

          .bulletList {
            list-style: none;
            display: inline-block;
            width: var(--fs25);
            padding: 2px 5px;
            cursor: pointer;
            aspect-ratio: 1/1;
            &:hover {
              border-bottom: 2px solid #007bf4;
            }

            img {
              width: 100%;
            }
          }

          .active {
            border-bottom: 2px solid #007bf4;
          }
        }
      }

      .formatColorArea {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        gap: var(--fs20);
        margin-left: var(--fs40);
        border: 1px solid #9b9a9a;
        padding: 0 var(--fs20);
        font-size: var(--fs16);
        p {
          margin: 0;
        }
        .colorPicker {
          padding: 2px;
          width: var(--fs40);

          img {
            width: 100%;
          }
        }
      }
    }

    #chatScroll {
      height: 38vh;
      &.QA {
        height: calc(
          100vh -
            (var(--fs74) + var(--fs20) + var(--fs150) + 3.5vh + var(--fs150))
        );
      }
      &.Summarization {
        height: calc(
          100vh -
            (var(--fs74) + var(--fs20) + var(--fs150) + 3.5vh + var(--fs250))
        );
      }
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 11px;
        border: none;
      }

      .chatDialogBox {
        box-sizing: border-box;
        // background-color: #eff6ff;
        border-radius: 10px;
        padding: var(--fs15) var(--fs20);
        position: relative;
        margin-bottom: var(--fs35);
        background-color: var(--text_answer_color);
        color: var(--Text_dark_to_light);

        .chatQuestion {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          a {
            display: flex;
            color: #0496ff;
            text-decoration: none;
            border-bottom: 2px solid;
            font-size: var(--fs17);
            font-family: HomeBoldFamily;
          }

          .chatQuestion_btns {
            display: flex;
            gap: var(--fs20);

            .chatQuestion_btn {
              width: var(--fs30);
              display: flex;
              cursor: pointer;

              img {
                width: 100%;
              }
            }
          }
        }

        .chatAnswer {
          box-sizing: border-box;
          padding: var(--fs10) 0 var(--fs30) 0;
          font-size: var(--fs16);

          h2 {
            font-size: var(--fs20);
            font-family: HomeBoldFamily;
          }

          ul {
            li {
              list-style: none;
              gap: var(--fs10);
              display: flex;
              padding-bottom: 5px;
              font-size: var(--fs14);

              .bullet {
                width: var(--fs10);
                width: var(--fs15);
                height: var(--fs15);
                display: flex;
                margin-top: 3px;

                img {
                  width: 100%;
                }
              }

              .bulletText {
                display: block;
                width: 90%;
              }
            }
          }
        }

        .chatReaction_container {
          display: flex;
          align-items: center;
          justify-content: end;
          box-sizing: border-box;
          position: absolute;
          right: var(--fs35);
          overflow: hidden;
          width: 200px;
          border-radius: var(--fs20);
          top: 83%;

          img {
            width: 100%;
          }

          .reaction-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            position: relative;
          }

          .emoji-container {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            transition: transform 0.3s ease-in-out;
            position: absolute;
            right: 100%;
            transform: translate(155%);
            z-index: 1;
            border-radius: var(--fs20) 0 0 var(--fs20);
            overflow: hidden;
            padding: 0 var(--fs30);
            background-color: var(--bg_body);
            border: 1px solid #9e9c9c;
            border-right: none;

            .emoji {
              width: var(--fs46);

              &:hover {
                transform: scale(1.1);
                filter: drop-shadow(2px 4px 6px rgb(102, 101, 101));
              }
            }
          }

          .emoji {
            width: var(--fs50);
            display: flex;
            padding: var(--fs10);
          }

          .emoji.defaultEmoji {
            z-index: 2;
            border-radius: 100%;
            background-color: var(--bg_body);

            border: 1px solid #9e9c9c;
          }

          .emoji-container.open {
            transform: translateX(15%);
          }
        }

        .summary_chatReaction_container {
          top: 88%;
        }
      }
    }

    .chatFooter {
      position: absolute;
      bottom: 2%;
      width: 100%;
      .footerChips {
        display: flex;
        flex-wrap: wrap;
        gap: var(--fs15);
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        margin-right: 15%;

        li {
          list-style: none;
          padding: 3px var(--fs15);
          border-radius: 5px;
          cursor: pointer;
          font-size: var(--fs13);
          color: #007cff;
          border: 1px solid #007cff;
          display: inline-block;

          &:hover {
            background-color: #afc8e2;
          }
        }
      }
    }
    .typingArea {
      display: flex;
      gap: var(--fs30);
      align-items: center;
      justify-content: space-between;
      margin-top: var(--fs20);

      .inputBox {
        display: flex;
        width: 100%;

        input {
          font-size: var(--fs16);
          padding: 1.5vh var(--fs35);
          // background-color: #e6e2e2;
          background-color: var(--text_area_to_dark);
          transition: all 0.3s ease;

          width: 100%;
          border-radius: 50px;
          border: none;
          color: var(--Text_dark_to_light);
          &:focus {
            border: none;
            outline: none;
          }
        }
      }
      button.sendBtn {
        background-color: transparent;
        border: none;
        padding: 0;
      }
      .sendBtn {
        width: var(--fs50);
        cursor: pointer;
        display: flex;
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
.hide {
  display: none;
}

.themebtn {
  background: linear-gradient(
    180deg,
    rgba(10, 131, 250, 1) 0%,
    rgba(124, 190, 254, 1) 100%
  );
  color: #ffffff;
  padding: 0.5vh;
  border-radius: var(--fs25);
  border-color: #037ef9;
  font-size: var(--fs18);
  border-width: 3px;
  border-bottom-color: #037ef9;
  border-right-color: #037ef9;
  cursor: pointer;
}

.slideOver {
  background-color: blueviolet;
  z-index: 2000;
  // display: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 30%;
  height: 100vh;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform: translate(100%);

  .qa_docs_generate {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding: 1vh 10%;
      font-size: var(--fs20);
      color: var(--Text_dark_to_light);
    }
  }

  .slideTrigger {
    transform: rotate(-90deg) translateY(-50%);
    text-align: center;
    display: flex;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    right: 70%;
    cursor: pointer;
    // width: 100%;

    p {
      background-color: #0496ff;
      display: inline-block;
      margin: 0;
      font-size: var(--fs20);
      font-family: HomeRegularFamily;
      color: white;
      padding: var(--fs10) var(--fs45);
      border-radius: 8px;
    }
  }

  .slideTriggerHide {
    opacity: 0;
  }

  .sliderContent {
    background-color: var(--nav_bgdark_to_light);

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
    height: 100%;
    padding: 5%;
    padding-top: 7.5vh;
    font-family: HomeRegularFamily;
    .slideClose {
      position: absolute;
      top: 5vh;
      right: 3%;
      width: var(--fs25);
      display: flex;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    .qa_fileDrop_area {
      .file-upload {
        text-align: center;
        cursor: pointer;
      }

      .file-uploadArea {
        display: flex;
        align-items: center;
        height: 80vh;
        flex-direction: column;
        gap: var(--fs20);
        box-sizing: border-box;

        .subBtn {
          p {
            background-color: #0496ff;
            display: inline-block;
            margin: 0;
            font-size: var(--fs20);
            font-family: HomeRegularFamily;
            color: white;
            padding: var(--fs10) var(--fs45);
            border-radius: 8px;
          }
        }

        .custom-label {
          border-radius: 10px;
          border: 2px dashed #0496ff;
          width: 80%;
          height: 40%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h4 {
            margin: var(--fs10);
            color: #007bf4;
            font-family: HomeBoldFamily;
            font-size: var(--fs25);
          }

          .uploadImg {
            width: 25%;
            display: flex;

            img {
              width: 100%;
            }
          }
        }

        .file-list {
          list-style: none;
          height: 40%;
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          box-sizing: border-box;
          margin: 0;
          overflow-y: scroll;
          scrollbar-width: auto;
          scrollbar-color: #b3b2b3 #ffffff;
          transition: all 0.8s ease-in-out;

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #cccccc;
            border-radius: 11px;
            border: none;
          }

          .file-item {
            padding: 5px;
            display: flex;
            align-items: flex-start;
            gap: var(--fs20);
            transition: all 0.8s ease-in-out;

            .file-itemimg {
              width: var(--fs40);
              display: flex;

              img {
                width: 100%;
              }
            }

            .file-name {
              h6 {
                font-size: var(--fs18);
                font-family: HomeBoldFamily;
              }

              p {
                font-size: var(--fs16);
                color: #7c7b7b;
              }
            }

            .file-delete {
              width: var(--fs16);
              cursor: pointer;

              img {
                width: 100%;
              }
            }
          }
        }
      }

      .highlight {
        border-color: #000;
        background-color: rgba(0, 0, 0, 0.1);
      }

      .file-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }

      .file-name {
        flex-grow: 1;
        margin-right: 10px;
      }

      .file-delete {
        color: red;
        cursor: pointer;
      }
    }
  }
}
.slideActive {
  transform: translate(0%) !important;
}
// ---------------------------------suse case FOoter----------------------
footer.usecase {
  font-family: HomeRegularFamily;
  background-color: var(--footer_bg_light_dark);
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 1.75vh 7.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  p {
    margin: 0;
    font-size: var(--fs18);
    color: #666666;
  }

  .footer_right_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 23%;
  }
}

body.overflow {
  overflow-x: hidden;
  width: 100%;
}

.enterpises_container {
  width: 85.5%;
  margin: auto;
  font-family: HomeRegularFamily;
  padding-top: 10vh;
  .enterpises_header {
    text-align: center;
    margin: 3vh 0;
    h2 {
      margin-bottom: 0;
      padding-bottom: 0.5vh;
      font-size: var(--fs40);
      font-family: HomeBoldFamily;
      color: #333333;
    }
    p {
      margin: 0;
      font-size: var(--fs16);
    }
  }
  .enterpises_card_container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2%;
    .enterpises_card {
      width: 29%;
      display: flex;
      align-items: center;
      border-radius: var(--fs20);
      background-color: var(--card_light_dark);
      border: 2px solid transparent;
      margin-bottom: 1.5vh;
      transition: all 0.4s ease-in-out;
      &:hover {
        border-color: #037ef9;
        background-color: var(--card_bd_light_blue_to_dark);
        .usecase_Card_holder {
          .img_holder {
            background-color: #037ef9;
            img {
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                display: block;
              }
            }
          }
        }
      }
      .usecase_Card_holder {
        padding: 2vh 7%;
        text-decoration: none;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .img_holder {
          width: 20%;
          aspect-ratio: 1/1;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #eaeaea;
          border-radius: 50%;
          img {
            width: 100%;
            &:nth-child(1) {
              display: block;
            }
            &:nth-child(2) {
              display: none;
            }
          }
        }

        .use_case_card_body {
          width: 75%;
          h3 {
            font-size: var(--fs30);
            color: var(--Text_light_dark_to_white33);
            margin: 0;
            padding-bottom: 0.5vh;
          }
          p {
            font-size: var(--fs14);
            color: var(--Text_light_dark_to_white66);
            margin: 0;
          }
        }
      }
    }
  }
}
.enterprises_empty_container {
  width: 85.5%;
  font-family: HomeRegularFamily;
  display: flex;
  align-items: center;
  height: 60vh;
  margin: auto;
  .enterprises_empty_holder {
    width: 100%;
    text-decoration: none;
    .img_holder {
      width: 11.5%;
      margin: auto;
      img {
        width: 100%;
      }
    }
    h2 {
      text-align: center;
      font-size: var(--fs50);
      color: #007bf4;
    }
  }
}
// ------- setting screens style --------
.settingScreen_nav {
  border: none;
}

.settingsContent {
  // margin: 0 7%;
  font-family: HomeRegularFamily;
  width: 85.5%;
  margin: auto;
  box-sizing: border-box;
  margin-top: var(--fs20);
  position: relative;

  p {
    margin: 0;
  }

  .invite_tableArea {
    width: 70%;

    .invite_header {
      .invite_tableArea_head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: var(--fs25);
        }

        button {
          // background-color: red;
          display: inline-flex;
          align-items: center;
          gap: var(--fs10);
          cursor: pointer;
          background: none;
          border: none;

          span {
            font-size: var(--fs16);
            color: var(--Text_light_dark_to_white66);

            div {
              width: var(--fs16);
              display: flex;

              img {
                width: 100%;
                object-fit: scale-down;
              }
            }
          }
        }
      }
    }

    .invite_table {
      table {
        width: 100%;

        thead {
          background-color: #dddcdc;

          tr {
            th {
              &:nth-child(1){
                // padding: var(--fs10) 0;
                vertical-align: middle;
                text-align: center;
              }
              padding: var(--fs10);
              font-size: var(--fs17);

              input {
                width: var(--fs16);
                height: var(--fs16);
                cursor: pointer;
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid #dddcdc;

            td {
              &:nth-child(1){
                padding: var(--fs10) 0;
                vertical-align: middle;
                text-align: center;
              }
              padding: var(--fs10);
              font-size: var(--fs17);
              
              color: var(--Text_light_dark_to_white66);
              input[type="checkbox"] {
                width: var(--fs16);
                height: var(--fs16);
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .API_table {
      height: 22vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 11px;
        border: none;
      }

      table {
        thead {
          position: sticky;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .qa_docs_selectionArea {
    .subBtn {
      text-align: center;

      p {
        background-color: #0496ff;
        display: inline-block;
        font-size: var(--fs20);
        font-family: HomeRegularFamily;
        color: white;
        padding: var(--fs10) var(--fs45);
        border-radius: 8px;
        margin-bottom: var(--fs15);
      }
    }

    .qa_docs_tabHeadArea {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--fs35);
      color: var(--Text_dark_to_light);

      .qa_docs_tabHead {
        font-size: var(--fs17);
        font-family: HomeRegularFamily;
        color: #6d6b6b;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--fs10);
        cursor: pointer;

        input {
          width: var(--fs17);
          height: var(--fs17);
        }
      }

      // .active {
      //   color: black;
      //   color: var(--Text_dark_to_light);
      // }
    }

    .qa_docs_tabbodyArea {
      box-sizing: border-box;

      .subHead {
        font-size: var(--fs25);
        font-family: HomeRegularFamily;
        font-style: italic;
        color: #6d6b6b;
        margin: var(--fs10) 0;
      }

      .qa_docs_tabbody {
        display: none;
      }

      .active {
        display: block;
      }
    }
  }

  .keyInputArea {
    background-color: var(--preview_to_dark);

    padding: var(--fs40);
    border-radius: 10px;
    margin-bottom: 3vh;

    .inputContainers {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .inputBox {
        width: 31%;

        input {
          width: 100%;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #a8a7a7;
          padding: var(--fs10) 0;
          font-size: var(--fs18);
          outline: none;
        }
      }

      button {
        font-family: HomeRegularFamily;
        background-color: transparent;
        padding: 1vh 6.5%;
        margin-bottom: 4vh;
        font-size: var(--fs20);
      }
    }

    .verifyArea {
      margin: var(--fs10) 0;

      .checkBox {
        display: flex;
        align-items: center;
        gap: var(--fs10);

        input {
          width: var(--fs16);
          height: var(--fs16);
        }

        label {
          font-size: var(--fs16);
          color: #4d4c4c;
          cursor: pointer;

          a {
            font-size: var(--fs16);
            color: #4d4c4c;
          }
        }
      }
    }

    .generateKey {
      display: flex;
      justify-content: end;

      button {
        font-size: var(--fs20);
        font-family: HomeRegularFamily;
        color: #000;
        padding: 1vh 2%;
      }
    }
  }

  .billingArea {
    width: 100%;

    // .billingImg {
    //   width: 60%;
    //   margin: 0 auto;
    //   display: flex;
    //   height: 60vh;

    //   img {
    //     width: 100%;
    //   }
    // }

    .subscription_tab_container {
      width: 85.5%;
      margin: auto;
      .tab_btn_container {
        position: relative;
        width: 28%;
        margin: auto;
        border-bottom: 2px solid #dee2e6;
        .slider_Anim {
          transition: color 0.4s ease-in-out;
          position: absolute;
          bottom: 0;
          width: 50%;
          height: 2px;
          background-color: rgb(13, 110, 253);
          transition: left 0.25s;
          &.left {
            left: 0;
          }
          &.right {
            left: 50%;
          }
        }
        li {
          width: 50%;

          button {
            width: 100%;
            background-color: transparent;
            border: none;
            font-size: var(--fs24);
            padding: 7% 0;
            color: #666666;
            transition: color 0.15s;
            // font-family: HomeBoldFamily;
            &.active {
              color: rgb(13, 110, 253);
            }
          }
        }
      }
      .tab_content_container {
        .subscription_card_container {
          display: flex;
          align-items: center;
          gap: 4%;
          justify-content: center;
          padding-top: 4%;
          .subscription_card {
            width: 24%;
            border: 1px solid rgb(13, 110, 253);
            border-radius: var(--fs40);
            padding: 2%;
            &:hover {
              p {
                img {
                  filter: brightness(300);
                }
              }
            }
            .card_content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: rgb(13, 110, 253);
              h2 {
                margin-top: 28%;
                margin-bottom: 0;
                padding-bottom: 5%;
                font-size: var(--fs50);
                span {
                  font-size: var(--fs30);
                }
              }
              h3 {
                margin: 0;
                margin-bottom: 4%;
                font-size: var(--fs25);
              }
              p {
                font-size: var(--fs17);
                img {
                  margin-right: 6px;
                }
              }
              button {
                margin-top: 10%;
                background-color: rgb(13, 110, 253);
                width: 50%;
                border-radius: var(--fs10);
                padding: 2%;
                font-size: var(--fs18);
                text-transform: uppercase;
                border: 1px solid rgb(13, 110, 253);
                color: #fff;
                margin-bottom: 15%;
              }
            }
            .tag {
              position: relative;
              width: 70%;
              padding: 5%;
              left: -16%;
              .tag_content {
                box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25),
                  0px 1px 3px -1px rgba(0, 0, 0, 0.3);
                top: 80%;
                width: 100%;
                position: absolute;
                text-transform: uppercase;
                background-color: rgb(13, 110, 253);
                border-radius: 3px;
                h5 {
                  font-size: var(--fs30);
                  color: #fff;
                  margin: 0;
                  text-align: center;
                  padding: 3% 0;
                }
                &::before {
                  content: "";
                  position: absolute;
                  width: 30%;
                  height: 110%;
                  top: -5%;
                  background-color: var(--bg_body);
                  transform: skewX(20deg);
                  right: -20%;
                }
              }
            }
            &.premium {
              // background-color: rgb(13, 110, 253);
              .card_content {
                color: #fff;
                button {
                  background-color: #fff;
                  // color: rgb(13, 110, 253);
                }
              }
              .tag {
                .tag_content {
                  background-color: #fff;
                  border-radius: 3px;
                  &::before {
                    // background-color: rgb(13, 110, 253);
                  }
                  h5 {
                    // color: rgb(13, 110, 253);
                    font-size: var(--fs30);
                  }
                }
              }
            }

            &:nth-child(1) {
              border: 1px solid rgb(112, 5, 247);

              &:hover {
                background: linear-gradient(
                  180deg,
                  rgb(112, 5, 247) 0%,
                  rgb(196, 26, 213) 100%
                );
                .card_content {
                  color: #fff;

                  button {
                    color: rgb(112, 5, 247);
                    background-color: #fff;

                    border: 1px solid #fff;
                  }
                }
                .tag {
                  .tag_content {
                    background-color: #fff;
                    &::before {
                      background-color: rgb(112, 5, 247);
                      background-color: #fff;
                      height: 99%;
                      top: 0;
                    }
                    h5 {
                      color: rgb(112, 5, 247);
                    }
                  }
                }
              }

              .card_content {
                color: rgb(112, 5, 247);

                button {
                  background-color: rgb(112, 5, 247);

                  border: 1px solid rgb(112, 5, 247);
                }
              }
              .tag {
                .tag_content {
                  background-color: rgb(112, 5, 247);
                }
              }
            }
            &:nth-child(2) {
              border: 1px solid rgb(253, 104, 14);
              &:hover {
                background: linear-gradient(
                  180deg,
                  rgb(253, 104, 14) 0%,
                  rgb(254, 6, 96) 100%
                );

                .card_content {
                  color: #fff;

                  button {
                    color: rgb(253, 104, 14);
                    background-color: #fff;

                    border: 1px solid #fff;
                  }
                }
                .tag {
                  .tag_content {
                    background-color: #fff;
                    &::before {
                      background-color: rgb(253, 104, 14);
                      background-color: #fff;
                      height: 99%;
                      top: 0;
                    }
                    h5 {
                      color: rgb(253, 104, 14);
                    }
                  }
                }
              }
              .card_content {
                color: rgb(253, 104, 14);

                button {
                  background-color: rgb(253, 104, 14);

                  border: 1px solid rgb(253, 104, 14);
                }
              }
              .tag {
                .tag_content {
                  background-color: rgb(253, 104, 14);
                }
              }
            }
            &:nth-child(3) {
              border: 1px solid rgb(54, 224, 203);
              &:hover {
                background: linear-gradient(
                  180deg,
                  rgb(54, 224, 203) 0%,
                  rgb(27, 200, 214) 100%
                );

                .card_content {
                  color: #fff;

                  button {
                    color: rgb(54, 224, 203);
                    background-color: #fff;

                    border: 1px solid #fff;
                  }
                }
                .tag {
                  .tag_content {
                    background-color: #fff;
                    &::before {
                      background-color: rgb(54, 224, 203);
                      background-color: #fff;
                      height: 99%;
                      top: 0;
                    }
                    h5 {
                      color: rgb(54, 224, 203);
                    }
                  }
                }
              }

              .card_content {
                color: rgb(54, 224, 203);

                button {
                  background-color: rgb(54, 224, 203);

                  border: 1px solid rgb(54, 224, 203);
                }
              }
              .tag {
                .tag_content {
                  background-color: rgb(54, 224, 203);
                }
              }
            }
          }
        }
      }
    }
  }
}

.forSettingsPage {
  height: 80vh;
  position: relative;
}

#floatingButton {
  position: fixed;
  top: 50%;
  right: 0;
  border: none;
  height: var(--fs60);
  width: var(--fs60);
  padding: 0;
  margin: 0;
  display: flex;
  background-color: transparent;

  img {
    width: 100%;
    object-fit: scale-down;
  }
}

.popupVerify {
  position: fixed;
  transition: all 0.5s ease-in-out;
  width: 20%;
  top: 50%;
  right: 0;
  transform: translate(100%);
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: var(--fs20);

  img {
    width: 100%;
  }

  // display: none;
  .popupVerifyHead {
    display: flex;
    align-items: center;

    .popupVerifyHeadImg {
      width: var(--fs40);
      margin-right: var(--fs10);
    }

    .popupVerifyHeadText {
      font-size: var(--fs20);
      font-family: HomeBoldFamily;
    }

    .popupVerifyClose {
      margin-left: auto;
      cursor: pointer;
      width: var(--fs20);
    }
  }

  .popupVerifyBody {
    margin: var(--fs15) 0;

    p {
      font-size: var(--fs16);

      a {
        color: black;
      }
    }

    button {
      margin-top: var(--fs10);
      font-size: var(--fs14);
      padding: 0.5vh 5%;
    }
  }
}

.popupVerifyActive {
  transform: translate(0%);
}
// span.blue_text {
//   color: #007bf4;
// }

// ------------------------------------------Home Screen  nav Styles----------------------------------------------
header.home {
  nav {
    .nav_bar {
      &.res_login {
        padding: 2vh 5.6% 1.5vh 5.6%;
      }
      &.fixTop {
        transition: padding 0.3s ease-in-out;
        background-color: rgba(252, 252, 252, 0.2);
        backdrop-filter: blur(20px) saturate(160%) contrast(45%)
          brightness(140%);
        // padding: 3vh 5% 3vh 5%;
        padding: 2vh 5.6% 2vh 5.6%;

        box-shadow: 0px 5px 13px 0px rgba(227, 227, 227, 0.48);
      }
      transition:padding 0.3s ease;
      position: fixed;
      width: 100%;
      z-index: 10000;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4vh 5.6% 4vh 5.6%;
      .ham-icon {
        border: none;
        background-color: transparent;
      }
      .logo_ {
        font-family: HomeBoldFamily;
        width: 16%;

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          gap: 5%;
          color: var(--Text_light_dark_to_white33);
          svg {
            width: 15%;
          }
          .img_holder {
            width: 10%;
          }
          img {
            // width: var(--fs32);
            width: 100%;
            // margin-right: var(--fs10);
          }
        }
        h3 {
          font-size: var(--fs37);
          font-weight: 600;
          color: #333333;
          margin: 0;
        }
      }
      .nav_collapse_btn {
        width: var(--fs15);
        display: none;
        img {
          width: 100%;
        }
      }

      .ham-icon {
        display: none;
      }
      .ham-checkbox {
        display: none;
      }
      .nav_links {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        width: 65.6%;
        justify-content: space-between;

        li {
          font-family: HomeRegularFamily;
          a {
            font-family: HomeRegularFamily;
            text-decoration: none;
            font-size: var(--fs20);
            color: #787a7f;
            transition: 0.3s ease;
            &.active,
            &:hover {
              color: #3551f2;
              position: relative;
              &:after {
                content: "";
                position: absolute;
                width: 50%;
                left: 25%;
                height: var(--fs5);
                bottom: -55%;
                border-radius: var(--fs10);
                background-color: #3551f2;
              }
            }
          }
        }
      }
    }
  }
}

// ----------home screen landing_container-----------------
.landing_container {
  padding-top: 10vh;
  background-image: url("../src/assets/images/Landingpage_Bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .carousel-indicators {
    bottom: var(--fs100);
    z-index: 1000;
    align-items: center;
    button {
      &.active {
        width: 3px;
        height: 3px;
      }
      width: 2px;
      height: 2px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: #0496ff;
      // border-radius: 3px;
    }
  }
}
.carosel_inner {
  .carousel-item {
    // &:nth-child(1) {
    .banner_content {
      .banner_content_cotainer {
        animation: mymove 1s linear;

        h2 {
        }
      }
      .banner_img_container {
        animation: mymove2 1s linear;
      }
    }
    // }
  }
  .banner_container {
  }
}
.banner_container {
  width: 94.5%;
  margin-left: 5.5%;
  font-family: HomeRegularFamily;
  position: relative;

  .scroll_down {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 13vh;
    z-index: 20;
    p {
      font-size: var(--fs12);
      text-transform: uppercase;
      font-family: HomeBoldFamily;
      margin: 0;
      color: #333333;
      padding-bottom: 1%;
    }
    button,
    a {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      text-decoration: none;
      font-size: var(--fs22);
      img {
        width: var(--fs16);
        margin-right: var(--fs10);
      }
    }
  }
  .banner_content {
    display: flex;
    padding-top: 2vh;
    z-index: 12;
    position: relative;
    padding-bottom: 10vh;
    justify-content: space-between;
    overflow: hidden;

    width: 100%;

    .banner_content_cotainer {
      width: 47%;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      justify-content: center;

      ol {
        display: none !important;
      }
      .carosel_inner {
        aspect-ratio: 1/0.45;
        position: static;
        display: flex;
        align-items: center;

        // .carousel-item {
        //   align-self: flex-end;
        // }
      }
      h2 {
        margin: 0;
        font-size: fs(67);
        font-weight: 300;
        padding-top: 2.27vh;
        font-family: HomeMediumFamily;
        padding-bottom: 5vh;

        span {
          color: #3650f2;
          position: relative;
          font-family: alanta;
        }
      }
      h4 {
        margin: 0;
        font-size: var(--fs26);
        line-height: var(--fs40);
        color: #979797;
        span {
          color: #333333;
        }
        padding: 6vh 0;
      }
      .btn_container {
        display: flex;
        align-items: center;
        gap: 1%;
        button {
          background-color: transparent;
          padding: 1.2vh 6.5%;
          margin-bottom: 4vh;
          font-size: var(--fs30);

          img {
            font-size: var(--fs40);
            transition: 0.3s ease-in;
            margin-left: var(--fs10);
          }

          // &:hover {
          //   img {
          //     transform: rotate(35deg);
          //   }
          // }
        }
      }
    }
    .banner_img_container {
      width: 45.5%;
      height: 100%;
      margin-right: 3.5%;
      position: relative;

      img {
        width: 100%;
      }
      // img,
      // svg {
      //   width: var(--fs815);
      //   height: var(--fs517);
      //   aspect-ratio: 1/0.5;
      // }
    }
  }
}

// -----------------about_us_container-----------------------
.about_us_container {
  padding-bottom: 10vh;
  padding-top: 10vh;
  margin-top: -10vh;
  .home_container_header {
    margin-bottom: 6vh;
  }
  .about_us_content_holder {
    width: 79%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    font-family: HomeRegularFamily;

    .about_us_left_content_container {
      width: 51%;
      padding-top: 4vh;
      p {
        font-size: var(--fs24);
        color: #737373;
        margin-bottom: 3vh;
      }
      button.view_more {
        background-color: transparent;
        padding: 1.2vh 6.5%;
        margin-bottom: 4vh;
        font-size: var(--fs30);
        margin-top: 2vh;
      }
    }
    .about_us_right_content_container {
      width: 46%;
      img {
        width: 100%;
      }
    }
  }
}
// -----------------------enabling container------------------------------
.how_we_enabling_Container {
  padding-top: 10vh;
  margin-top: -10vh;
  margin-bottom: 10vh;
  .how_we_enabling_img_holder {
    // width: 85%;
    position: relative;
    // padding: 10vh 0;
    margin: auto;
    video {
      width: 100%;
    }
    // .video_gradient_container {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-image: linear-gradient(rgba(#f2fcfc, 0.2), rgba(#fae4f2, 0.2));
    //   // background-color: rgba(0, 0, 0, 0.24);
    // }
    img,
    svg {
      width: 100%;
      text {
        &#_App_Hosting_ {
          tspan {
            fill: #000;
          }
        }
        font-family: HomeRegularFamily;
      }

      // &:nth-of-type(1) {
      //   // margin-top: -200px;
      // }
      // &:nth-child(2) {
      //   display: none;
      // }
    }
  }
}
// ---------------------Product_Container----------------------
.product_container {
  font-family: HomeRegularFamily;
  padding-top: 10vh;
  margin-top: -10vh;
  .product_tab_container {
    width: 90%;
    margin: auto;
    padding: 5vh 0 0;

    .product_btn_container {
      button {
        border: none;
        background-color: #f7f7ff;
        font-size: var(--fs22);
        // width: 100%;
        align-items: center;
        text-align: start;
        // padding: 3vh 10%;
        padding: 1.5vh var(--fs25);
        border-radius: var(--fs15);
        // color: #999999;
        display: flex;
        gap: var(--fs20);
        position: relative;
        overflow: hidden;
        outline: none;
        box-shadow: none;
        margin-bottom: 3vh;
        transition: all 0.3s ease;
        color: #999999;
        img {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: inline-block;
          }
        }
        &.active {
          background: linear-gradient(
            77deg,
            rgb(17, 10, 255) 0%,
            rgb(0, 144, 255) 100%
          );

          color: #fff;
          // &::before {
          //   top: 59%;
          //   left: 91%;
          //   transform: rotate(40deg);
          //   background-color: #ffffff;
          // }
          // &::after {
          //   bottom: 49%;
          //   left: 93%;
          //   transform: rotate(48deg);
          //   background-color: #fff;
          // }
        }
        // &::after,
        // &::before {
        //   content: "";
        //   position: absolute;

        //   width: 20%;
        //   height: 100%;
        //   right: 0;
        // }
        // &::before {
        //   top: 59%;
        //   left: 90%;
        //   transform: rotate(0);
        //   background-color: #ffffff;
        // }
        // &::after {
        //   bottom: 0%;
        //   left: 90%;
        //   transform: rotate(0);
        //   background-color: #fff;
        // }
        img {
          // filter: brightness(0) invert(0);
          width: var(--fs30);
          // filter: unset;
        }
        &.active {
          color: #fff;
          img {
            // filter: brightness(0) invert(1);
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(1) {
              display: inline-block;
            }
          }
        }
      }
      /* width */

      .nav_tabs {
        border: none;
        display: flex;
        // width: 27%;
        flex-wrap: wrap;
        gap: 3%;
        border-radius: var(--fs15);
        justify-content: center;
        padding-bottom: 5vh;
      }
    }

    .product_content_container {
      padding-bottom: 10vh;
      // width: calc(100% - 31%);
      width: 100%;
      margin: auto;
      .product_card_container {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        // gap: 9.5%;
        flex-wrap: wrap;
        .product_card {
          margin-bottom: 4vh;
          width: 49%;
          padding: 4vh 3% 4vh;
          // background-color: #f8f2fe;
          border-radius: var(--fs16);
          display: flex;
          align-items: center;
          gap: 5%;
          box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
            rgba(17, 17, 26, 0.1) 0px 0px 8px;
          // &:nth-child(1) {
          //   background-color: #f8f2fe;
          //   &:hover {
          //     background: linear-gradient(
          //       180deg,
          //       rgba(151, 0, 255, 1) 0%,
          //       rgba(97, 0, 241, 1) 100%
          //     );
          //   }
          // }
          // &:nth-child(2) {
          //   background: #e9fbfa;
          //   &:hover {
          //     background: linear-gradient(
          //       180deg,
          //       rgba(54, 224, 203, 1) 0%,
          //       rgba(27, 200, 214, 1) 100%
          //     );
          //   }
          // }
          // &:nth-child(3) {
          //   background-color: #fff2f7;
          //   &:hover {
          //     background-color: #f8006d;
          //   }
          // }
          // &:nth-child(4) {
          //   background-color: #f2faea;
          //   &:hover {
          //     background: rgb(148, 212, 80);
          //     background: linear-gradient(
          //       180deg,
          //       rgb(148, 212, 80) 0%,
          //       rgb(111, 216, 0) 100%
          //     );
          //   }
          // }
          // &:nth-child(5) {
          //   background-color: #fffaf2;
          //   &:hover {
          //     background: rgb(254, 178, 0);
          //     background: linear-gradient(
          //       180deg,
          //       rgb(254, 178, 0) 0%,
          //       rgb(253, 128, 1) 100%
          //     );
          //   }
          // }
          &:hover {
            // box-shadow:rgba(4, 150, 255, 1) 0px 3px 8px;
            box-shadow: rgba(4, 150, 255, 1) 0px 1px 3px 0px,
              rgba(4, 150, 255, 1) 0px 0px 0px 1px;

            .product_card_body {
              h5,
              p {
                color: #0496ff;
              }
            }
            .img_holder {
              img {
                width: 100%;
                &:nth-child(2) {
                  display: none;
                }
                &:nth-child(1) {
                  display: inline-block;
                }
              }
            }
          }
          .img_holder {
            width: 9.5%;
            // margin: auto;
            // padding-bottom: 4vh;

            img {
              width: 100%;
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                display: inline-block;
              }
            }
          }
          .product_card_body {
            // text-align: center;
            width: 85%;
            h5 {
              font-size: var(--fs28);
              margin-bottom: 1.5vh;
              font-family: HomeMediumFamily;
              color: #000;
            }
            p {
              font-size: var(--fs20);
              margin-bottom: 0;
              color: #999999;
            }
          }
        }
      }
    }
  }

  .product_accordian_tab_container {
    width: 90%;
    margin: auto;
    border-radius: var(--fs20);
    background-image: url(../src/assets/images/usecase_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 5vh;
    padding: 4vh 0;
    display: none;
    .accordian_container {
      margin: auto;

      .accordian_holder {
        width: 96%;
        // margin: auto;
        margin-left: 5%;
        padding-right: 5%;
        border: none;
        height: 73vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #cccccc;
          border-radius: 11px;
          border: none;
        }
        .accordian_item {
          padding: 1.75vh 0%;
          border-radius: 0;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #cccccc;
          .accordian_header {
            border: none;
            .accordion_button {
              box-shadow: none;
              background-color: transparent;
              font-size: 17px;
              padding: 0;
              color: #000000;
              outline: none;
              border: none;
              &:not(.collapsed) {
                background-color: transparent;
                &:after {
                  transform: rotate(0deg);
                }
              }
              &:after {
                position: absolute;
                width: 8px;
                height: 8px;
                margin-left: unset;
                right: 2%;
                transform: rotate(180deg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("../src/assets/images/Arrow_accordians.png");
              }
              img {
                width: 20px;
                margin-right: 10px;
              }
            }
          }
          .accordion_body {
            padding: 0;
            margin: 3vh 0;

            .product_card_container {
              display: flex;
              align-items: stretch;
              justify-content: center;
              flex-wrap: wrap;
              gap: 3%;
              flex-wrap: wrap;
              .product_card {
                margin-bottom: 2vh;
                width: 100%;
                padding: 2vh 3% 2vh;
                background-color: #fff;
                border-radius: var(--fs10);
                display: flex;
                align-items: center;
                gap: 5%;
                box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
                  rgba(17, 17, 26, 0.1) 0px 0px 8px;
                // &:hover {
                //   // box-shadow:rgba(4, 150, 255, 1) 0px 3px 8px;
                //   box-shadow: rgba(4, 150, 255, 1) 0px 1px 3px 0px,
                //     rgba(4, 150, 255, 1) 0px 0px 0px 1px;
                // }
                // &:nth-child(1) {
                //   border: 1px solid rgba(151, 0, 255, 1);
                // }
                // &:nth-child(2) {
                //   border: 1px solid rgba(54, 224, 203, 1);
                // }
                // &:nth-child(3) {
                //   border: 1px solid #f8006d;
                // }
                // &:nth-child(4) {
                //   border: 1px solid rgb(148, 212, 80);
                // }
                // &:nth-child(5) {
                //   border: 1px solid rgb(254, 178, 0);
                // }

                .img_holder {
                  width: 10%;
                  padding-bottom: 2vh;

                  img {
                    width: 100%;
                    &:nth-child(1) {
                      display: none;
                    }
                    &:nth-child(2) {
                      display: inline-block;
                    }
                  }
                }
                .product_card_body {
                  width: 85%;
                  h5 {
                    font-size: 16px;
                    margin-bottom: 1vh;
                    font-family: HomeMediumFamily;
                  }
                  p {
                    font-size: 14px;
                    margin-bottom: 0;
                  }
                }
              }
            }
            p {
              font-size: var(--fs24);
              color: #7a7a7a;
              margin: 0;
            }
          }
        }
      }
    }
  }

  button.view_more {
    background-color: transparent;
    padding: 1.2vh 3.5%;
    margin-bottom: 4vh;
    font-size: var(--fs30);
    margin-top: 2vh;
  }
}

// -----------------enterprises_usecase_card_Container------------------
.enterprises_usecase_Container {
  font-family: HomeRegularFamily;
  padding-top: 10vh;
  margin-top: -10vh;
  margin-bottom: 10vh;
  .enterprises_chip_container {
    width: 80%;
    margin: auto;
    overflow: hidden;
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      list-style-type: none;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: var(--fs10);
      li {
        display: flex;
        align-items: center;
        // p {
        gap: var(--fs20);
        padding: 1vh var(--fs20);
        // background-color: #e7e7e7;
        border-radius: var(--fs10);
        margin-bottom: 2vh;
        font-size: var(--fs18);
        color: #999999;
        position: relative;
        &:last-of-type {
          &:after {
            width: 0;
          }
        }
        &::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 80%;
          top: 10%;
          right: clamp(-12.5px, -20vw * 100 / 1920, -20px);
          background-color: #e2e2e2;
        }
        // }
        img {
          width: var(--fs30);
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .enterprises_use_case_silder_container {
    width: 90%;
    margin: auto;
    overflow: hidden;
    padding-top: 4vh;
    .enterprises_use_case_silder {
      .use_case_silde {
        img {
          margin: auto;
          width: var(--fs60);
        }
        p {
          margin: 0;
          text-align: center;
          font-size: var(--fs20);
          padding-top: 1vh;
        }
      }
    }
  }
  .enterprises_flip_card_container {
    display: flex;
    align-items: stretch;
    gap: 3.3%;
    flex-wrap: wrap;
    padding: 10vh 0 5vh;
    width: 90%;
    margin: auto;
    border-bottom: 1px solid #ececec;
    .enterprises_flip_card {
      width: 22.5%;
      cursor: pointer;
      aspect-ratio: 1/1;
      // padding: 2%;
      border-radius: 7px;
      background-color: transparent;
      border: none;
      padding: 3vh 2%;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;
      // transform-style: preserve-3d;
      // will-change: transform;
      // -webkit-transition: all 0.5s ease-out;
      // transition: all 0.5s ease-out;
      margin-bottom: 5vh;
      &:hover {
        // box-shadow:rgba(4, 150, 255, 1) 0px 3px 8px;
        box-shadow: rgba(4, 150, 255, 1) 0px 1px 3px 0px,
          rgba(4, 150, 255, 1) 0px 0px 0px 1px;
        .img_holder {
          img {
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(1) {
              display: inline-block;
            }
          }
        }
        .card_back {
          p {
            color: #007bf4;
          }
          h4 {
            color: #0496ff;
          }
        }
      }
      // &:nth-child(1) {
      //   .card_front {
      //     background-color: #eafbfa;
      //   }
      //   .card_back {
      //     background: linear-gradient(
      //       180deg,
      //       rgb(54, 224, 203) 0%,
      //       rgb(27, 200, 214) 100%
      //     );
      //   }
      // }
      // &:nth-child(2) {
      //   .card_front {
      //     background-color: #f8f2fe;
      //   }
      //   .card_back {
      //     background: linear-gradient(
      //       180deg,
      //       rgb(112, 5, 247) 0%,
      //       rgb(196, 26, 213) 100%
      //     );
      //   }
      // }
      // &:nth-child(3) {
      //   .card_front {
      //     background-color: #fff6f3;
      //   }
      //   .card_back {
      //     background: rgb(253, 104, 14);
      //     background: linear-gradient(
      //       180deg,
      //       rgb(253, 104, 14) 0%,
      //       rgb(254, 6, 96) 100%
      //     );
      //   }
      // }
      // &:nth-child(4) {
      //   .card_front {
      //     background-color: #f2f9ff;
      //   }
      //   .card_back {
      //     background: rgb(0, 193, 255);
      //     background: linear-gradient(
      //       180deg,
      //       rgb(0, 193, 255) 0%,
      //       rgb(18, 8, 255) 100%
      //     );
      //   }
      // }

      // &:nth-child(5) {
      //   .card_front {
      //     background-color: #fffaf2;
      //   }
      //   .card_back {
      //     background: rgb(254, 178, 0);
      //     background: linear-gradient(
      //       180deg,
      //       rgb(254, 178, 0) 0%,
      //       rgb(253, 128, 1) 100%
      //     );
      //   }
      // }
      // &:nth-child(6) {
      //   .card_front {
      //     background-color: #f2faea;
      //   }
      //   .card_back {
      //     background: rgb(148, 212, 80);
      //     background: linear-gradient(
      //       180deg,
      //       rgb(148, 212, 80) 0%,
      //       rgb(111, 216, 0) 100%
      //     );
      //   }
      // }
      // &:nth-child(7) {
      //   .card_front {
      //     background-color: #f8f2fe;
      //   }
      //   .card_back {
      //     background: linear-gradient(
      //       180deg,
      //       rgb(112, 5, 247) 0%,
      //       rgb(196, 26, 213) 100%
      //     );
      //   }
      // }
      // &:nth-child(8) {
      //   .card_front {
      //     background-color: #f6f6f7;
      //   }
      //   .card_back {
      //     background: rgb(94, 89, 113);
      //     background: linear-gradient(
      //       180deg,
      //       rgb(94, 89, 113) 0%,
      //       rgb(86, 105, 143) 100%
      //     );
      //   }
      // }
      // &:hover .card_inner {
      //   transform: rotateY(180deg);
      // }
      .card_front {
        // padding: 5vh 5%;

        h4 {
          font-size: var(--fs25);
          display: none;
        }
      }
      .img_holder {
        width: 25%;
        // margin: 0 auto;
        margin-bottom: 2.5vh;
        img {
          width: 100%;
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: inline-block;
          }
        }
      }
      .card_inner {
        // position: relative;
        // width: 100%;
        // height: 100%;
        // text-align: center;
        // transition: transform 0.8s;
        // transform-style: preserve-3d;
      }

      .card_back {
        // background-color: #3f4d56;
        // color: #fff;
        // padding: 15% 5% 5% 5%;

        // transform: rotateY(180deg);
        p {
          margin: 0;
          font-size: var(--fs18);
        }
        h4 {
          font-family: HomeMediumFamily;
          font-size: var(--fs24);
          margin-bottom: 3vh;
          color: #000;
        }
      }

      .card_front,
      .card_back {
        // position: absolute;
        width: 100%;
        // -webkit-backface-visibility: hidden;
        // backface-visibility: hidden;
        // aspect-ratio: 1/1;
        border-radius: var(--fs15);
        // overflow: hidden;
      }
      .card_img img {
        width: 100%;
        // aspect-ratio: 1/1.4;
      }
    }
  }
  .enterprises_usecase_card_Container {
    display: flex;
    align-items: stretch;
    gap: 3%;
    flex-wrap: wrap;
    padding: 10vh 0 5vh;
    width: 90%;
    margin: auto;
    border-bottom: 1px solid #ececec;

    .enterprises_usecase_card {
      margin-bottom: 8vh;
      cursor: pointer;
      &:nth-child(1) {
        &:hover {
          .img_holder {
            background: rgb(151, 0, 255);
            background: linear-gradient(
              180deg,
              rgba(151, 0, 255, 1) 0%,
              rgba(97, 0, 241, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #f8f2fe;
        }
      }
      &:nth-child(2) {
        &:hover {
          .img_holder {
            background: rgb(54, 224, 203);
            background: linear-gradient(
              180deg,
              rgba(54, 224, 203, 1) 0%,
              rgba(27, 200, 214, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #e9fafa;
        }
      }
      &:nth-child(3) {
        .img_holder {
          background-color: #f2f3ff;
        }
        &:hover {
          .img_holder {
            background: rgb(18, 7, 255);
            background: linear-gradient(
              180deg,
              rgba(18, 7, 255, 1) 0%,
              rgba(0, 147, 255, 1) 100%
            );
          }
        }
      }
      &:nth-child(4) {
        .img_holder {
          background-color: #fff2f7;
        }
        &:hover {
          .img_holder {
            background: #f8006d;
          }
        }
      }
      &:nth-child(5) {
        &:hover {
          .img_holder {
            background: rgb(254, 178, 0);
            background: linear-gradient(
              180deg,
              rgba(254, 178, 0, 1) 0%,
              rgba(253, 128, 1, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #fffaf2;
        }
      }
      &:nth-child(6) {
        &:hover {
          .img_holder {
            background: rgb(112, 5, 247);
            background: linear-gradient(
              180deg,
              rgba(112, 5, 247, 1) 0%,
              rgba(196, 26, 213, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #f8f2fe;
        }
      }
      &:nth-child(7) {
        &:hover {
          .img_holder {
            background: rgb(253, 104, 14);
            background: linear-gradient(
              180deg,
              rgba(253, 104, 14, 1) 0%,
              rgba(254, 6, 96, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #fff6f3;
        }
      }
      &:nth-child(8) {
        &:hover {
          .img_holder {
            background: rgb(0, 193, 255);
            background: linear-gradient(
              180deg,
              rgba(0, 193, 255, 1) 0%,
              rgba(18, 8, 255, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #f2f9ff;
        }
      }
      &:nth-child(9) {
        &:hover {
          .img_holder {
            background: rgb(94, 89, 113);
            background: linear-gradient(
              180deg,
              rgba(94, 89, 113, 1) 0%,
              rgba(86, 105, 143, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #f6f6f7;
        }
      }
      &:nth-child(10) {
        &:hover {
          .img_holder {
            background: rgb(148, 212, 80);
            background: linear-gradient(
              180deg,
              rgba(148, 212, 80, 1) 0%,
              rgba(111, 216, 0, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #f2faea;
        }
      }
      &:nth-child(11) {
        &:hover {
          .img_holder {
            background: rgb(255, 239, 0);
            background: linear-gradient(
              180deg,
              rgba(255, 239, 0, 1) 0%,
              rgba(255, 190, 0, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #fffde5;
        }
      }
      &:hover {
        .img_holder {
          img {
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(1) {
              display: inline-block;
            }
          }
        }
      }
      width: 22%;
      .img_holder {
        width: 64%;
        margin: auto;
        aspect-ratio: 1/1;
        background-color: #007bf4;
        border-radius: var(--fs10);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3vh;
        img {
          width: 50%;
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: inline-block;
          }
        }
      }
      .usecase_card_body {
        h2 {
          text-align: center;
          font-size: var(--fs35);
          margin-bottom: 0;
          font-family: HomeRegularFamily;
          width: 90%;
          margin: auto;
        }
      }
    }
  }
}
// -------------------service_container------------------------
.services_container {
  font-family: HomeRegularFamily;
  padding: 5vh 0;
  h2.head {
    text-align: center;
    font-family: HomeRegularFamily;
    margin: 0;
    font-size: clamp(56.25px, 90vw * 100 / 1920, 90px);
    color: #010101;
    margin-bottom: 4vh;
  }
  .services_card_container {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: stretch;
    gap: 3%;
    flex-wrap: wrap;
    .services_card {
      margin-bottom: 8vh;
      cursor: pointer;
      &:nth-child(1) {
        .img_holder {
          background-color: #f2f3ff;
        }
        &:hover {
          .img_holder {
            background: rgb(18, 7, 255);
            background: linear-gradient(
              180deg,
              rgba(18, 7, 255, 1) 0%,
              rgba(0, 147, 255, 1) 100%
            );
          }
        }
      }
      &:nth-child(2) {
        &:hover {
          .img_holder {
            background: rgb(254, 178, 0);
            background: linear-gradient(
              180deg,
              rgba(254, 178, 0, 1) 0%,
              rgba(253, 128, 1, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #fffaf2;
        }
      }
      &:nth-child(3) {
        &:hover {
          .img_holder {
            background: rgb(253, 104, 14);
            background: linear-gradient(
              180deg,
              rgba(253, 104, 14, 1) 0%,
              rgba(254, 6, 96, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #fff6f3;
        }
      }
      &:nth-child(4) {
        &:hover {
          .img_holder {
            background: rgb(0, 193, 255);
            background: linear-gradient(
              180deg,
              rgba(0, 193, 255, 1) 0%,
              rgba(18, 8, 255, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #f2f9ff;
        }
      }
      &:nth-child(5) {
        &:hover {
          .img_holder {
            background: rgb(254, 178, 0);
            background: linear-gradient(
              180deg,
              rgba(254, 178, 0, 1) 0%,
              rgba(253, 128, 1, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #fffaf2;
        }
      }
      &:nth-child(6) {
        &:hover {
          .img_holder {
            background: rgb(148, 212, 80);
            background: linear-gradient(
              180deg,
              rgba(148, 212, 80, 1) 0%,
              rgba(111, 216, 0, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #f2faea;
        }
      }
      &:nth-child(7) {
        &:hover {
          .img_holder {
            background: rgb(151, 0, 255);
            background: linear-gradient(
              180deg,
              rgba(151, 0, 255, 1) 0%,
              rgba(97, 0, 241, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #f8f2fe;
        }
      }
      &:nth-child(8) {
        &:hover {
          .img_holder {
            background: rgb(94, 89, 113);
            background: linear-gradient(
              180deg,
              rgba(94, 89, 113, 1) 0%,
              rgba(86, 105, 143, 1) 100%
            );
          }
        }
        .img_holder {
          background-color: #f6f6f7;
        }
      }
      &:hover {
        .img_holder {
          img {
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(1) {
              display: inline-block;
            }
          }
        }
      }
      width: 22%;
      .img_holder {
        width: 64%;
        margin: auto;
        aspect-ratio: 1/1;
        background-color: #007bf4;
        border-radius: var(--fs10);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3vh;
        img {
          width: 50%;
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: inline-block;
          }
        }
      }
      .usecase_card_body {
        h2 {
          text-align: center;
          font-size: var(--fs35);
          margin-bottom: 0;
          font-family: HomeRegularFamily;
          width: 90%;
          margin: auto;
        }
      }
    }
  }
}

// ---------------------blog article container-----------------
.blog_Article_Container {
  margin-bottom: 8vh;
  font-family: HomeRegularFamily;
  // margin-top: 10vh;
  padding-top: 10vh;
  margin-top: -10vh;
  .blog_card_container {
    width: 82%;
    padding-top: 4vh;
    margin: auto;
    display: flex;
    // justify-content: space-between;
    gap: 5.25%;
    .blog_card {
      width: 28.75%;
      &.active {
        .card_img_holder {
          position: relative;
          transition: 0.3s ease-in;
          &:after {
            content: "";
            left: 0;
            position: absolute;
            height: var(--fs10);
            width: 100%;
            background-color: #fd2e3d;
          }
        }
      }
      .card_img_holder {
        padding-bottom: 3vh;
        img {
          width: 100%;
        }
      }
      .card_body {
        h6 {
          span.date {
            position: relative;
            padding-left: 8%;
            &::before {
              content: "";
              position: absolute;
              width: 5px;
              left: 5%;
              top: 40%;
              background-color: #000000;

              height: 5px;
              border-radius: 50%;
            }
          }
        }
        h3 {
          width: 83%;
          font-size: var(--fs32);
        }
      }
    }
  }
  .btn_holder {
    button {
      background-color: transparent;
      padding: 1.2vh 3.5%;
      margin-bottom: 4vh;
      font-size: var(--fs30);
      margin-top: 7vh;
    }
  }
}

// ------------footer_container-----------------------
.footer_container {
  width: 90%;
  margin: auto;
  display: flex;
  font-family: HomeRegularFamily;
  margin-bottom: 5vh;
  margin-top: 5vh;
  // gap: 5%;
  h2 {
    font-size: var(--fs37);
    color: #333333;
    font-family: HomeBoldFamily;
    width: 100%;
    img {
      width: 75%;
    }
  }
  h3 {
    position: relative;
    margin-bottom: 2vh;
    font-family: HomeRegularFamily;
    &::before {
      content: "";
      position: absolute;
      height: 3px;
      width: 50%;
      background-color: #000000;
      bottom: -25%;
    }
  }
  .head {
    display: flex;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      a {
        text-decoration: none;
        color: #000000;
        display: inline-block;
        padding-bottom: 2vh;
        font-size: var(--fs18);
        color: #8d8d8d;
      }
    }
  }
  .footer_Enterprise_content {
    width: 45%;
    ul {
      padding: 0;
      margin: 0;

      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
      }
    }
  }
  .footer_content {
    &.social {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        //  li{
        //   width: 50%;
        //  }
      }
    }
    a {
      &:hover {
        img {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
        }
      }
      img {
        width: var(--fs30);
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(1) {
          display: block;
        }
      }
    }
    width: 18.3%;
  }
}
footer {
  font-family: HomeRegularFamily;
  .footer {
    background: rgb(17, 10, 255);
    background: linear-gradient(
      77deg,
      rgba(17, 10, 255, 1) 0%,
      rgba(0, 144, 255, 1) 100%
    );
    display: flex;
    align-items: center;
    padding: 1vh 3% 1vh 5%;
    justify-content: space-between;
    p {
      margin: 0;
      color: #9dadff;
      font-size: var(--fs16);
    }
    .footer_right_content {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      a {
        display: block;
        text-decoration: none;
        color: #9dadff;
        font-size: var(--fs16);
      }
    }
  }
}
//  half border button styles start
.half_border_button {
  position: relative;
  border: none;
  background-color: transparent;
  transition: 0.3s ease;

  &:hover {
    img {
      filter: unset;
    }
  }
  // svg {
  //   width: var(--fs28);
  //   transition: 0.3s ease-in;
  //   transform: rotate(-35deg);
  // }
  img {
    font-size: var(--fs40);
    transition: 0.3s ease-in;
    transform: rotate(-33deg);
    filter: brightness(0) invert(0);
  }
  &::before {
    position: absolute;
    content: "";
    width: 99.8%;
    height: 50%;
    left: 0;
    top: 0;
    border-left: 2px solid #414447;
    border-top: 2px solid #414447;
    border-top-left-radius: var(--fs10);
    border-top-right-radius: var(--fs10);
    transition: all 0.3s ease-in;
  }
  &::after {
    position: absolute;
    content: "";
    width: 50%;
    height: 99%;
    right: 0;
    bottom: 0;
    border-right: 2px solid #414447;
    border-bottom: 2px solid #414447;
    border-bottom-right-radius: var(--fs10);
    border-top-right-radius: var(--fs10);
    transition: all 0.3s ease-in;
  }

  &:hover {
    // background: -webkit-linear-gradient(
    //   77deg,
    //   rgba(17, 10, 255, 1) 0%,
    //   rgba(0, 144, 255, 1) 100%
    // );
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-color: #007bf4;
    img {
      transform: rotate(0deg);
    }
    svg {
      transform: rotate(0deg);
    }
    color: #007bf4;
    &::before {
      height: 98%;
      border-left: 2px solid #007bf4;
      border-top: 2px solid #007bf4;
      border-bottom-left-radius: var(--fs10);
    }
    &::after {
      width: 99.8%;
      border-right: 2px solid #007bf4;
      border-bottom: 2px solid #007bf4;
      border-bottom-left-radius: var(--fs10);
    }
  }
}

//  half border button styles end
//--------------home_container_header ------
.home_container_header {
  margin-bottom: 3vh;
  text-align: center;
  font-family: HomeRegularFamily;
  .dummy_btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h6 {
    padding: 1.25vh 1%;
    background-color: #e7e7e7;
    border-radius: var(--fs10);
    font-family: HomeBoldFamily;
    margin-bottom: 2vh;
    font-size: var(--fs18);
    color: #000000;
  }
  h2 {
    font-family: HomeRegularFamily;
    margin: 0;
    padding-bottom: 1vh;
    font-size: var(--fs40);
    color: #737373;
  }
  p {
    margin: 0 auto;
    font-size: var(--fs25);
    width: 33%;
    color: #737373;
    line-height: var(--fs30);
  }
}
body.home_res_log {
  background-image: url(../src/assets/images/Landingpage_Bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
// @import "../src/assets/scss/ownModal";
@keyframes mymove {
  // 0%,100%{
  // }
  from {
    transform: translateX(5%);
    // right: -5%;
  }
  to {
    transform: translateX(-0%);

    right: 0;
  }
}
@keyframes mymove2 {
  from {
    left: -5%;
  }
  to {
    left: 0;
  }
}

.thankyou_container {
  margin-top: 15vh;
  font-family: HomeRegularFamily;
  .thankyou_illustration_holder {
    width: 32%;
    margin: auto;
    padding-bottom: 5vh;
    img {
      width: 100%;
    }
  }
  .thankyou_text_Content {
    text-align: center;
    h3 {
      margin-bottom: 2vh;
      font-size: var(--fs40);
      font-family: HomeMediumFamily;
    }
    h4 {
      margin-bottom: 6vh;
      font-size: var(--fs28);
      font-family: HomeMediumFamily;
    }
    p {
      margin-bottom: 4vh;
      font-size: var(--fs26);
    }
    .btn_holder {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background-color: transparent;
        padding: 1.2vh 2.5%;
        margin-bottom: 0vh;
        font-size: var(--fs25);
      }
    }
  }
}
#ham {
  display: none;
}
.rectangle {
  fill: #dbdbdb;
  transition: 0.3s ease-in;
  &:hover {
    fill: rgb(94, 89, 113);
    .Path_9168 {
      fill: #fff;
    }
    // rgb(94, 89, 113) 0%, rgb(86, 105, 143) 100%)
  }
}

//-------------------------------- enterprises_usecase_modal------------------------------
#exampleModal {
  .enterprises_usecase_modal {
    max-width: 53%;
    font-family: HomeRegularFamily;
    .enterprises_usecase_modal_content {
      border-radius: var(--fs20);
      padding: 3vh 7.5%;
      // background-color: var(--bg_body);
      background-color: var(--nav_bgdark_to_light);
      .enterprises_usecase_modal_header {
        position: relative;
        justify-content: center;
        padding: 0;
        border: none;
        margin-bottom: var(--fs20);
        button {
          position: absolute;
          right: 0%;
          top: 50%;
          transform: translateY(-50%);
          border: none;
          background-color: transparent;
          padding: 0;
          width: 2.5%;
          img {
            width: 100%;
          }
        }
        h2 {
          font-size: var(--fs26);
          font-family: HomeRegularFamily;
          span {
            font-size: var(--fs18);
            font-family: HomeRegularFamily;
            color: #5c5c5c;
          }
        }
      }
      .enterprises_usecase_modal_body {
        padding: 0;
        background-color: var(--nav_bgdark_to_light);

        .table_hoder {
          position: relative;
          max-height: 70vh;
          overflow-y: auto;
          border: 1px solid #e7e7e7;
          border-radius: 0.4rem;
          margin-bottom: var(--fs25);

          &::-webkit-scrollbar {
            width: var(--fs3);
          }

          &::-webkit-scrollbar-track {
            background: #ffffff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #b3b2b3;
            border-radius: 11px;
          }
          .table {
            margin: 0;
            thead {
              position: sticky;
              top: 0;
              z-index: 999;
              tr {
                th {
                  background-color: var(--nav_bgdark_to_light);
                  color: var(--Text_dark_to_light);
                  &:nth-of-type(1) {
                    width: 33%;
                    border-right: 1px solid #e7e7e7;
                  }
                  padding: var(--fs12) var(--fs18);
                  background-color: var(--card_light_dark);
                  font-family: HomeRegularFamily;
                  font-weight: unset;
                  font-size: var(--fs22);
                  text-align: center;
                }
              }
            }
            tbody {
              tr {
                td {
                  background-color: var(--nav_bgdark_to_light);
                  color: var(--Text_dark_to_light);

                  &.data_name {
                    font-family: HomeRegularFamily;
                    font-size: var(--fs20);
                    padding-left: 7.5%;
                  }
                  padding: var(--fs12) var(--fs18);
                }
                td:nth-of-type(1) {
                  vertical-align: middle;
                  border-right: 1px solid #e7e7e7;
                  .check_holder {
                    input {
                      display: none;
                    }
                    input:checked + .custom_holder {
                      .checkmark {
                        position: relative;
                        border: 1px solid #007bf4;
                        visibility: visible;
                        &:after {
                          content: "";
                          left: 50%;
                          top: 50%;
                          width: 30%;
                          aspect-ratio: 1/2;
                          border: solid #007bf4;
                          border-width: 0 2px 3px 0;
                          transform: translate(-50%, -50%) rotate(45deg);
                          position: absolute;
                        }
                      }
                    }
                    .custom_holder {
                      display: flex;
                      align-items: center;
                      column-gap: var(--fs20);

                      .checkmark {
                        border-radius: var(--fs7);
                        border: 1px solid #ccc;
                        width: var(--fs28);
                        aspect-ratio: 1/1;
                        background-color: transparent;
                        display: inline-block;
                        visibility: hidden;
                      }
                      .labelTxt {
                        font-family: HomeRegularFamily;
                        font-size: var(--fs20);
                        margin-bottom: 1vh;
                        margin: 0;
                        color: var(--Text_dark_to_light);
                      }
                    }
                  }
                }
                td:nth-of-type(2) {
                  .website_form_group {
                    position: relative;
                    input {
                      padding: 0.5vh 2%;
                      font-size: var(--fs16);
                      border-radius: var(--fs10);
                      padding: var(--fs10);
                      padding-right: 15%;
                      background-color: transparent;
                      font-family: HomeRegularFamily;
                      color: var(--Text_dark_to_light);

                      &::placeholder {
                        color: #000;
                        font-size: var(--fs16);
                        font-family: HomeRegularFamily;
                        color: #e7e7e7;
                      }
                    }
                    .add_url_links {
                      font-size: var(--fs14);
                      font-family: HomeRegularFamily;
                      position: absolute;
                      top: 50%;
                      right: 5px;
                      transform: translateY(-50%);
                      border: none;
                      background-color: transparent;
                      color: #2773fc;
                    }
                  }
                  .modal_chip_container {
                    margin-top: 0;
                    display: flex;
                    // display: none;
                    flex-wrap: wrap;
                    margin-bottom: 0;
                    gap: 4%;
                    row-gap: var(--fs16);
                    .chip {
                      border: 1px solid #ccc;
                      border-radius: var(--fs20);
                      display: flex;
                      align-items: center;
                      padding: 0.1% 2%;
                      column-gap: var(--fs10);
                      p {
                        margin: 0;
                        font-size: var(--fs15);
                        color: #b6b6b6;
                      }
                      button {
                        border: none;
                        background-color: transparent;
                        width: var(--fs12);
                        padding: 0;
                        margin-left: var(--fs3);
                        color: var(--Text_dark_to_light);

                        img {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .submit_container {
            display: flex;
            justify-content: center;
            padding-bottom: 1vh;
            button {
              padding: 1.3vh 7.5%;
              font-size: var(--fs20);
              img {
                width: var(--fs25);
              }
            }
          }
        }
      }
      .submit_container {
        display: flex;
        justify-content: center;
        padding-bottom: 1vh;
        button {
          color: var(--Text_dark_to_light);

          &::after {
            border-width: 1.5px;
          }
          &::before {
            border-width: 1.5px;
          }
          padding: 0.8vh var(--fs25);
          font-size: var(--fs20);
          img {
            width: var(--fs25);
          }
        }
      }
    }
  }
}
.usecase_iframe_container {
  height: 75vh;
  width: 85.5%;
  margin: auto;
  padding-top: 4vh;
  iframe {
    height: 100%;
    width: 100%;
  }
}
.marketplace_holder {
  width: 85.5%;
  margin: auto;
  margin-top: 2vh;
  &.fix_height {
    height: calc(100vh - calc(var(--fs74) + var(--fs20) + var(--fs150) + 2vh));
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    width: var(--fs3);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b3b2b3;
    border-radius: 11px;
  }
  // height: 70vh;
  // overflow-y: scroll;
}
.marketplace_Uidriven_holder {
  width: 85.5%;
  margin: auto;
}
.home_res_log {
  background-image: url(../src/assets/images/Landingpage_Bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.error_Screen {
  width: 100%;
  height: calc(100vh - var(--fs74));
  display: flex;
  align-items: center;
  justify-content: center;
}
