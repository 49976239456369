.storeData_Modal {
  width: 35%;
  background-color: var(--nav_bgdark_to_light);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 4vh var(--fs55);
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 5vh;
}

.storeData_Modal .storeData_Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5vh;
}

.storeData_Modal .storeData_Form .storeData_formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 2vh;
}

.storeData_Modal .storeData_Form .storeData_formGroup label {
  font-family: HomeMediumFamily;
  font-size: var(--fs22);
  color: #b9b9b9;
}

.storeData_Modal .storeData_Form .storeData_formGroup input {
  padding: var(--fs12) var(--fs20);
  font-family: HomeMediumFamily;
  font-size: var(--fs24);
background: transparent;
color: var(--Text_light_dark_to_white66);
  border-radius: 0.6rem;
  border: 1px solid #cccccc;
}

.storeData_Modal .storeData_Form .storeData_formGroup input:focus {
  outline: none;
}

@media (max-width:1200px) {
  .storeData_Modal {
    width: 70%;
    padding: 4vh var(--fs50);
  }

  .storeData_Modal .storeData_Form .storeData_formGroup label {
    font-size: var(--fs20);
  }
}

@media (max-width:790px) {
  .storeData_Modal {
    width: 70%;
    padding: 4vh var(--fs30);
  }

  .storeData_Modal .storeData_Form .storeData_formGroup label {
    font-size: var(--fs18);
  }
}