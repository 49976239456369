.ownModalWrapper {
  padding-inline: var(--fs74);
  padding-block: var(--fs20);
  display: flex;
  flex-direction: column;
  row-gap: var(--fs60);
  position: relative;
  height: calc(100vh - calc(var(--fs74) + var(--fs20) + var(--fs150)));
  justify-content: space-between;
}

@media (max-width: 1200px) {
}

@media (max-width: 768px) {
  .ownModalWrapper {
    padding-inline: var(--fs60);
    padding-block: var(--fs10);
    row-gap: var(--fs50);
    height: calc(100vh - calc(var(--fs74) + var(--fs20) + var(--fs150)));
  }
}

@media (max-width: 576px) {
}

@media (max-width: 375px) {
}
