.subCategoryItem {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  position: relative;
}

.subCategoryItem button {
  border: none;
  width: 100%;
  padding-block: var(--fs28);
  padding-left: var(--fs28);

  color: var(--Text_light_dark_to_white66);
  
  background-color: var(--tab_btn_to_dark);
  display: flex;
  column-gap: var(--fs14);
  align-items: center;
  font-family: HomeRegularFamily;
  font-size: var(--fs20);
}

.subCategoryItem button img {
  width: 8%;
}

.subCategoryItem button.active {
  background-color: #0496ff;
  color: #fff;
  position: relative;
}

.subCategoryItem button.active img {
  filter: invert(1);
}

.subCategoryItem button.active::after {
  content: "";
  position: absolute;
  width: 7%;
  aspect-ratio: 1 / 1;
  background-color: #0496ff;
  transform: translate(50%, -50%) rotate(45deg);
  right: 0;
  top: 50%;
  border-top-right-radius: 0.2rem;
}

.subCategoryItem:nth-of-type(1) button {
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
}

.subCategoryItem:last-child>button {
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.subCategoryItem button:not(.subCategoryItem:last-child button) {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width:1200px) {
  .subCategoryItem button {
    padding-block: var(--fs20);
    padding-left: var(--fs25);
    column-gap: var(--fs14);
    font-size: var(--fs20);
  }
}

@media (max-width:976px) {
  .subCategoryItem button {
    padding-block: var(--fs15);
    padding-left: var(--fs20);
    column-gap: var(--fs12);
    font-size: var(--fs17);
  }
}

@media (max-width:576px) {
  .subCategoryItem {
    width: 25%;
    position: relative;
  }

  .subCategoryItem:nth-of-type(1) button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .subCategoryItem:last-child>button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .subCategoryItem button {
    height: 100%;
    padding-block: var(--fs15);
    padding-left: var(--fs10);
    column-gap: 0;
    font-size: var(--fs15);
    color: #333333;
    display: flex;
    flex-direction: column;
    gap: var(--fs10);
  }

  .subCategoryItem button img {
    width: 40%;
    height: 40%;
    object-fit: scale-down;
  }

  .subCategoryItem button.active::after {
    content: "";
    position: absolute;
    width: var(--fs10);
    aspect-ratio: 1 / 1;
    background-color: #0496ff;
    transform: translate(50%, -50%) rotate(45deg);
    right: 50%;
    top: 100%;
    border-top-right-radius: 0.2rem;
  }
}