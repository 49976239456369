.submitBtn {
  background-color: transparent;
  border: none;
  font-size: var(--fs22);
color: var(--Text_light_dark_to_white66);
  display: flex;
  align-items: center;
  font-family: HomeRegularFamily;
}
.submitBtn.ModalSubmit {
  align-self: flex-end;
}

.submitBtn img {
  /* margin-right: var(--fs10); */
  width: var(--fs32);
}

.submitBtn {
  position: relative;
  border: none;
  background-color: transparent;
  transition: 0.3s ease;
  padding: 1vh var(--fs26);
}

.submitBtn img {
  /* margin-right: var(--fs10); */
  width: var(--fs16);
}

.submitBtn:hover img {
  filter: unset;
}

.submitBtn img {
  font-size: var(--fs40);
  transition: 0.3s ease-in;
  transform: rotate(-33deg);
  filter: brightness(0) invert(0);
}

.submitBtn:before {
  position: absolute;
  content: "";
  width: 99.8%;
  height: 50%;
  left: 0;
  top: 0;
  border-left: 2px solid #414447;
  border-top: 2px solid #414447;
  border-top-left-radius: var(--fs10);
  border-top-right-radius: var(--fs10);
  transition: all 0.3s ease-in;
}

.submitBtn::after {
  position: absolute;
  content: "";
  width: 50%;
  height: 99%;
  right: 0;
  bottom: 0;
  border-right: 2px solid #414447;
  border-bottom: 2px solid #414447;
  border-bottom-right-radius: var(--fs10);
  border-top-right-radius: var(--fs10);
  transition: all 0.3s ease-in;
}

.submitBtn:hover {
  background: -webkit-linear-gradient(
    77deg,
    rgba(17, 10, 255, 1) 0%,
    rgba(0, 144, 255, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  color: #3e3eed;
}

.submitBtn:hover img {
  transform: rotate(0deg);
}

.submitBtn:hover::before {
  height: 98%;
  border-left: 2px solid #3e3eed;
  border-top: 2px solid #3e3eed;
  border-bottom-left-radius: var(--fs10);
}

.submitBtn:hover::after {
  width: 99.8%;
  border-right: 2px solid #508efb;
  border-bottom: 2px solid #508efb;
  border-bottom-left-radius: var(--fs10);
}

@media (max-width: 1200px) {
  .submitBtn {
    font-size: var(--fs18);
  }
}

@media (max-width: 790px) {
  .submitBtn {  
    font-size: var(--fs15);
  }
}
