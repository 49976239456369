.previewModalItem {
  padding: 0;
  margin: 0;
  list-style: none;
}

.previewModalItem .preview_itemWrapper {
  background-color: var(--modal_card_todark);
  padding: var(--fs20);
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
}

.previewModalItem .preview_itemWrapper .previewQuestion {
  padding: 0;
  margin: 0;
  color: var(--Text_light_dark_to_white66);
  font-family: HomeRegularFamily;
  font-size: var(--fs20);
}

.previewModalItem .preview_itemWrapper .preview_Answer_container {}

.previewModalItem .preview_itemWrapper .preview_Answer_container .preview_Answer {
  font-family: HomeMediumFamily;
  padding: 0;
  margin: 0;
  color: var(--Text_light_dark_to_white66);

  font-size: var(--fs20);
}

@media (max-width:1200px) {
  .previewModalItem .preview_itemWrapper .previewQuestion {
    font-size: var(--fs19);
  }

  .previewModalItem .preview_itemWrapper .preview_Answer_container .preview_Answer {
    font-size: var(--fs19);
  }
}

@media (max-width:768px) {
  .previewModalItem .preview_itemWrapper .previewQuestion {
    font-size: var(--fs17);
  }

  .previewModalItem .preview_itemWrapper .preview_Answer_container .preview_Answer {
    font-size: var(--fs17);
  }
}