.subListChips_list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 2vh;
  align-content: start;
  width: 75%;
}

.subListChips_list .subListChips_item {
  padding: 0;
  margin: 0;
  list-style: none;
}

.subListChips_list .subListChips_item button {
  border: none;
  width: 100%;
  background-color: var(--nav_bgdark_to_light);
  padding: var(--fs10) var(--fs16);
  border: 1px solid rgba(0, 0, 0, 0.4);
  /* color: rgba(0, 0, 0, 0.4); */
  border-radius: 100px;
  font-family: HomeRegularFamily;
  font-size: var(--fs20);
  color:var(--Text_light_dark_to_white66);
}

.subListChips_list .subListChips_item button.active {
  border: 1px solid #0496ff;
  color: #0496ff;
  /* width: 100%;
  background-color: transparent;
  padding: var(--fs10) var(--fs16);
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  font-family: HomeRegularFamily;
  font-size: var(--fs20); */
}

@media (max-width:1200px) {
  .subListChips_list {
    column-gap: 2%;
    row-gap: 2vh;
    width: 65%;
  }

  .subListChips_list .subListChips_item button {
    padding: 0.4vw 0.8vw;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 100px;
    font-family: HomeRegularFamily;
    font-size: var(--fs18);
  }
}

@media (max-width:1200px) {
  .subListChips_list {
    width: 100%;
  }
}