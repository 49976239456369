.modal_QA_Option_item {
  margin: 0;
  list-style: none;
  padding: 0;
  width: 9%;
}

.modal_QA_Option_item input {
  display: none;
}

.modal_QA_Option_item input:checked + label .gradient_wrapper {
  background-color: #0496ff;
}

.modal_QA_Option_item input:checked + label p {
  font-family: HomeRegularFamily;
  color: #0496ff;
}

.modal_QA_Option_item label {
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.modal_QA_Option_item label .gradient_wrapper {
  width: 60%;
  background-color: #cccccc;
  padding: 1px;
  aspect-ratio: 1 / 1;
  border-radius: 0.4rem;
}

.modal_QA_Option_item label .image_wrapper {
  background-color: var(--nav_bgdark_to_light);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 0.4rem;
  /* aspect-ratio: 1 / 1; */
}

.modal_QA_Option_item label .image_wrapper img {
  height: 42%;
}

.modal_QA_Option_item label p {
  font-family: HomeLightFamily;
  color: #999999;
  font-size: var(--fs18);
}

@media (max-width: 1200px) {
  .modal_QA_Option_item label p {
    font-size: var(--fs16);
  }
}

@media (max-width: 992px) {
  .modal_QA_Option_item label p {
    font-size: var(--fs14);
  }

  .modal_QA_Option_item {
    width: 18%;
  }
}

@media (max-width: 768px) {
  .modal_QA_Option_item label p {
    font-size: var(--fs13);
  }

  .modal_QA_Option_item {
    width: 22%;
  }
}

@media (max-width: 576px) {
  .modal_QA_Option_item label p {
    font-size: var(--fs15);
  }

  .modal_QA_Option_item {
    width: 30%;
  }

  .modal_QA_Option_item label .image_wrapper img {
    height: 38%;
  }

  .modal_QA_Option_item label .gradient_wrapper {
    width: 70%;
  }
}
