.ownModalWrapper_subContainer {
  display: flex;
  justify-content: space-between;
  /* padding-left: var(--fs50); */
  padding-inline: var(--fs74);
  /* column-gap: var(--fs100); */
  flex-grow: 1;
  overflow-y: hidden;
}

@media (max-width: 1200px) {
  .ownModalWrapper_subContainer {
    padding-inline: var(--fs55);
  }
}

@media (max-width: 768px) {
  .ownModalWrapper_subContainer {
    /* padding-left: 0; */
    padding-inline: var(--fs10) 0;
    column-gap: var(--fs50);
  }
}

@media (max-width: 576px) {
  .ownModalWrapper_subContainer {
    /* padding-left: var(--fs10); */
    padding-inline: 0;
    column-gap: 0;
  }
}
