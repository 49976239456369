.tableRows {
}

.tableRows td {
  padding: 0;
  border: 1px solid #e7e7e7;
}

.tableRows td:nth-of-type(1) {
  border-left: 0;
}

.tableRows td:last-child {
  border-right: 0;
}
.tableRows:nth-of-type(1) td {
  border-top: 0;
}

.tableRows:last-child td {
  border-bottom: 0;
}
.tableRows td .td_Wrapper {
  padding: var(--fs12);
}

.tableRows td .subQuestion_Wrapper p {
  margin: 0;
  font-family: HomeRegularFamily;
  font-size: var(--fs20);
  color: var(--Text_light_dark_to_white66);
}

.tableRows td .subQuestion_Wrapper p sup {
  color: red;
}
