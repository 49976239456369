.foundational_tab_head_list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  column-gap: 2%;
}

.foundational_tab_head_list .foundational_tab_head_item {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 16%;
}

.foundational_tab_head_list .foundational_tab_head_item button {
  border: none;
  background-color: transparent;
  width: 100%;
  color: var(--Text_light_dark_to_white66);
  background-color: var(--tab_btn_to_dark);

  border-radius: 0.4rem;
  padding-block: var(--fs11);
  font-family: HomeRegularFamily;
  font-size: var(--fs20);
}

.foundational_tab_head_list .foundational_tab_head_item button.active {
  background-color: #0496ff;
  color: #fff;
}

@media (max-width: 1200px) {
  .foundational_tab_head_list .foundational_tab_head_item {
    width: 20%;
  }

  .foundational_tab_head_list .foundational_tab_head_item button {
    padding-block: var(--fs10);
    font-size: var(--fs18);
  }
}

@media (max-width: 976px) {
  .foundational_tab_head_list {
    /* justify-content: space-between; */
  }

  .foundational_tab_head_list .foundational_tab_head_item {
    width: 23%;
  }

  .foundational_tab_head_list .foundational_tab_head_item button {
    padding-block: var(--fs10);
    font-size: var(--fs17);
  }
}

@media (max-width: 576px) {
  .foundational_tab_head_list {
    /* justify-content: space-between; */
    flex-wrap: wrap;
  }

  .foundational_tab_head_list .foundational_tab_head_item {
    width: 46%;
    margin-bottom: var(--fs10);
  }

  .foundational_tab_head_list .foundational_tab_head_item button {
    padding-block: var(--fs10);
    font-size: var(--fs17);
  }
}
