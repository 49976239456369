.qa_fileDrop_area {
  width: 100%;
}

/* .qa_fileDrop_area .file-upload {
} */

.qa_fileDrop_area .file-uploadArea {
  display: flex;
  align-items: center;
  height: 39vh;
  gap: var(--fs20);
  box-sizing: border-box;
}

.qa_fileDrop_area .file-uploadArea .custom-label {
  border-radius: 10px;
  border: 2px dashed #0496ff;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding-block: 2vh;
}

.qa_fileDrop_area .file-uploadArea .custom-label h4 {
  margin: var(--fs10);
  color: #0496ff;
  font-size: var(--fs25);
  font-family: HomeBoldFamily;
}

.qa_fileDrop_area .file-uploadArea .custom-label .uploadImg {
  width: 22%;
  display: flex;
}

.qa_fileDrop_area .file-uploadArea .custom-label .uploadImg img {
  width: 100%;
}

.qa_fileDrop_area .file-uploadArea .file-list {
  list-style: none;
  height: 100%;
  width: 60%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  overflow-y: scroll;
  scrollbar-width: auto;
  scrollbar-color: #b3b2b3 #ffffff;
  transition: all 0.8s ease-in-out;
}

/* .qa_fileDrop_area .file-uploadArea::-webkit-scrollbar {
  width: 3px;
} */

/* Track */
/* .qa_fileDrop_area .file-uploadArea::-webkit-scrollbar-track {
  background: transparent;
  width: 3px;
} */

/* Handle */
/* .qa_fileDrop_area .file-uploadArea::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 8px;

} */

/* Handle on hover */
/* .qa_fileDrop_area .file-uploadArea::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

 .qa_fileDrop_area .file-uploadArea .file-list::-webkit-scrollbar {
  width: 3px;
}

.qa_fileDrop_area .file-uploadArea .file-list::-webkit-scrollbar-track {
  background: transparent;
}

.qa_fileDrop_area .file-uploadArea .file-list::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 11px;
  border: none;
} 

.qa_fileDrop_area .file-uploadArea .file-list .file-item {
  padding: 5px;
  display: flex;
  align-items: flex-start;
  gap: var(--fs20);
  transition: all 0.8s ease-in-out;
}

.qa_fileDrop_area .file-uploadArea .file-list .file-item .file-itemimg {
  width: var(--fs40);
  display: flex;
}

.qa_fileDrop_area .file-uploadArea .file-list .file-item .file-itemimg img {
  width: 100%;
}

.qa_fileDrop_area .file-uploadArea .file-list .file-item .file-name h6 {
  font-size: var(--fs18);
  font-family: HomeBoldFamily;
}

.qa_fileDrop_area .file-uploadArea .file-list .file-item .file-name p {
  font-size: var(--fs16);
  color: #7c7b7b;
}

.qa_fileDrop_area .file-uploadArea .file-list .file-item .file-delete {
  width: var(--fs16);
  cursor: pointer;
}

.qa_fileDrop_area .file-uploadArea .file-list .file-item .file-delete img {
  width: 100%;
}

/**ADter**/
.qa_fileDrop_area .highlight {
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.qa_fileDrop_area .file-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.qa_fileDrop_area .file-name {
  flex-grow: 1;
  margin-right: 10px;
}

.qa_fileDrop_area .file-delete {
  color: red;
  cursor: pointer;
}

@media (max-width: 576px) {
  .qa_fileDrop_area .file-uploadArea {
    flex-direction: column;
  }

  .qa_fileDrop_area .file-uploadArea .custom-label {
    border-radius: 10px;
    border: 2px dashed #0496ff;
    width: 90%;
  }

  .qa_fileDrop_area .file-uploadArea .file-list {
    list-style: none;
    height: 100%;
    width: 90%;
    overflow-y: inherit;
  }
}
